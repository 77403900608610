import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../redux/actions";
import HeaderMain from "./HeaderMain";
import history from "../../history";
import AuthenticationService from "../../service/AuthenticationService";
import {selectIsUserLoggedIn} from "../../redux/select-app-reducer";

const HeaderContainer = (props) => {
    const [isLoggined, setIsLoggined] = useState(false);

    const {isLoggedInUser, isTCAInMaintenance} = props;

    useEffect(() => {
        isLoggedInUser();
    }, [isLoggedInUser]);

    const {login} = props;
    useEffect(() => {
        let loggedUserData = AuthenticationService.getLoggedUser();
        if (loggedUserData && loggedUserData.token) {
            setIsLoggined(true);
        } else {
            setIsLoggined(false);
        }

    }, [login]);

    return (
        <HeaderMain history={history} isUserLoggedIn={props.isUserLoggedIn} isTCAInMaintenance={isTCAInMaintenance}/>
    )
}
const mapStateToProps = (state) => ({
    login: state.auth.login,
    isUserLoggedIn: selectIsUserLoggedIn(state)
});

const mapDispatchToProps = (dispatch) => ({
    isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
);
