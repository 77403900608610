import React, {useState} from 'react';
import {connect} from "react-redux";
import Calculations from "./Calculations.jsx";
import {getCurrencyPairs} from "../../redux/exchange/reducer.js"
import {setCurrentCalculation} from "../../redux/calculations/reducer.js"


const CalculationsContainer = props => {

    const {currencyPairs, basicAuth, isCurrencyPairsLoaded, getCurrencyPairs} = props;

    const [isCurrencyPairsLoading, setCurrencyPairsLoading] = useState(false);

    if ((!isCurrencyPairsLoaded) && (!isCurrencyPairsLoading)){
        setCurrencyPairsLoading (true);
        getCurrencyPairs(basicAuth);
    }
    return (<Calculations {...props} />);
};

const mapStateToProps = state => ({
    currencyPairs: state.exchange.currencyPairs,
    basicAuth: state.appReducer.basicAuth,
    isCurrencyPairsLoaded: state.exchange.isCurrencyPairsLoaded,
    currentCalculation: state.calculations.currentCalculation
});

export default connect(mapStateToProps,{getCurrencyPairs, setCurrentCalculation})(CalculationsContainer);