import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  organization: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  updateOrganization: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  deleteOrganization: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  addOrganization: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  organizationInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
};

/* fetch organization*/
const fetchOrganizationsRequest = (state, action) =>
  update(state, {
    organization: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchOrganizationsSuccess = (state, action) =>
  update(state, {
    organization: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchOrganizationsError = (state, action) =>
  update(state, {
    organization: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

/* update organization*/
const updateOrganizationsRequest = (state, action) =>
  update(state, {
    updateOrganization: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const updateOrganizationsSuccess = (state, action) =>
  update(state, {
    updateOrganization: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully Updated !!" },
      data: { $set: action.payload },
    },
  });

const updateOrganizationsError = (state, action) =>
  update(state, {
    updateOrganization: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while updating !!" },
      data: { $set: null },
    },
  });

/* delete organization*/
const deleteOrganizationsRequest = (state, action) =>
  update(state, {
    deleteOrganization: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const deleteOrganizationsSuccess = (state, action) =>
  update(state, {
    deleteOrganization: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully delete !!" },
      data: { $set: action.payload },
    },
  });

const deleteOrganizationsError = (state, action) =>
  update(state, {
    deleteOrganization: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while deleting !!" },
      data: { $set: null },
    },
  });

/* add organization*/
const addOrganizationsRequest = (state, action) =>
  update(state, {
    addOrganization: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });
const addOrganizationsSuccess = (state, action) =>
  update(state, {
    addOrganization: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully added!!" },
      data: { $set: null },
    },
  });
const addOrganizationsError = (state, action) =>
  update(state, {
    addOrganization: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while adding!!" },
      data: { $set: null },
    },
  });

/* fetch organization info*/
const fetchOrganizationInfoRequest = (state, action) =>
  update(state, {
    organizationInfo: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchOrganizationInfoSuccess = (state, action) =>
  update(state, {
    organizationInfo: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchOrganizationInfoError = (state, action) =>
  update(state, {
    organizationInfo: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

export default handleActions(
  {
    [constants.FETCH_ORGANIZATION_REQUEST]: fetchOrganizationsRequest,
    [constants.FETCH_ORGANIZATION_SUCCESS]: fetchOrganizationsSuccess,
    [constants.FETCH_ORGANIZATION_ERROR]: fetchOrganizationsError,

    [constants.UPDATE_ORGANIZATION_REQUEST]: updateOrganizationsRequest,
    [constants.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationsSuccess,
    [constants.UPDATE_ORGANIZATION_ERROR]: updateOrganizationsError,

    [constants.DELETE_ORGANIZATION_REQUEST]: deleteOrganizationsRequest,
    [constants.DELETE_ORGANIZATION_SUCCESS]: deleteOrganizationsSuccess,
    [constants.DELETE_ORGANIZATION_ERROR]: deleteOrganizationsError,

    [constants.ADD_ORGANIZATION_REQUEST]: addOrganizationsRequest,
    [constants.ADD_ORGANIZATION_SUCCESS]: addOrganizationsSuccess,
    [constants.ADD_ORGANIZATION_ERROR]: addOrganizationsError,

    [constants.FETCH_ORGANIZATION_INFO_REQUEST]: fetchOrganizationInfoRequest,
    [constants.FETCH_ORGANIZATION_INFO_SUCCESS]: fetchOrganizationInfoSuccess,
    [constants.FETCH_ORGANIZATION_INFO_ERROR]: fetchOrganizationInfoError,
  },
  initialState
);
