import React from "react";
import { connect } from "react-redux";

import {
  selectForwardPointsInterpolation,
  selectAsyncFormState,
} from "../../redux/exchange/exchange-selector";

import ForwardPoints from "./ForwardPoints";

const ForwardPointsContainer = (props) => <ForwardPoints {...props} />;

const mapStateToProps = (state) => ({
  forwardPointsInterpolation: selectForwardPointsInterpolation(state),
  asyncFormState: selectAsyncFormState(state),
});

export default connect(mapStateToProps, {})(ForwardPointsContainer);
