// import {setCurrencyPairsLoaded} from "./reducer";

export const selectRates = (state) => state.exchange.rates;
export const selectRatesLoader = (state) => state.exchange.ratesLoader;
export const selectCurrencyPairsLoaded = (state) =>
  state.exchange.isCurrencyPairsLoaded;
export const selectCurrency = (state) => state.exchange.currency;
export const selectDirection = (state) => state.exchange.direction;
export const selectRatioData = (state) => state.exchange.ratioData;
export const selectTimeList = (state) => state.exchange.timeList;
export const selectCurrencyPairs = (state) => state.exchange.currencyPairs;
export const selectSortRates = (state) => state.exchange.sortRates;
export const selectChartData = (state) => state.exchange.chartData;
export const selectChartExtraData = (state) => state.exchange.chartExtraData;
export const selectChartCurrency = (state) => state.exchange.chartCurrency;
export const selectInstantTradeCheck = (state) =>
  state.exchange.instantTradeCheck;
export const selectForwardPointsInterpolation = (state) =>
  state.exchange.forwardPointsInterpolation;
export const selectAsyncFormState = (state) => state.exchange.asyncFormState;
