import React, {useState} from 'react';
import cn from "classnames";
import SpotForm from "./SpotForm";
import ForwardForm from "./ForwardForm";
import SwapForm from "./SwapForm";
import AuthenticationService from "../../../service/AuthenticationService";
import resetInstantTradeCheck from "../../../redux/exchange/reducer";

const RightSidebarTabs = ({direction, currencyPairs}) => {
    
    const isUserAdmin = AuthenticationService.isUserAdmin();
    const [selectedTab, setSelectedTab] = useState(0);

    const tabs = [
        {
            name: 'Spot',
            component: <SpotForm direction={direction} currencyPairs={currencyPairs} />
        },
        {
            name: 'Forward',
            component: <ForwardForm direction={direction} currencyPairs={currencyPairs} />
        },
        {
            name: 'Swap',
            component: <SwapForm direction={direction} currencyPairs={currencyPairs} />
        }
    ];


    return (
        <div className="form-tabs" >
            <div className="form-tabs__header">
                {tabs ? tabs.map((item, index) =>
                    <div key={index}
                         className={cn('form-tabs__switch', {'form-tabs__switch--active': index === selectedTab})}
                         onClick={() => 
                        {
                            setSelectedTab(index);
                        }
                        }>
                        {item.name}
                    </div>
                ) : null}
            </div>
            <div className="form-tabs__content">
                {tabs ? tabs.map((item, index) =>
                    <div key={index}
                         className={cn('form-tabs__item', {'form-tabs__item--active': index === selectedTab})}>
                        {item.component}
                    </div>
                ) : null}
            </div>
        </div>
    )
};

export default RightSidebarTabs;