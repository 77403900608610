
let requiredText = 'Field is required';
export const required = val => val ? undefined : requiredText;

export const maxLengthCreator = maxLength => val => {
    let maxLengthCreatorText = `Max length is ${maxLength} symbols`;
    if(val && val.length > maxLength) return maxLengthCreatorText;
    return undefined;
};

export const minLengthCreator = minLength => val => {
    let minLengthCreatorText = `Must be at least ${minLength} symbols`;
    if(val && val.length < minLength) return minLengthCreatorText;
};

let checkEmailText = 'Invalid email address';
export const checkEmail = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        checkEmailText : undefined;

let checkTelText = 'Invalid number format';
export const checkTel = value =>
    value && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/gi.test(value) ?
        checkTelText : undefined;

let checkNumberText = 'Must be a number';
export const checkNumber = value => value && isNaN(Number(value)) ? checkNumberText : undefined;

let commaCheckText = 'Must not contain commas';
export const commaCheck = value => value.includes(",") ? commaCheckText : undefined;

export const timeValidator = value => {
    let hours = value.slice(0,2);
    let minutes = value.slice(3,5);
    let seconds = value.slice(6,8);
    if (hours > 23 || minutes > 59 || seconds > 59) {
        return "Time given is not valid. Must be a valid 24hr time."
    } else {
        return undefined;
    }
}

export const millisecondTimeValidator = value => {
    let hours = value.slice(0,2);
    let minutes = value.slice(3,5);
    let seconds = value.slice(6,8);
    let milliseconds = value.slice(9,12);
    if (hours > 23 || minutes > 59 || seconds > 59 || milliseconds > 999) {
        return "Time given is not valid. Must be a valid 24hr time."
    } else {
        return undefined;
    }
}

let millisecondTimeFormatText = "Must follow the pattern hh:mm:ss:SSS";
export const millisecondTimeFormatValidator = value => {
    const regex = /[0-9][0-9]:[0-9][0-9]:[0-9][0-9]:[0-9][0-9][0-9]/g;
    const match = value.match(regex) ? undefined : millisecondTimeFormatText;
    return match;
}

let timeFormatText = "Must follow the pattern hh:mm:ss";
export const timeFormatValidator = value => {
    const regex = /[0-9][0-9]:[0-9][0-9]:[0-9][0-9]/g;
    const match = value.match(regex) ? undefined : timeFormatText;
    return match;
}

// export const phoneNumber = value =>
//     value && !/^(0|[1-9][0-9]{8})$/i.test(value) ? 'Invalid phone number, must be ${value.length} digits' : undefined;