import React, {useState} from 'react';
import cn from "classnames";

import DashboardTop from "./DashboardTop/DashboardTop";
import ChartContainer from "./Chart/ChartContainer";
import DashboardMiddle from "./DashboardMiddle/DashboardMiddle";

const Dashboard = ({ratioData, timeList, setCurrencySelectOpen, currency, setSortRates, rates}) => {
    const [isRatioSelectOpen, setRatioSelectOpened] = useState(false);

    return (
        <div className={cn('exchange-page__dashboard exchange-dashboard',
            {'ratio-opened': isRatioSelectOpen})}>
            <DashboardTop setRatioSelectOpened={setRatioSelectOpened}
                          isRatioSelectOpen={isRatioSelectOpen}
                          timeList={timeList}
                          rates={rates}
                          setCurrencySelectOpen={setCurrencySelectOpen}
                          currency={currency}
                          setSortRates={setSortRates}
                          ratioData={ratioData}/>
            <DashboardMiddle />
            <ChartContainer/>
        </div>
    )
};

export default Dashboard;