import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  users: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  updateUsers: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  updateContact: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  deleteUsers: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  addUsers: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  userInfoById: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  userInfoByUsername: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  updatePassword: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
};

/* fetch users*/
const fetchUsersRequest = (state, action) =>
  update(state, {
    users: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchUsersSuccess = (state, action) =>
  update(state, {
    users: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchUsersError = (state, action) =>
  update(state, {
    users: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

/* update user*/
const updateUsersRequest = (state, action) =>
  update(state, {
    updateUsers: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const updateUsersSuccess = (state, action) =>
  update(state, {
    updateUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully Updated !!" },
      data: { $set: action.payload },
    },
  });

const updateUsersError = (state, action) =>
  update(state, {
    updateUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while updating !!" },
      data: { $set: null },
    },
  });

/* delete users*/
const deleteUsersRequest = (state, action) =>
  update(state, {
    deleteUsers: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const deleteUsersSuccess = (state, action) =>
  update(state, {
    deleteUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully deleted !!" },
      data: { $set: action.payload },
    },
  });

const deleteUsersError = (state, action) =>
  update(state, {
    deleteUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while deleting !!" },
      data: { $set: null },
    },
  });

/* add user*/
const addUsersRequest = (state, action) =>
  update(state, {
    addUsers: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });
const addUsersSuccess = (state, action) =>
  update(state, {
    addUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully added!!" },
      data: { $set: null },
    },
  });
const addUsersError = (state, action) =>
  update(state, {
    addUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while adding!!" },
      data: { $set: null },
    },
  });

/* fetch user info by id*/
const fetchUserInfoByIdRequest = (state, action) =>
  update(state, {
    userInfoById: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchUserInfoByIdSuccess = (state, action) =>
  update(state, {
    userInfoById: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchUserInfoByIdError = (state, action) =>
  update(state, {
    userInfoById: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

/* fetch user info by username*/
const fetchUserInfoByUsernameRequest = (state, action) =>
  update(state, {
    userInfoByUsername: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchUserInfoByUsernameSuccess = (state, action) =>
  update(state, {
    userInfoByUsername: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchUserInfoByUsernameError = (state, action) =>
  update(state, {
    userInfoByUsername: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

/* update contact*/
const updateContactRequest = (state, action) =>
  update(state, {
    updateContact: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const updateContactSuccess = (state, action) =>
  update(state, {
    updateContact: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully Updated !!" },
      data: { $set: action.payload },
    },
  });

const updateContactError = (state, action) =>
  update(state, {
    updateContact: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while updating !!" },
      data: { $set: null },
    },
  });

/* update password*/
const updatePasswordRequest = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const updatePasswordSuccess = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully Updated !!" },
      data: { $set: action.payload },
    },
  });

const updatePasswordError = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while updating !!" },
      data: { $set: null },
    },
  });

export default handleActions(
  {
    [constants.FETCH_USERS_REQUEST]: fetchUsersRequest,
    [constants.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
    [constants.FETCH_USERS_ERROR]: fetchUsersError,

    [constants.UPDATE_USERS_REQUEST]: updateUsersRequest,
    [constants.UPDATE_USERS_SUCCESS]: updateUsersSuccess,
    [constants.UPDATE_USERS_ERROR]: updateUsersError,

    [constants.DELETE_USERS_REQUEST]: deleteUsersRequest,
    [constants.DELETE_USERS_SUCCESS]: deleteUsersSuccess,
    [constants.DELETE_USERS_ERROR]: deleteUsersError,

    [constants.ADD_USERS_REQUEST]: addUsersRequest,
    [constants.ADD_USERS_SUCCESS]: addUsersSuccess,
    [constants.ADD_USERS_ERROR]: addUsersError,

    [constants.FETCH_USER_INFO_BY_ID_REQUEST]: fetchUserInfoByIdRequest,
    [constants.FETCH_USER_INFO_BY_ID_SUCCESS]: fetchUserInfoByIdSuccess,
    [constants.FETCH_USER_INFO_BY_ID_ERROR]: fetchUserInfoByIdError,

    [constants.FETCH_USER_INFO_BY_USERNAME_REQUEST]: fetchUserInfoByUsernameRequest,
    [constants.FETCH_USER_INFO_BY_USERNAME_SUCCESS]: fetchUserInfoByUsernameSuccess,
    [constants.FETCH_USER_INFO_BY_USERNAME_ERROR]: fetchUserInfoByUsernameError,

    [constants.UPDATE_CONTACT_REQUEST]: updateContactRequest,
    [constants.UPDATE_CONTACT_SUCCESS]: updateContactSuccess,
    [constants.UPDATE_CONTACT_ERROR]: updateContactError,

    [constants.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
    [constants.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
    [constants.UPDATE_PASSWORD_ERROR]: updatePasswordError,
  },
  initialState
);
