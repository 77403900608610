import React from 'react';
import {Link} from "react-router-dom";
import history from "../../history";


import mobLogo from '../../assets/img/mob-mini-NCFX.png'
import twitterIcon from '../../assets/img/twitter.svg'
import linkedInIcon from '../../assets/img/linkedin.svg'

import './_footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__top">
                <div className="container">
                    <div className="footer__left">
                        {/* <Link to="/">
                            <img className="footer__logo" src={mobLogo} alt=""/>
                        </Link> */}
                        <div className="footer__col">
                            <h3>New Change FX</h3>
                            <div className="footer__text">
                                <p>
                                    <span>New Change FX,</span>
                                    <span>8th Floor,</span>
                                    <span>71 Queen Victoria Street,</span>
                                    <span>London,</span>
                                    <span>United Kingdom</span>
                                    <span>EC4V 4AY</span>
                                </p>
                            </div>
                        </div>
                        <div className="footer__menu">
                            <ul>
                                <li>
                                    <a href="https://www.newchangefx.com/about-us/" target="_blank">
                                        About Us
                                    </a>
                                </li>
                                <li className="disabled">
                                    <Link to="/">
                                        TCA
                                    </Link>
                                </li>
                                <li className="disabled">
                                    <Link to="/">
                                        Regulatory Library
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://www.newchangefx.com/insights/" target="_blank">
                                        Insights
                                    </a>
                                </li>
                                <li>
                                    <Link to="/" onClick={() => {
                                        history.push("/terms");
                                    }}>
                                        Terms
                                    </Link>
                                </li>
                                {/* <li>
                                    <a href="https://www.newchangefx.com/contact-us/" target="_blank">
                                        Contact Us
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        <div className="footer__menu">
                            <ul>
                                <li className="disabled">
                                    <Link to="/under-construction" onClick={() => {
                                        history.push("/under-construction");
                                    }}>
                                        Client Portal User Guide
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer__col">
                            <h3>Contact Us</h3>
                            <div className="footer__text">
                                <div className="footer__contact-item">
                                    <p>
                                        <span>Email:</span>
                                        <a href="mailto:info@newchangefx.com">
                                            info@newchangefx.com
                                        </a>
                                    </p>
                                </div>
                                <div className="footer__contact-item">
                                    <p>
                                        <span>Telephone:</span>
                                        <a href="mailto:+44 (0) 7826 866 414">
                                        +44 (0) 7826 866 414
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="footer__bottom">
                <div className="container">
                    <div className="footer__links">
                        <Link to="/">
                            Terms of Use
                        </Link>
                        <Link to="/">
                            Privacy Policy
                        </Link>
                        <Link to="/">
                            Cookie Policy
                        </Link>
                    </div>
                    <div className="footer__flex">
                        <div className="footer__copyright">
                            @2020 New Change FX
                        </div>
                        <ul className="footer__socials">
                            <li>
                                <a href="/">
                                    <img src={twitterIcon} alt=""/>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <img src={linkedInIcon} alt=""/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>*/}
        </footer>
    )
};

export default Footer;