import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import formatTime from "../../components/common/FormsControls/TimePicker";
import {
  required,
  timeFormatValidator,
  timeValidator,
} from "../../utils/validators/validators";
import {
  RenderDatePicker,
  RenderSelect,
  RenderField,
} from "../../components/common/FormsControls/FormsControls";
import { getForwardPointsInterpolation } from "../../redux/exchange/reducer";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";

const Form = (props) => {
  const { handleSubmit, reset, getForwardPointsInterpolation } = props;
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const submit = ({ currency_pair, value_date, executionDate, time }) => {
    let timestamp = moment(executionDate).format("YYYY-MM-DD") + " " + time;
    const forwardFormData = {
      currency_pair: currency_pair.value,
      trade_date_start_time: timestamp,
      value_date: moment(value_date).format("YYYY-MM-DD"),
    };
    getForwardPointsInterpolation(forwardFormData);
    setShowSuccessPopup(true);
    //reset('trade');

    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 500);
  };

  const [time, setTime] = useState();

  const timepickerChange = (e, value) => {
    const newValue = formatTime(value, false);
    setTime(newValue);
    props.change("time", newValue);
  };

  useEffect(() => {
    const form = props.forwardPointsForm;
    if (form) {
      if (form.values) {
        console.log(form);
        if (form.values.time != time) {
          props.change("time", time);
        }
      }
    }
  }, [props, props.forwardPointsForm, time]);

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className="right-sidebar-form forward-points-form"
      >
        <div className="right-sidebar-form__item">
          <Field
            validate={[required]}
            name="currency_pair"
            isSearchable={true}
            component={RenderSelect}
            placeholder={"Currency Pair"}
            options={props.currencyPairs}
          />
        </div>
        <div className="right-sidebar-form__item right-sidebar-form__item">
          <Field
            validate={[required]}
            name="executionDate"
            label="Execution Date"
            component={RenderDatePicker}
            type="text"
            className="form-control"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="right-sidebar-form__item">
          <Field
            validate={[required, timeFormatValidator, timeValidator]}
            name="time"
            label="Execution Time"
            type="text"
            component={RenderField}
            onChange={timepickerChange}
          />
        </div>
        <div className="right-sidebar-form__item">
          <Field
            validate={[required]}
            name="value_date"
            label="Value Date"
            component={RenderDatePicker}
            type="text"
            className="form-control"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="right-sidebar-form__item right-sidebar-form__item forward-points-form__button">
          <input
            className="right-sidebar-form__submit"
            type="submit"
            value="SUBMIT"
          />
        </div>
      </form>
      {/* <SuccessMessage show={showSuccessPopup} /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  forwardPointsForm: state.form.forwards,
});

const ForwardPointsForm = compose(
  reduxForm({
    form: "forwards",
  }),
  connect(mapStateToProps, { getForwardPointsInterpolation })
)(Form);

export default ForwardPointsForm;
