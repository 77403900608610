import React from 'react';

import logo from "../../assets/img/logo.svg";
import './_maintenance-page.scss';

const MaintenancePage = () => 
  <main className="maintenance-page">        
    <div className="maintenance-page__header">
      <div className="maintenance-page__header-container">
        <img
          className="header__logo "
          src={logo}
          alt=""
        />
      </div>
    </div> 
    <div className="maintenance-page__content">  
      <h2>We are down for scheduled maintenance right now.</h2> 
      <h3>Please check back later...</h3>
    </div>
  </main>
    
export default MaintenancePage;