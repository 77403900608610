import React, {useState, useEffect} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import moment from "moment";

import {RenderDatePicker, RenderField, RenderSelect} from "../../../components/common/FormsControls/FormsControls";
import {commaCheck, checkNumber, maxLengthCreator, minLengthCreator, required, timeValidator, timeFormatValidator} from "../../../utils/validators/validators";
import {setSwapCheck} from "../../../redux/exchange/reducer";
import SuccessMessage from "../../../components/SuccessMessage/SuccessMessage";
import formatTime from "../../../components/common/FormsControls/TimePicker";


const maxLength20 = maxLengthCreator(20);
const minLength3 = minLengthCreator(3);

const Form = (props) => {
    const {handleSubmit, reset, setSwapCheck} = props;
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const submit = ({near_leg_amount, far_leg_amount, currency_pair, far_leg, near_leg, sell_buy, executionDate, time, swap_points, near_leg_rate, far_leg_rate}) => {
        let timestamp = moment(executionDate).format('YYYY-MM-DD') + " " + time;
        const spotFormData = {
            near_leg_amount,
            far_leg_amount,
            swap_points,
            far_leg: moment(far_leg).format('YYYY-MM-DD'),
            near_leg: moment(near_leg).format('YYYY-MM-DD'),
            sell_buy: sell_buy === 'You B/S' ? 'buy' : 'sell',
            currency_pair: currency_pair.value,
            timestamp: moment(timestamp).format('YYYY-MM-DD HH:mm:ss'),
            near_leg_rate,
            far_leg_rate,
        };
        setSwapCheck(spotFormData);
        setShowSuccessPopup(true);
        //reset('swap');

        setTimeout(() => {
            setShowSuccessPopup(false);
        }, 500);
    };

    const [time, setTime] = useState();

    const timepickerChange = (e,value) => {
        const newValue = formatTime(value, false);
        setTime(newValue);
        props.change("time", newValue);
    }

    useEffect(() => {
        const form = props.swapForm.values;
        console.log(form);
        if (form.time != time) {
            props.change("time", time);
        }
    }, [props.swapForm.values])

    return (
        <form className="right-sidebar__form right-sidebar-form" onSubmit={handleSubmit(submit)}>
            <div className="right-sidebar-form__item right-sidebar-form__item--flex">
                <Field name="sell_buy" value={'You B/S'} text={'You B/S'} component={RenderField} checked={'checked'} type="radio"/>
                <Field name="sell_buy" value={'You S/B'} text={'You S/B'} component={RenderField} type="radio"/>
            </div>
            <div className="right-sidebar-form__item">
                <Field validate={[required]} name="currency_pair" isSearchable={true} component={RenderSelect} placeholder={"Currency Pair"} options={props.currencyPairs}/>
            </div>
            <div className="right-sidebar-form__item">
                <Field name="near_leg_amount"
                       validate={[required, minLength3, maxLength20, checkNumber]}
                       component={RenderField} label="Near Leg Amount" type="text"/>
            </div>
            <div className="right-sidebar-form__item">
                <Field name="far_leg_amount"
                       validate={[required, minLength3, maxLength20, checkNumber]}
                       component={RenderField} label="Far Leg Amount" type="text"/>
            </div>
            <div className="right-sidebar-form__item">
                <Field name="near_leg_rate"
                       validate={[required, minLength3, maxLength20, commaCheck, checkNumber]}
                       component={RenderField} label="Near Leg Rate" type="text"/>
            </div>
            <div className="right-sidebar-form__item">
                <Field name="far_leg_rate"
                       validate={[required, minLength3, maxLength20, commaCheck, checkNumber]}
                       component={RenderField} label="Far Leg Rate" type="text"/>
            </div>
            <div className="right-sidebar-form__item right-sidebar-form__item">
                <Field
                    validate={[required]}
                    name="executionDate"
                    label="Execution Date"
                    component={RenderDatePicker}
                    type="text"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="right-sidebar-form__item">
                <Field
                    validate={[required, timeFormatValidator, timeValidator]}
                    name="time"
                    label="Execution Time"
                    type="text"
                    component={RenderField}
                    onChange={timepickerChange}
                />
            </div>
            <div className="right-sidebar-form__item">
                <Field
                    validate={[required]}
                    name="near_leg"
                    label="Near Leg Date"
                    component={RenderDatePicker}
                    type="text"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="right-sidebar-form__item">
                <Field
                    validate={[required]}
                    name="far_leg"
                    label="Far Leg Date"
                    component={RenderDatePicker}
                    type="text"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="right-sidebar-form__item right-sidebar-form__item--full">
                <input className="right-sidebar-form__submit" type="submit" value="SUBMIT"/>
            </div>
            <SuccessMessage show={showSuccessPopup} />
        </form>
    )
};

const mapStateToProps = state => ({
    swapForm: state.form.swap,
});

const SwapForm = compose(
    reduxForm({
        form: 'swap',
        initialValues: {
            sell_buy: 'You B/S'
        }
    }),
    connect(mapStateToProps, {setSwapCheck})
)(Form);

export default SwapForm;