import React, { Component } from "react";
import { connect } from "react-redux";
import {Link, withRouter} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Datatable } from "@o2xp/react-datatable";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import WorkIcon from "@material-ui/icons/Work";
import GroupIcon from "@material-ui/icons/Group";
import * as actions from "../../redux/actions";
import ModalWindow from "../../components/ModalWindow";
import ClassIcon from "@material-ui/icons/Class";

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      organizationName: "",
      fullObjectMap: [],
      options: "",
      showModal: false,
      modalTitle: null,
      modalContent: null,
    };
  }

  componentDidMount() {
    this.props.fetchOrganizationsRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationList !== prevProps.organizationList) {
      this.setOptions(this.props.organizationList);
    }
  }

  setOptions(organizations) {
    let options = {
      actions: [],
      dimensions: {
        datatable: {
          width: "100%",
          height: "500px",
        },
      },
      keyColumn: "id",
      data: {
        columns: [
          {
            id: "id",
            label: "ID",
            colSize: "10px",
            editable: false,
          },
          {
            id: "organizationName",
            label: "Organization Name",
            colSize: "100px",
            editable: true,
          },
        ],
        rows: organizations,
      },
      features: {
        canEdit: true,
        canDelete: true,
        additionalActions: [
          {
            title: "Full Edit",
            icon: <EditAttributesIcon />,
            onClick: (e) => {
              let url = "/editOrganization?id=" + e.id;
              this.props.history.push(url);
            },
          },
          {
            title: "Show Roles",
            icon: <ClassIcon />,
            onClick: (e) => {
              this._renderRolesList(e);
            },
          },
          {
            title: "Show Users",
            icon: <GroupIcon />,
            onClick: (e) => {
              this._renderUsersList(e);
            },
          },
        ],
      },
    };

    this.setState({ options: options });
  }

  _renderRolesList = (e) => {
    let modalTitle = "Roles - " + e.organizationName;
    let content = "No Roles";
    if (e.roles && e.roles.length > 0) {
      content = e.roles.map((i) => {
        return (
          <div key={i.id}>
            {i.id} - {i.name}
          </div>
        );
      });
    }
    this.setState({
      showModal: true,
      modalTitle: modalTitle,
      modalContent: content,
    });
  };

  _renderUsersList = (e) => {
    let modalTitle = "Users - " + e.organizationName;
    let content = "No Users";
    if (e.users && e.users.length > 0) {
      content = e.users.map((i) => {
        return (
          <div key={i.id}>
            {i.id} - {i.fullName}
          </div>
        );
      });
    }
    this.setState({
      showModal: true,
      modalTitle: modalTitle,
      modalContent: content,
    });
  };

  actionsRow = ({ type, payload }) => {
    if (type === "save") {
      this.props.updateOrganizationsRequest(payload);
    } else if (type === "delete") {
      this.props.deleteOrganizationsRequest({ id: payload.id });
    }
  };

  render() {
    if (Object.keys(this.state.options).length === 0) {
      return [
        <div key="1" className="Organizations">
          <div className="lander">
            <div className="loading-text">Loading Organizations...</div>
          </div>
        </div>,
      ];
    } else {
      return [
        <div key="2" className="Organizations">
          <div className="lander">
            <div className="organizations-category">
              <form>
                <span className="current">Organizations</span>
              </form>
            </div>
            <div className="link-insert">
              <Link
                to="/InsertOrganization"
              >
                <WorkIcon /> Insert New Organization
              </Link>
            </div>
            <div>
              <Datatable
                options={this.state.options}
                actions={this.actionsRow}
              />
            </div>
          </div>
          <ModalWindow
            showModal={this.state.showModal}
            title={this.state.modalTitle}
            content={this.state.modalContent}
            onClose={() => {
              this.setState({
                showModal: false,
                content: null,
              });
            }}
          />
        </div>,
      ];
    }
  }
}

const mapStateToProps = (state) => ({
  organizationList: state.organization.organization.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizationsRequest: (data) =>
    dispatch(actions.fetchOrganizationsRequest(data)),
  updateOrganizationsRequest: (data) =>
    dispatch(actions.updateOrganizationsRequest(data)),
  deleteOrganizationsRequest: (data) =>
    dispatch(actions.deleteOrganizationsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Organizations)
);
