import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {getTimeSeries as getSpotTimeSeries, getTimeSeriesFull as getSpotTimeSeriesFull} from "../../../redux/data/spot/reducer.js";
import {getTimeSeries as getForwardTimeSeries, getTimeSeriesFull as getForwardTimeSeriesFull} from "../../../redux/data/forward/reducer.js";
import TimeSeriesDisplay from "./TimeSeriesDisplay.jsx";
import DataForm from "../DataForm.jsx";
import moment from "moment";
import exportCsv from "../../ExportCsv";

const TimeSeriesContainer = props => {
    const {financialInstrument, dataView} = props;

    const {spotTimeSeries, spotTimeSeriesRequestError, spotTimeSeriesLoading, spotLastRequestBody, spotIsFullUpToDate, spotTimeSeriesFull,
        spotTimeSeriesFullRequestSent, spotTimeSeriesFullRequestError, getSpotTimeSeries, getSpotTimeSeriesFull} = props;
    const {forwardTimeSeries, forwardTimeSeriesRequestError, forwardTimeSeriesLoading, forwardLastRequestBody, forwardIsFullUpToDate, forwardTimeSeriesFull,
        forwardTimeSeriesFullRequestSent, forwardTimeSeriesFullRequestError, getForwardTimeSeries, getForwardTimeSeriesFull} = props;

    const [timeSeries, setTimeSeries] = useState(spotTimeSeries);
    const [timeSeriesRequestError, setTimeSeriesRequestError] = useState(spotTimeSeriesRequestError);
    const [timeSeriesLoading, setTimeSeriesLoading] = useState(spotTimeSeriesLoading);

    const [lastRequestBody, setLastRequestBody] = useState(spotLastRequestBody);
    const [isFullUpToDate, setIsFullUpToDate] = useState(spotIsFullUpToDate);

    const [timeSeriesFull, setTimeSeriesFull] = useState(spotTimeSeriesFull);
    const [timeSeriesFullRequestSent, setTimeSeriesFullRequestSent] = useState(spotTimeSeriesFullRequestSent);
    const [timeSeriesFullRequestError, setTimeSeriesFullRequestError] = useState(spotTimeSeriesFullRequestError);

    const [exportToCsv, setExportToCsv] = useState(false);

    const [isCsvDownloadFinished, setIsCsvDownloadFinished] = useState(false);

    const [form, setForm] = useState({})

    const submit = (data) => {
        setIsCsvDownloadFinished(false);
        
        switch(financialInstrument) {
            case 0:
                return spotSubmit(data);
            case 1:
                return forwardSubmit(data);
            default:
              // code block
        }
    }

    const spotSubmit = (request) => {
        const data = {
            currencyPair: request.currencyPair.value,
            startDate: moment(request.startDate).format('YYYY-MM-DD') + " " + request.startTime,
            endDate: moment(request.endDate).format('YYYY-MM-DD') + " " + request.endTime,
        }
        setForm(data);
        getSpotTimeSeries(data);
    }

    const forwardSubmit = (request) => {

        const data = {
            currencyPair: request.currencyPair.value,
            startDate: moment(request.startDate).format('YYYY-MM-DD') + " " + request.startTime,
            endDate: moment(request.endDate).format('YYYY-MM-DD') + " " + request.endTime,
            tenor: request.tenor.value
        }
        setForm(data);
        getForwardTimeSeries(data);
    }

    useEffect(() => {
        switch(financialInstrument) {
        case 0:
            setTimeSeries(spotTimeSeries);
            setTimeSeriesRequestError(spotTimeSeriesRequestError);
            setTimeSeriesLoading(spotTimeSeriesLoading);

            setLastRequestBody(spotLastRequestBody);
            setIsFullUpToDate(spotIsFullUpToDate);

            setTimeSeriesFull(spotTimeSeriesFull);
            setTimeSeriesFullRequestSent(spotTimeSeriesFullRequestSent);
            setTimeSeriesFullRequestError(spotTimeSeriesFullRequestError);
            
            break;
        case 1:
            setTimeSeries(forwardTimeSeries);
            setTimeSeriesRequestError(forwardTimeSeriesRequestError);
            setTimeSeriesLoading(forwardTimeSeriesLoading);
            
            setLastRequestBody(forwardLastRequestBody);
            setIsFullUpToDate(forwardIsFullUpToDate);

            setTimeSeriesFull(forwardTimeSeriesFull);
            setTimeSeriesFullRequestSent(forwardTimeSeriesFullRequestSent);
            setTimeSeriesFullRequestError(forwardTimeSeriesFullRequestError);

          break;
        default:
          // code block
        }
    }, [financialInstrument, spotTimeSeries, spotTimeSeriesRequestError, spotTimeSeriesLoading,
        spotLastRequestBody, spotIsFullUpToDate, spotTimeSeriesFull,
        spotTimeSeriesFullRequestSent, spotTimeSeriesFullRequestError,
        forwardTimeSeries, forwardTimeSeriesRequestError, forwardTimeSeriesLoading, forwardLastRequestBody,
        forwardIsFullUpToDate, forwardTimeSeriesFull, forwardTimeSeriesFullRequestSent,
        forwardTimeSeriesFullRequestError]);

    useEffect(() => {
        if (exportToCsv){
            setIsCsvDownloadFinished(false);
            if(timeSeries.isSampled){
                if(isFullUpToDate) {
                    exportCsv(timeSeriesFull.series, financialInstrument, dataView, form);
                    setExportToCsv(false);
                    setIsCsvDownloadFinished(true);
                } else if (!timeSeriesFullRequestSent){
                    if (!timeSeriesFullRequestError){
                        fullSubmit();
                    }
                }
            } else {
                exportCsv(timeSeries.series, financialInstrument, dataView, form);
                setExportToCsv(false);
                setIsCsvDownloadFinished(true);
            }
        }
    },[exportToCsv, timeSeriesFullRequestSent, isFullUpToDate]);

    const fullSubmit = () => {

        if (financialInstrument == 0)
            getSpotTimeSeriesFull(lastRequestBody);
        else 
            getForwardTimeSeriesFull(lastRequestBody);
    }

    return (
    <>
            <div>
                <DataForm {...props} onSubmit={submit}/>
                <TimeSeriesDisplay timeSeries={timeSeries} setExportToCsv={setExportToCsv} timeSeriesFullRequestSent={timeSeriesFullRequestSent} timeSeriesFullRequestError={timeSeriesFullRequestError}
                 timeSeriesLoading={timeSeriesLoading} timeSeriesRequestError={timeSeriesRequestError} isCsvDownloadFinished={isCsvDownloadFinished}/>  
            </div>
    </>)
};

const mapStateToProps = state => ({
    spotTimeSeries: state.spot.timeSeries,
    spotTimeSeriesRequestError: state.spot.timeSeriesRequestError,
    spotTimeSeriesLoading: state.spot.timeSeriesLoading,
   
    spotLastRequestBody: state.spot.lastRequestBody,
    spotIsFullUpToDate: state.spot.isFullUpToDate,

    spotTimeSeriesFull: state.spot.timeSeriesFull,
    spotTimeSeriesFullRequestSent: state.spot.timeSeriesFullRequestSent,
    spotTimeSeriesFullRequestError: state.spot.timeSeriesFullRequestError,
    
    forwardTimeSeries: state.forward.timeSeries,
    forwardTimeSeriesRequestError: state.forward.timeSeriesRequestError,
    forwardTimeSeriesLoading: state.forward.timeSeriesLoading,

    forwardLastRequestBody: state.forward.lastRequestBody,
    forwardIsFullUpToDate: state.forward.isFullUpToDate,

    forwardTimeSeriesFull: state.forward.timeSeriesFull,
    forwardTimeSeriesFullRequestSent: state.forward.timeSeriesFullRequestSent,
    forwardTimeSeriesFullRequestError: state.forward.timeSeriesFullRequestError,
    
    financialInstrument: state.data.financialInstrument,
    dataView: state.data.dataView
});

export default connect(mapStateToProps,{getSpotTimeSeries, getSpotTimeSeriesFull, getForwardTimeSeries, getForwardTimeSeriesFull})(TimeSeriesContainer);