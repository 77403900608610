import React, {useRef, useState, useEffect} from 'react';
import { curveCardinal } from 'd3-shape';
import {AreaChart, Brush, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import {useContainerDimensions} from '../../../utils/helpers';


const TwapDisplay = ({twap, twapRequestError, twapLoading, setExportToCsv, isCsvDownloadFinished}) => {
    const containerRef = useRef();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000);
    const { width, height } = useContainerDimensions(containerRef);
    const cardinal = curveCardinal.tension(1);

    function CustomTooltip({ payload, label, active}) {
        if (active && payload){
            return (
                <div>
                    <h6><b>{`${payload[0].payload.time}`}</b></h6>
                    <h6 style={{color: "#F95987"}}><b>{`${payload[0].payload.rate}`}</b></h6>
                </div>
            );
        }
        return null;
    }

    const wrapperStyle = { padding: "10px", backgroundColor: '#FFF', border: '1px solid #d5d5d5' };

    useEffect(() => {
        setMax(twap.maxRate);
        setMin(twap.minRate);
    }, [twap.minRate, twap.maxRate]);

    return (<>
                {twapRequestError &&
                    (<div className="calculations__display">
                        <span className="calculations__display__error">{twapRequestError.message}</span>
                    </div>)
                }

                {!twapRequestError && !twapLoading &&
                (<div className="account-page__controls web-action-buttons" style={{marginTop: "10px"}}>
                    <div className="nice-select open" tabIndex="0">
                        <span
                            className="current"
                            onClick={() => (setExportToCsv(true))}
                            style={{cursor: "pointer"}}
                        >
                            Export to CSV
                        </span>
                    </div>

                {twapRequestError && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#F95987", fontWeight: "bold"}}>{twapRequestError}</h2> )}
                {isCsvDownloadFinished && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#6fdeaf", fontWeight: "bold"}}>Your CSV download is complete.</h2> )}</div>)}

                {!twapLoading? (
                <div className="exchange-dashboard__chart" ref={containerRef}>
                    <AreaChart width={width} height={height} data={twap.series}
                            margin={{top: 50, right: 0, left: 10, bottom: 10}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F95987" stopOpacity={0.3}/>
                                <stop offset="95%" stopColor="#F95987" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#eee"/>
                        <XAxis dataKey="time" minTickGap={15} padding={window.innerWidth < 767 ? { right: 50 } : {}} />
                        <YAxis dataKey="rate" orientation="right" hide={window.innerWidth < 767}
                            domain={[min, max]}
                        />
                        <Tooltip wrapperStyle={wrapperStyle} content={<CustomTooltip />}/>
                        <Area type={cardinal} dataKey="rate" stroke="#F95987" strokeWidth={4} fill="url(#colorUv)"/>
                        
                    </AreaChart>
                </div>):(<div className="rates-loader"/>)}

                <div className="calculations__display">
        {!twapRequestError && (twap.numberOfSamples < 100) && (twap.numberOfSamples > 0) && !twapLoading &&
            (<>
                {/* <p><span className="calculations__display__title">Implementation Shortfall (NOT FINISHED):</span>{implementationShortfall}</p> */}
                <table className="calculations__table account-page__table">
                    <thead>
                    <tr>
                        <th>
                            <div>
                                <span>Timestamp</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>TWAP</span>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {twap.series.map((fixing, index) => {
                        return (
                            <tr key={index}>
                                <td>{fixing.time}</td>
                                <td>{fixing.rate}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
                </>
                )
            }
        </div>


            </>
        )
}

export default TwapDisplay;