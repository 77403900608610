import React, {useState} from 'react';


const TcaDisplay = props => {
    const {tca, tcaError} = props;
    
    return (
        <div className="calculations__display">
            {tcaError &&
                (<><span className="calculations__display__error">{tcaError.message}</span></>)}

            {tca.length > 0 &&
                (<>
                    <table className="calculations__table account-page__table">
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <span>NCFX Benchmark</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span>USD Cost</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span>USD bp Cost</span>
                                </div>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                            {tca.map((result, index) => (
                            <tr key={index}>
                                <td>{result.ncfxBenchmark}</td>
                                <td>${result.usdCost}</td>
                                <td>{result.usdBpCost}</td>
                            </tr>))}
                        </tbody>
                    </table>
                </>)}
        </div>
        )
};

export default TcaDisplay;