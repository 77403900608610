import React, {useState} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Link, withRouter} from "react-router-dom";
import * as actions from "../redux/actions";
import {checkEmail, maxLengthCreator, minLengthCreator, required} from "../utils/validators/validators";
import {RenderField} from "../components/common/FormsControls/FormsControls";
import LeftSide from "../components/LeftSide";
import { useLocation } from "react-router-dom";
import cn from 'classnames';


import "../assets/css/main.css";
import "../assets/css/reset.css";

const maxLength20 = maxLengthCreator(20);
const minLength5 = minLengthCreator(5);

const LoginForm = (props) => {

    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    if(token){
        props.setAuthorised({ token })
    }

    const {handleSubmit, reset, login} = props;

    
    const submit = (values) => {
        props.loginRequest(values);

        preventDoubleTap();
        reset('login');
    };

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const preventDoubleTap = () => {
    setSubmitDisabled(true);
    
    setTimeout(()=>{
        setSubmitDisabled(false);
    }, 5000)
    }

    return (
        <main className="main home-page">
            <LeftSide/>
            <div className="home-page__right">
                <div className="home-page__forms">
                    <div className="form-item form-item_login form-item_active">
                        <h3>LOGIN</h3>
                        <span className="form-item__server-message form-item__server-message--error">{login.message}</span>
                        <form className="form-item__form" onSubmit={handleSubmit(submit)}>
                            <Field name="username"
                                   validate={[required, checkEmail]}
                                   component={RenderField} label="Email Address" type="text"/>
                            <Field name="password"
                                   validate={[required, maxLength20]}
                                   component={RenderField} label="Password" type="password"/>
                            <Field name="remember" component={RenderField} type="checkbox"
                                   text={'Remember me'} linkPath={'/ForgotPassword'} linkText={'Forgot password?'}/>
                            <input className="form-item__submit" type="submit" value="Login"/>
                            <div style={{justifyContent: 'left'}} className="form-item__bottom">
                                Don’t have account?
                                <Link  className="form-item__link" to="/Register">
                                    Register here  
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
};

const Login = reduxForm({
    form: 'login'
})(LoginForm);

const mapStateToProps = (state) => ({
    login: state.auth.login
});

const mapDispatchToProps = (dispatch) => ({
    loginRequest: (data) => dispatch(actions.loginRequest(data)),
    setAuthorised: (data) => dispatch(actions.setAuthorised(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
