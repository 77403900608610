import React, {useEffect, useState} from 'react';
import Select from "react-select";
import {connect} from "react-redux";
import cn from 'classnames';

import {getChartData, getRates, setChartCurrency} from "../redux/exchange/reducer";
import {selectBasicAuth} from "../redux/select-app-reducer";
import {selectSortRates} from "../redux/exchange/exchange-selector";

const CurrencySelect = ({isSorting = false, currency, setCurrencySelectOpen, setSortRates, getRates, basicAuth,
                            isMulti, getChartData, timeValue, placeholder, setChartCurrency, sortRates}) => {
    const [options, setOptions] = useState([]);
    const [disableSelect, setDisableSelect] = useState(false);

    useEffect(() => {
        setOptions(currency.map(item => ({label: item, value: item})));
    }, [currency]);

    useEffect(() => {
        if(sortRates.length >= (14 - 6) && isMulti) {
            setDisableSelect(true);
        } else {
            setDisableSelect(false);
        }
    }, [sortRates]);

    const handleChange = (value) => {
        if (isSorting && isMulti) {
            let newValue = value || [];
            let array = newValue.map(item => item.value);
            setSortRates(array);
            getRates(['EURUSD',
                'GBPUSD',
                'USDCHF',
                'USDJPY',
                'AUDUSD',
                'EURGBP'].concat(array), basicAuth);
        }

        if(!isMulti && timeValue?.value) {
            getChartData(timeValue.value, value.value);
            setChartCurrency(value.value);
        }
    }

    return (
        <div>
        <Select className={cn('currency-select', {'disabled': disableSelect})}
                classNamePrefix="currency-select"
                isSearchable={false}
                isMulti={isMulti}
                options={options}
                placeholder={placeholder}
                onChange={(value) => handleChange(value)}
                onMenuOpen={() => setCurrencySelectOpen(true)}
                onMenuClose={() => setCurrencySelectOpen(false)}
                />

                {disableSelect && (
                    <span className="tooltipText">
                    You have reached the maximum number of currency pairs.
                    </span>
                )}
        </div>
    )
};

const mapStateToProps = state => ({
    basicAuth: selectBasicAuth(state),
    sortRates: selectSortRates(state)
});

export default connect(mapStateToProps, {getRates, getChartData, setChartCurrency})(CurrencySelect);