import React, {useState} from 'react';
import {connect} from "react-redux";
import Data from "./Data.jsx";
import {getCurrencyPairs} from "../../redux/exchange/reducer.js"
import {setFinancialInstrument} from "../../redux/data/reducer.js"

const DataContainer = props => {

    const {currencyPairs, basicAuth, isCurrencyPairsLoaded, getCurrencyPairs} = props;

    const [isCurrencyPairsLoading, setCurrencyPairsLoading] = useState(false);

    if ((!isCurrencyPairsLoaded) && (!isCurrencyPairsLoading)){
        setCurrencyPairsLoading (true);
        getCurrencyPairs(basicAuth);
    }
    return (<Data {...props} />);
};

const mapStateToProps = state => ({
    currencyPairs: state.exchange.currencyPairs,
    basicAuth: state.appReducer.basicAuth,
    isCurrencyPairsLoaded: state.exchange.isCurrencyPairsLoaded,
    financialInstrument: state.data.financialInstrument
});

export default connect(mapStateToProps,{getCurrencyPairs, setFinancialInstrument})(DataContainer);