import React, {useState, useEffect} from 'react';
import {Container} from "react-bootstrap";
import {connect} from "react-redux";

import {selectServerError} from "../redux/select-app-reducer";
import {setServerError} from "../redux/app-reducer";

import './ErrorPage.scss';

const ErrorPage = ({serverError, setServerError}) => {
    let [error, setError] = useState('');

    useEffect(() => {
        setError(serverError);
        setServerError('');

        // eslint-disable-next-line
    }, []);

    return(
        <main className="main error-page">
            <Container className="main-container">
                {JSON.stringify(error)}
            </Container>
        </main>
    )
};

const mapStateToProps = state => ({
    serverError: selectServerError(state)
});

export default connect(mapStateToProps, {setServerError})(ErrorPage);
