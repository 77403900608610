import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  roles: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  updateRoles: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  deleteRoles: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  addRoles: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
};

/* fetch roles*/
const fetchRolesRequest = (state, action) =>
  update(state, {
    roles: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchRolesSuccess = (state, action) =>
  update(state, {
    roles: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchRolesError = (state, action) =>
  update(state, {
    roles: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

/* update role*/
const updateRolesRequest = (state, action) =>
  update(state, {
    updateRoles: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const updateRolesSuccess = (state, action) =>
  update(state, {
    updateRoles: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully Updated !!" },
      data: { $set: action.payload },
    },
  });

const updateRolesError = (state, action) =>
  update(state, {
    updateRoles: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while updating !!" },
      data: { $set: null },
    },
  });

/* delete roles*/
const deleteRolesRequest = (state, action) =>
  update(state, {
    deleteRoles: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const deleteRolesSuccess = (state, action) =>
  update(state, {
    deleteRoles: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully deleted !!" },
      data: { $set: action.payload },
    },
  });

const deleteRolesError = (state, action) =>
  update(state, {
    deleteRoles: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while deleting !!" },
      data: { $set: null },
    },
  });

/* add role*/
const addRolesRequest = (state, action) =>
  update(state, {
    addRoles: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });
const addRolesSuccess = (state, action) =>
  update(state, {
    addRoles: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Successfully added!!" },
      data: { $set: null },
    },
  });
const addRolesError = (state, action) =>
  update(state, {
    addRoles: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "Error while adding!!" },
      data: { $set: null },
    },
  });

export default handleActions(
  {
    [constants.FETCH_ROLES_REQUEST]: fetchRolesRequest,
    [constants.FETCH_ROLES_SUCCESS]: fetchRolesSuccess,
    [constants.FETCH_ROLES_ERROR]: fetchRolesError,

    [constants.UPDATE_ROLES_REQUEST]: updateRolesRequest,
    [constants.UPDATE_ROLES_SUCCESS]: updateRolesSuccess,
    [constants.UPDATE_ROLES_ERROR]: updateRolesError,

    [constants.DELETE_ROLES_REQUEST]: deleteRolesRequest,
    [constants.DELETE_ROLES_SUCCESS]: deleteRolesSuccess,
    [constants.DELETE_ROLES_ERROR]: deleteRolesError,

    [constants.ADD_ROLES_REQUEST]: addRolesRequest,
    [constants.ADD_ROLES_SUCCESS]: addRolesSuccess,
    [constants.ADD_ROLES_ERROR]: addRolesError,
  },
  initialState
);
