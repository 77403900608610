import {calculationAPI, submitForms} from "../../exhange-api/api";
import moment from 'moment';

const SET_PRICE_IMPACT = 'calculations/SET_PRICE_IMPACT';
const SET_PRICE_IMPACT_REQUEST_ERROR = 'calculations/SET_PRICE_IMPACT_REQUEST_ERROR';

const SET_VOLATILITY = 'calculations/SET_VOLATILITY';
const SET_VOLATILITY_REQUEST_ERROR = 'calculations/SET_VOLATILITY_REQUEST_ERROR';

const SET_DECAY = 'calculations/SET_DECAY';
const SET_DECAY_REQUEST_SUCCESS = 'calculations/SET_DECAY_REQUEST_SUCCESS';
const SET_DECAY_REQUEST_ERROR = 'calculations/SET_DECAY_REQUEST_ERROR';

const SET_IMPLEMENTATION_SHORTFALL = 'calculations/SET_IMPLEMENTATION_SHORTFALL';
const SET_IMPLEMENTATION_SHORTFALL_REQUEST_ERROR = 'calculations/SET_IMPLEMENTATION_SHORTFALL_REQUEST_ERROR';

const SET_TCA = 'calculations/SET_TCA';
const SET_TCA_REQUEST_ERROR = 'calculations/SET_TCA_REQUEST_ERROR';
const SET_CURRENT_CALCULATION = 'calculations/SET_CURRENT_CALCULATION';

const initialState = {
    priceImpact: [],
    priceImpactRequestError: false,
    volatility: {
        costOfVolatility: 0,
        usdConversionRate: 0,
        usdCostOfVolatility: 0
    },
    volatilityRequestError: false,
    decay:{
        decays: [],
        maxCost: 0,
        minCost: 0,
        centreTime: null
    },
    decayRequestSuccess: false,
    decayRequestError: false,
    implementationShortfall: {
        shortfall: [],
        minCost: null,
        maxCost: null,
        total: []
    },
    implementationShortfallRequestError: false,
    tca: [],
    tcaRequestError: false,
    currentCalculation: 0
};

const calculationsReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_IMPLEMENTATION_SHORTFALL:
        case SET_IMPLEMENTATION_SHORTFALL_REQUEST_ERROR:
        case SET_PRICE_IMPACT:
        case SET_PRICE_IMPACT_REQUEST_ERROR:
        case SET_VOLATILITY:
        case SET_VOLATILITY_REQUEST_ERROR:
        case SET_DECAY:
        case SET_DECAY_REQUEST_SUCCESS:
        case SET_DECAY_REQUEST_ERROR:
        case SET_TCA:
        case SET_TCA_REQUEST_ERROR:
        case SET_CURRENT_CALCULATION:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const setPriceImpact = priceImpact => ({type: SET_PRICE_IMPACT, payload: {priceImpact}});
export const setPriceImpactRequestError = priceImpactRequestError => ({type: SET_PRICE_IMPACT_REQUEST_ERROR, payload: {priceImpactRequestError}});
export const setImplementationShortfall = implementationShortfall => ({type: SET_IMPLEMENTATION_SHORTFALL, payload: {implementationShortfall}});
export const setImplementationShortfallRequestError = implementationShortfallRequestError => ({type: SET_IMPLEMENTATION_SHORTFALL_REQUEST_ERROR, payload: {implementationShortfallRequestError}});
export const setVolatility = volatility => ({type: SET_VOLATILITY, payload: {volatility}});
export const setVolatilityRequestError = volatilityRequestError => ({type: SET_VOLATILITY_REQUEST_ERROR, payload: {volatilityRequestError}});
export const setDecay = decay => ({type: SET_DECAY, payload: {decay}});
export const setDecayRequestSuccess = decayRequestSuccess => ({type: SET_DECAY_REQUEST_SUCCESS, payload: {decayRequestSuccess}});
export const setDecayRequestError = decayRequestError => ({type: SET_DECAY_REQUEST_ERROR, payload: {decayRequestError}});
export const setTca = tca => ({type: SET_TCA, payload: {tca}});
export const setTcaRequestError = tcaRequestError => ({type: SET_TCA_REQUEST_ERROR, payload: {tcaRequestError}});
export const setCurrentCalculation = currentCalculation => ({type: SET_CURRENT_CALCULATION, payload: {currentCalculation}});

export const getImplementationShortfall = data => async (dispatch) => {
    try {
        dispatch(setImplementationShortfallRequestError(false));
        const result = await calculationAPI.getImplementationShortfall(data);
        if(result.code === 404) {
            console.error(result);
        } else {
                if(result.data.shortfall){
                    let implementationShortfallObject = {
                        shortfall: result.data.shortfall.slice(0,4),
                        maxCost: result.data.maxCost,
                        minCost: result.data.minCost,
                        total: result.data.shortfall.slice(4,5)
                    }

                    dispatch(setImplementationShortfall(implementationShortfallObject));
                }
                else {
                    dispatch(setImplementationShortfallRequestError(result.data.error));
                    dispatch(setImplementationShortfall(initialState.implementationShortfall));
                }
  
              
        }
  
    } catch (e) {
        console.error(e);
        dispatch(setImplementationShortfall(initialState.implementationShortfall));
    }
  };

export const getPriceImpact = data => async (dispatch) => {
  try {
      dispatch(setPriceImpactRequestError(false));
      const result = await calculationAPI.getPriceImpact(data);
      if(result.code === 404) {
          console.error(result);
      } else {
            if(result.data.permanentUsdCost){
                let priceImpactData = [];
                let datapoint = {
                        permanentUsdCost: result.data.permanentUsdCost,
                        temporaryUsdCost: result.data.temporaryUsdCost
                    };
                priceImpactData.push(datapoint);
                dispatch(setPriceImpact(priceImpactData));
            }
            else {
                dispatch(setPriceImpactRequestError(result.data.error));
                dispatch(setPriceImpact(initialState.priceImpact));
            }

            
      }

  } catch (e) {
      console.error(e);
      dispatch(setPriceImpact(initialState.priceImpact));
  }
};

export const getVolatility = data => async (dispatch) => {
    try {
        dispatch(setVolatilityRequestError(false));
        const result = await calculationAPI.getVolatility(data);
        if(result.code === 404) {
            dispatch(setVolatilityRequestError(true));
            console.error(result);
        } else {
            if (result.data.costOfVolatility){
                dispatch(setVolatility(result.data));
            } else {
                dispatch(setVolatilityRequestError(result.data.error));
            }
        }
  
    } catch (e) {
        console.error(e);
    }
  };

export const getDecay = data => async (dispatch) => {
try {
    dispatch(setDecayRequestError(false));
    const result = await calculationAPI.getDecay(data);
    if(result.code === 404) {
        console.error(result);
    } else {
        if (result.data.decayTimes){
            let decays = [];
            for (let i = 0; i < result.data.decayTimes.length; i++){
                
                let decay = {
                    
                    time: moment(result.data.decayTimes[i]).format('yyyy-MM-DD HH:mm:ss'),
                    cost: result.data.decayCosts[i],
                }
                decays.push(decay);
            }

            let decayObject = {
                decays: decays,
                maxCost: result.data.maxCost,
                minCost: result.data.minCost,
                centreTime: result.data.centreTime
            }
            
            dispatch(setDecay(decayObject));
            dispatch(setDecayRequestSuccess(true));
        }
        else if (result.data.error) {
            dispatch(setDecayRequestSuccess(false));
            dispatch(setDecayRequestError(result.data.error))
            dispatch(setDecay(initialState.decay))
        }
        else {
            console.log("Unforseen decay error.")
        }
    }
    

} catch (e) {
    console.error(e);
    dispatch(setDecayRequestError(e));
    dispatch(setDecay(initialState.decay));
}
};

export const getTca = data => async (dispatch) => {
    try {
        dispatch(setTcaRequestError(false));
        const result = await submitForms.setSpotCheck(data);
        if(result.data.ncfx_benchmark) {
                let data = [];
                let datapoint = {
                        ncfxBenchmark: result.data.ncfx_benchmark,
                        usdCost: result.data.usd_cost,
                        usdBpCost: result.data.usd_bp_cost
                    };
                data.push(datapoint);

            dispatch(setTca(data));
        } else if (result.data.error) {
            dispatch(setTcaRequestError(result.data.error));
            dispatch(setTca(initialState.tca));
        } else {
            console.log("Unforseen tca error");
        }
  
    } catch (e) {
        console.error(e);
        dispatch(setTcaRequestError(e));
        dispatch(setTca(initialState.tca));
    }
  };

export default calculationsReducer;