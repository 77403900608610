import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {getTwap} from "../../../redux/data/spot/reducer.js";
import TwapDisplay from "./TwapDisplay.jsx";
import DataForm from "../DataForm.jsx";
import moment from "moment";
import exportCsv from "../../ExportCsv";

const TwapContainer = props => {
    const {financialInstrument, dataView} = props;

    const {twap, twapRequestError, getTwap, twapLoading} = props;

    const [exportToCsv, setExportToCsv] = useState(false);

    const [isCsvDownloadFinished, setIsCsvDownloadFinished] = useState(false);

    const [form, setForm] = useState({});

    const submit = (request) => {
        setIsCsvDownloadFinished(false);
        const data = {
            currencyPair: request.currencyPair.value,
            startDate: moment(request.startDate).format('YYYY-MM-DD') + " " + request.startTime,
            endDate: moment(request.endDate).format('YYYY-MM-DD') + " " + request.endTime,
            interval: request.interval.value
        }
        setForm(data);
        getTwap(data);
    };

    useEffect(() => {
        if (exportToCsv){
            setIsCsvDownloadFinished(false);
            exportCsv(twap.series, financialInstrument, dataView, form);
            setExportToCsv(false);
            setIsCsvDownloadFinished(true);
        }
    },[exportToCsv]);

    return (
    <>
            <div>
                <DataForm {...props} onSubmit={submit}/>
                <TwapDisplay twap={twap} setExportToCsv={setExportToCsv} twapLoading={twapLoading}
                 twapRequestError={twapRequestError} isCsvDownloadFinished={isCsvDownloadFinished}/>  
            </div>
    </>)
};


const mapStateToProps = state => ({
    twap: state.spot.twap,
    twapRequestError: state.spot.twapRequestError,
    twapLoading: state.spot.twapLoading,

    financialInstrument: state.data.financialInstrument,
    dataView: state.data.dataView
});

export default connect(mapStateToProps,{getTwap})(TwapContainer);