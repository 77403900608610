import React, { Component } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import "./ChangePassword.css";
import AuthenticationService from "../service/AuthenticationService";
import { Button, Form } from "react-bootstrap";
import * as QueryString from "query-string";
import BG from "../assets/img/BG.png";
import $ from "jquery";
import { ReactSVG } from "react-svg";
import closeIcon from "../assets/img/close-eye-icon.svg";
import openIcon from "../assets/img/eye-icon.svg";
import { showNotification } from "../service/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import jwt from "jwt-decode";
import {Link, withRouter} from "react-router-dom";
import * as actions from "../redux/actions";
import {connect} from "react-redux";

class ChangeExpiredPassword extends Component {
  constructor(props) {
    super(props);
    const params = QueryString.parse(this.props.location.search);
    this.state = {
      passwordCheck: "",
      password: "",
      oldPassword: "",
      tempPass: params.tempPass,
      token: sessionStorage.getItem("jwtToken"),
      //email: this.props.email,
      hasLoginFailed: false,
      showSuccessMessage: false,
      errorMessage: "",
      isOldPassVisible: false,
      oldPassType: "password",
      isPassVisible: false,
      passType: "password",
      isNewPassVisible: false,
      newPassType: "password",
      passwordStrength: 0,
      isTsAndCsChecked: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
    this.invertCheckbox = this.invertCheckbox.bind(this);
  }

  showOldPass = () => {
    this.setState({
      isOldPassVisible: !this.state.isOldPassVisible,
      oldPassType: this.state.isOldPassVisible ? "password" : "text",
    });
};

  showPass = () => {
    this.setState({
      isPassVisible: !this.state.isPassVisible,
      passType: this.state.isPassVisible ? "password" : "text",
    });
  };

  showNewPass = () => {
    this.setState({
      isNewPassVisible: !this.state.isNewPassVisible,
      newPassType: this.state.isNewPassVisible ? "password" : "text",
    });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  loginClicked() {
    if (!this.state.oldPassword) {
      const message = "Please enter your current password!";
      this.setState({ hasLoginFailed: true });
      this.setState({ errorMessage: message });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (!this.state.password) {
      const message = "Please choose a password!";
      this.setState({ hasLoginFailed: true });
      this.setState({ errorMessage: message });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (this.state.password.length > 12) {
      const message = "The password has a max limit 12 characters!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message,
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (this.state.passwordStrength < 2) {
      const message = "Please choose a strong password!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message,
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (!this.state.passwordCheck) {
      const message = "Please choose a password confirmation!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message,
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (this.state.password !== this.state.passwordCheck) {
      const message = "Password confirmation is not same as password!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    }

    AuthenticationService.executePasswordChangeService(
      this.state.oldPassword,
      this.state.password,
      this.state.token
    )
      .then((response) => {
        if (response.data.userId != null){
          showNotification({
            type: "success",
            title: "Password Updated",
            message: "Your password has been updated successfully!",
          });
        this.setState({ showSuccessMessage: true });
        this.setState({ hasLoginFailed: false });
        sessionStorage.setItem("jwtToken", response.data.value);
        const user = jwt(response.data.value);
        sessionStorage.setItem("passwordExpiry", JSON.stringify(user.passwordExpiry));
        sessionStorage.setItem("jwtExpiry", JSON.stringify(user.jwtExpiry));
        this.props.changedPassword();
        return;
        }
        else {
          showNotification({
            type: "error",
            title: "Failed to Update Password",
            message: "Failed to Update Password",
          });
          this.setState({ errorMessage: response.data });
          this.setState({ showSuccessMessage: false });
          this.setState({ hasLoginFailed: true });
          return;
        }
      })
      .catch((error) => {
        showNotification({
          type: "error",
          title: "Failed to Update Password",
          message: "Failed to Update Password",
        });
        this.setState({ errorMessage: "Error" });
        this.setState({ showSuccessMessage: false });
        this.setState({ hasLoginFailed: true })
        return;
      });

      
  }

  invertCheckbox(){
    this.setState({isTsAndCsChecked: !this.state.isTsAndCsChecked});
  }

  componentDidMount() {
    // AuthenticationService.checkExpiredPassword(
    //   this.state.password,
    //   this.state.passwordCheck,
    //   this.state.tempPass,
    //   this.state.userId
    // )
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     this.setState({ errorMessage: error.response.data.message });
    //     this.setState({ showSuccessMessage: false });
    //     this.setState({ hasLoginFailed: true });
    //   });

    // const mainHeader = $(".header");

    // $(".field-item input").change(function () {
    //   if ($(this).val().length !== 0) {
    //     $(this).parent().addClass("filled");
    //   } else {
    //     $(this).parent().removeClass("filled");
    //   }
    // });

    // $(window).scroll(function () {
    //   if (mainHeader) {
    //     if ($(window).scrollTop() > 50) {
    //       mainHeader.addClass("fixed");
    //     } else {
    //       mainHeader.removeClass("fixed");
    //     }
    //   }
    // });
  }

  render() {
    return (
      <main className="main home-page">
        <div className="home-page__left">
          <img src={BG} alt="" />
        </div>
        <div className="home-page__right">
          <div className="home-page__forms">
            <div className="form-item form-item_reset-pass form-item_active">
              <h3>Your password has expired. Please choose a new password.</h3>
              {this.state.hasLoginFailed && (
                <span>{this.state.errorMessage}</span>
              )}
              {this.state.showSuccessMessage && (
                <div className="success">
                  <span>
                    Password creation successful!
                  </span>
                </div>
              )}
              <form className="form-item__form">
                <label className="field-item field-item_password">
                  <Form.Control
                    type={this.state.oldPassType}
                    name="oldPassword"
                    value={this.state.oldPassword}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                  <span className="field-item__title">Old password</span>
                  <span
                    className="field-item__icon field-item__icon_eye"
                    onClick={this.showOldPass}
                  >
                    {this.state.isOldPassVisible ? (
                      <ReactSVG src={closeIcon} />
                    ) : (
                      <ReactSVG src={openIcon} />
                    )}
                  </span>
                </label>
                <label className="field-item field-item_password">
                  <Form.Control
                    type={this.state.passType}
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                  <span className="field-item__title">New password</span>
                  <span
                    className="field-item__icon field-item__icon_eye"
                    onClick={this.showPass}
                  >
                    {this.state.isPassVisible ? (
                      <ReactSVG src={closeIcon} />
                    ) : (
                      <ReactSVG src={openIcon} />
                    )}
                  </span>
                </label>
                <label className="field-item field-item_password">
                  <Form.Control
                    type={this.state.newPassType}
                    name="passwordCheck"
                    value={this.state.passwordCheck}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                  <span className="field-item__title">
                    New password confirmation
                  </span>
                  <span
                    className="field-item__icon field-item__icon_eye"
                    onClick={this.showNewPass}
                  >
                    {this.state.isNewPassVisible ? (
                      <ReactSVG src={closeIcon} />
                    ) : (
                      <ReactSVG src={openIcon} />
                    )}
                  </span>
                </label>
                {this.state.password && this.state.password !== "" ? (
                  <PasswordStrengthBar
                    password={this.state.password}
                    onChangeScore={(score) => {
                      this.setState({
                        passwordStrength: score,
                      });
                    }}
                  />
                ) : null}
                <Button
                  variant="btn btn-success"
                  className="form-item__submit"
                  onClick={this.loginClicked}
                >
                  Create new password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.auth.password
});

const mapDispatchToProps = (dispatch) => ({
  changedPassword: (data) => dispatch(actions.changedPassword(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeExpiredPassword));

