import React, { useState, useEffect } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import moment from "moment";
import formatTime from "../../../components/common/FormsControls/TimePicker";
import {RenderDatePicker, RenderField, RenderSelect} from "../../../components/common/FormsControls/FormsControls";
import {commaCheck, checkNumber, maxLengthCreator, minLengthCreator, required, timeFormatValidator, timeValidator} from "../../../utils/validators/validators";

import {setForwardCheck} from "../../../redux/exchange/reducer";
import SuccessMessage from "../../../components/SuccessMessage/SuccessMessage";

const maxLength20 = maxLengthCreator(20);
const minLength3 = minLengthCreator(3);

const Form = (props) => {
    const {handleSubmit, reset, setForwardCheck} = props;
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const submit = ({amount, currency_pair, date, rate, sell_buy, executionDate, time}) => {
        let timestamp = moment(executionDate).format('YYYY-MM-DD') + " " + time;
        const spotFormData = {
            amount,
            rate,
            sell_buy: sell_buy === 'You Buy' ? 'buy' : 'sell',
            currency_pair: currency_pair.value,
            value_date: moment(date).format('YYYY-MM-DD'),
            trade_date: moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
        };

        setForwardCheck(spotFormData);
        setShowSuccessPopup(true);
        //reset('forward');

        setTimeout(() => {
            setShowSuccessPopup(false);
        }, 500);
    };

    const [time, setTime] = useState();

    const timepickerChange = (e,value) => {
        const newValue = formatTime(value, false);
        setTime(newValue);
        props.change("time", newValue);
    }

    useEffect(() => {
        const form = props.forwardForm.values;
        console.log(form);
        if (form.time != time) {
            props.change("time", time);
        }
    }, [props.forwardForm.values])

    return (
        <form className="right-sidebar__form right-sidebar-form" onSubmit={handleSubmit(submit)}>
            <div className="right-sidebar-form__item right-sidebar-form__item--flex">
                <Field name="sell_buy" value={'You Buy'} text={'You Buy'} component={RenderField} checked={'checked'} type="radio"/>
                <Field name="sell_buy" value={'You Sell'} text={'You Sell'} component={RenderField} type="radio"/>
            </div>
            <div className="right-sidebar-form__item">
                <Field validate={[required]} name="currency_pair" isSearchable={true} component={RenderSelect} placeholder={"Currency Pair"} options={props.currencyPairs}/>
            </div>
            <div className="right-sidebar-form__item">
                <Field name="amount"
                       validate={[required, minLength3, maxLength20, commaCheck, checkNumber]}
                       component={RenderField} label="Amount" type="text"/>
            </div>
            <div className="right-sidebar-form__item">
                <Field name="rate"
                       validate={[required, minLength3, maxLength20, commaCheck, checkNumber]}
                       component={RenderField} label="Execution Rate" type="text"/>
            </div>
            <div className="right-sidebar-form__item right-sidebar-form__item">
                <Field
                    validate={[required]}
                    name="executionDate"
                    label="Execution Date"
                    component={RenderDatePicker}
                    type="text"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="right-sidebar-form__item">
                <Field
                    validate={[required, timeFormatValidator, timeValidator]}
                    name="time"
                    label="Execution Time"
                    type="text"
                    component={RenderField}
                    onChange={timepickerChange}
                />
            </div>
            <div className="right-sidebar-form__item right-sidebar-form__item--full">
                <Field
                    validate={[required]}
                    name="date"
                    label="Value Date"
                    component={RenderDatePicker}
                    type="text"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="right-sidebar-form__item right-sidebar-form__item--full">
                <input className="right-sidebar-form__submit" type="submit" value="SUBMIT"/>
            </div>
            <SuccessMessage show={showSuccessPopup} />
        </form>
    )
};

const mapStateToProps = state => ({
    forwardForm: state.form.forward,
});

const ForwardForm = compose(
    reduxForm({
        form: 'forward',
        initialValues: {
            sell_buy: 'You Buy'
        }
    }),
    connect(mapStateToProps, {setForwardCheck})
)(Form);

export default ForwardForm;