import React from "react";
import Header from "./Header";
import AuthenticatedHeader from "./AuthenticatedHeader";

import './_header.scss';

const HeaderMain = ({isUserLoggedIn, history, isTCAInMaintenance}) => {
    return (
        <>
            {isUserLoggedIn ? (
                <AuthenticatedHeader mainHistory={history} isTCAInMaintenance={isTCAInMaintenance} />
            ) : (
                <Header mainHistory={history} />
            )}
        </>
    )
}

export default HeaderMain;
