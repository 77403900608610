import React, {useState, useEffect, useRef} from 'react';
import AuthenticationService from "../../../service/AuthenticationService";
// import RatioSelect from "./RatioSelect";
import TimeChoosing from "./TimeChoosing/TimeChoosing";
import CurrencySelect from "../../CurrencySelect";
import cn from "classnames";
import infoIcon from "../../../assets/img/info.svg";
import redCloseIcon from "../../../assets/img/red-close.svg";

const DashboardTop = ({timeList, setCurrencySelectOpen, currency, setSortRates}) => {
    const [timeValue, setTimeValue] = useState({value: "1 day", label: "1 day", popular: true});
    const isUserAllowedData = AuthenticationService.isUserAllowedData();

    const [isPopupOpen, setPopupOpen] = useState(false);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    setPopupOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    //const noAccessMessage = "- Please email info@newchangefx.com to access this feature"
    return (
        <div className="exchange-dashboard__top">
            <div className="exchange-dashboard__title">
                Currency FX 
            </div>
            {/* {isUserAllowedData? (<></>) : (<>
            <div className="exchange-info__icon exchange-info__graph" onClick={() => setPopupOpen(true)}>
                <img src={infoIcon} alt=""/>
            </div>
            <div className={cn('exchange-info__popup', {'hide': !isPopupOpen})}>
                <div className="exchange-info__close" onClick={() => setPopupOpen(false)}>
                    <img src={redCloseIcon} alt=""/>
                </div>
                <p>
                    To access this feature, please email <a href="info@newchangefx.com">info@newchangefx.com</a>. 
                </p>
            </div>
            </>)} */}
            <div className="exchange-dashboard__middle" >
            {/* style={isUserAllowedData ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}> */}
                {/* <RatioSelect setRatioSelectOpened={setRatioSelectOpened}
                            isRatioSelectOpen={isRatioSelectOpen}
                            ratioData={ratioData}/> */}
                <CurrencySelect setSortRates={setSortRates}
                                setCurrencySelectOpen={setCurrencySelectOpen}
                                currency={currency}
                                isMulti={false}
                                timeValue={timeValue}
                                placeholder="EURUSD"/>
                <TimeChoosing timeList={timeList} timeValue={timeValue} setTimeValue={setTimeValue} />
            </div>
        </div>
    )
};

export default DashboardTop;