import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import "./InsertRole.css";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import {Button, Form} from "react-bootstrap";

import * as actions from "../../redux/actions";

class InsertRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.addRoles.isSuccess &&
            this.props.addRoles.isSuccess !== prevProps.addRoles.isSuccess
        ) {
            this.props.history.push(`/Roles`);
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.addRolesRequest(this.state);
    }

    componentDidMount() {
    }

    render() {
        return [
            <div className="InsertRole">
                <div className="lander">
                    <div className="register-photo">
                        <div className="form-container">
                            <form onSubmit={this.handleSubmit}>
                                <h2 className="text-center">
                                    <strong>Create</strong> a new role.
                                </h2>

                                <div className="form-group">
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder={"Name"}
                                        required={true}
                                        min={3}
                                        value={this.state.name}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </div>
                                <div className="form-group">
                                    <Button className="btn btn-primary btn-block" type={"submit"}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>,
        ];
    }
}

const mapStateToProps = (state) => ({
    addRoles: state.roles.addRoles,
});

const mapDispatchToProps = (dispatch) => ({
    addRolesRequest: (data) => dispatch(actions.addRolesRequest(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InsertRole)
);
