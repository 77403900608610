import React from 'react';
import Select from "react-select";
import {connect} from "react-redux";

import {getChartData} from "../../../../redux/exchange/reducer";

const TimeSelect = ({timeList, timeValue, chooseTime, getChartData, chartCurrency}) => {

    const handleChangeTime = time => {
        chooseTime(time);
        getChartData(time.value, chartCurrency);
    };

    return (
        <Select className="time-select"
                classNamePrefix="time-select"
                isSearchable={false}
                options={timeList}
                value={timeValue}
                onChange={value => handleChangeTime(value)}
        />
    )
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {getChartData})(TimeSelect);