import React from "react";
import { ToastContainer } from "react-toastify";

import Routes from "./Routes";
import FooterContainer from "./components/Footer/FooterContainer";
import MaintenancePage from "./components/MaintenancePage/MaintenancePage";
// import AuthenticationService from "./service/AuthenticationService";
import MainHeader from "./components/Header/HeaderContainer";

import './assets/scss/base/_index.scss';
import "./App.scss";

const isTCAInMaintenance = process.env.REACT_APP_TCA_HUB_MAINTENANCE_MODE === "enabled";
const isPortalInMaintenance = process.env.REACT_APP_PORTAL_MAINTENANCE_MODE === "enabled";

const App = () => {
  // const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
  if(isPortalInMaintenance) 
    return <MaintenancePage/>
  
  return (
    <React.Fragment>
      <MainHeader isTCAInMaintenance={isTCAInMaintenance}/>
      <Routes />
      <FooterContainer />
      <ToastContainer style={{ zIndex: 999999 }} />
    </React.Fragment>
  );
}

export default App;
