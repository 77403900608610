import React from 'react';
import {connect} from "react-redux";
import {getImplementationShortfall} from "../../../redux/calculations/reducer.js";
import ImplementationShortfallDisplay from "./ImplementationShortfallDisplay.jsx";
import CalculationsForm from "../CalculationsForm.jsx";
import moment from "moment";

const ImplementationShortfallContainer = props => {
    const {currencyPairs, implementationShortfall, implementationShortfallError, getImplementationShortfall, currentCalculation} = props;

    const submit = ({currencyPair, sellBuy, originationTime, deskTime, submissionTime, fillTime, amount, dealtRate}) => {
        let baseCurrency = currencyPair.value.substring(0,3);
        let termCurrency = currencyPair.value.substring(3,6);
        let buyCurrency = sellBuy == 'b'? baseCurrency : termCurrency;
        let sellCurrency = sellBuy == 'b'? termCurrency : baseCurrency;

        let submission = "";
        if (submissionTime != undefined){
            submission = moment(submissionTime).format('YYYY-MM-DD HH:mm:ss');
        }

        let desk = "";
        if (deskTime != undefined){
            desk = moment(deskTime).format('YYYY-MM-DD HH:mm:ss');
        }

        let origination = "";
        if (originationTime != undefined){
            origination = moment(originationTime).format('YYYY-MM-DD HH:mm:ss');
        }

        const data = {
            buyCurrency: buyCurrency,
            sellCurrency: sellCurrency,
            tradeSide: sellBuy,

            originationTime: origination,
            deskTime: desk,
            submissionTime: submission,
            fillTime: moment(fillTime).format('YYYY-MM-DD HH:mm:ss'),

            amount: amount,
            dealtRate: dealtRate
        }
        getImplementationShortfall(data);
    }


    return (
    <>
            <div>
                <CalculationsForm currencyPairs={currencyPairs} currentCalculation={currentCalculation} onSubmit={submit}/>
                <ImplementationShortfallDisplay implementationShortfall={implementationShortfall} implementationShortfallError={implementationShortfallError}/>  
            </div>
    </>);
};


const mapStateToProps = state => ({
    implementationShortfall: state.calculations.implementationShortfall,
    implementationShortfallError: state.calculations.implementationShortfallRequestError,
    currentCalculation: state.calculations.currentCalculation
});

export default connect(mapStateToProps,{getImplementationShortfall})(ImplementationShortfallContainer);