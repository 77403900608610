import moment from "moment";

import { chartAPI, currencyAPI, submitForms } from "../../exhange-api/api";
import {
  getFormatDateByTime,
  getTimeInSecondsFromStringName,
} from "../../utils/helpers";

const SET_RATES = "exchange/SET_RATES";
const SET_DIRECTION = "exchange/SET_DIRECTION";
const SET_RATIO_DATA = "exchange/SET_RATION_DATA";
const SET_TIME_DATA = "exchange/SET_TIME_DATA";
const SET_CURRENCY_PAIR = "exchange/SET_CURRENCY_PAIR";
const SET_RATES_LOADER = "exchange/SET_RATES_LOADER";
const SET_CURRENCY_PAIRS = "exchange/SET_CURRENCY_PAIRS";
const SET_SORT_RATES = "exchange/SET_SORT_RATES";
const SET_CHART_DATA = "exchange/SET_CHART_DATA";
const SET_CHART_EXTRA_DATA = "exchange/SET_CHART_EXTRA_DATA";
const SET_CHART_CURRENCY = "exchange/SET_CHART_CURRENCY";
const SET_INSTANT_TRADE_CHECK = "exchange/SET_INSTANT_TRADE_CHECK";
const SET_EXECUTION_TIME = "exchange/SET_EXECUTION_TIME";
const SET_INSTANT_TRADE_CHECK_REQUEST_ERROR =
  "exchange/SET_INSTANT_TRADE_CHECK_REQUEST_ERROR";
const GET_FORWARD_POINTS_INTERPOLATION =
  "exchange/GET_FORWARD_POINTS_INTERPOLATION";
const SET_FORM_SUBMITTING = "exchange/SET_FORM_SUBMITTING";

const initialState = {
  ratesLoader: true,
  currency: [
    {
      value: "GBP",
      label: "GBP (UK Pound Sterling)",
    },
    {
      value: "AUD",
      label: "AUD (Australia Dollar)",
    },
    {
      value: "EUR",
      label: "EUR (Euro)",
    },
    {
      value: "JPY",
      label: "JPY (Japan Yen)",
    },
    {
      value: "CHF",
      label: "CHF (Switzerland Franc)",
    },
    {
      value: "USD",
      label: "USD (USA Dollar)",
    },
    {
      value: "GBP",
      label: "GBP (UK Pound Sterling)",
    },
    {
      value: "AUD",
      label: "AUD (Australia Dollar)",
    },
    {
      value: "EUR",
      label: "EUR (Euro)",
    },
    {
      value: "JPY",
      label: "JPY (Japan Yen)",
    },
    {
      value: "CHF",
      label: "CHF (Switzerland Franc)",
    },
    {
      value: "USD",
      label: "USD (USA Dollar)",
    },
  ],
  direction: [
    {
      value: "item_1",
      label: "item 1",
    },
    {
      value: "item_2",
      label: "item 2",
    },
    {
      value: "item_3",
      label: "item 3",
    },
    {
      value: "item_4",
      label: "item 4",
    },
    {
      value: "item_5",
      label: "item 5",
    },
    {
      value: "item_6",
      label: "item 6",
    },
    {
      value: "item_7",
      label: "item 7",
    },
    {
      value: "item_8",
      label: "item 8",
    },
    {
      value: "item_9",
      label: "item 9",
    },
    {
      value: "item_10",
      label: "item 10",
    },
    {
      value: "item_11",
      label: "item 11",
    },
  ],
  rates: [],
  ratioData: [
    "AUD",
    "EUR",
    "GBP",
    "JPY",
    "CHF",
    "USD",
    "AUD",
    "EUR",
    "GBP",
    "JPY",
    "CHF",
    "USD",
  ],
  // currencyPairs: [
  //     {
  //         label: "USD/ILS",
  //         value: "USD/ILS",
  //     },        {
  //         label: "USD/JPY",
  //         value: "USD/JPY",
  //     },        {
  //         label: "USD/MXN",
  //         value: "USD/MXN",
  //     },
  // ],
  currencyPairs: ["Loading..."],
  timeList: [
    {
      value: "1 min",
      label: "1 min",
      popular: true,
    },
    {
      value: "30 min",
      label: "30 min",
      popular: false,
    },
    {
      value: "1 hour",
      label: "1 hour",
      popular: true,
    },
    {
      value: "1 day",
      label: "1 day",
      popular: true,
    },
    {
      value: "1 week",
      label: "1 week",
      popular: true,
    },
    {
      value: "1 month",
      label: "1 month",
      popular: false,
    },
    {
      value: "6 months",
      label: "6 months",
      popular: false,
    },
    {
      value: "1 year",
      label: "1 year",
      popular: false,
    },
    {
      value: "10 years",
      label: "10 years",
      popular: false,
    },
  ],
  isCurrencyPairsLoaded: false,
  sortRates: [],
  chartData: [],
  chartExtraData: {
    close: 0,
    high: 0,
    low: 0,
    open: 0,
    time: "",
  },
  chartCurrency: "EURUSD",
  instantTradeCheck: null,
  instantTradeCheckRequestError: false,
  forwardPointsInterpolation: null,
  asyncFormState: {
    formName: null,
    isSubmitting: false,
  },
};

const exchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RATES:
    case SET_DIRECTION:
    case SET_RATIO_DATA:
    case SET_TIME_DATA:
    case SET_CURRENCY_PAIR:
    case SET_RATES_LOADER:
    case SET_CURRENCY_PAIRS:
    case SET_SORT_RATES:
    case SET_CHART_DATA:
    case SET_CHART_EXTRA_DATA:
    case SET_CHART_CURRENCY:
    case SET_INSTANT_TRADE_CHECK:
    case SET_INSTANT_TRADE_CHECK_REQUEST_ERROR:
    case GET_FORWARD_POINTS_INTERPOLATION:
      return { ...state, ...action.payload };
    case SET_FORM_SUBMITTING:
      return { ...state, asyncFormState: { ...action.payload } };
    default:
      return state;
  }
};

export const setRatesLoader = (ratesLoader) => ({
  type: SET_RATES_LOADER,
  payload: { ratesLoader },
});
export const setCurrencyPairsLoaded = (isCurrencyPairsLoaded) => ({
  type: SET_RATES_LOADER,
  payload: { isCurrencyPairsLoaded },
});
export const setRates = (rates) => ({ type: SET_RATES, payload: { rates } });
export const setDirection = (rates) => ({
  type: SET_DIRECTION,
  payload: { rates },
});
export const setRatioData = (ratioData) => ({
  type: SET_RATIO_DATA,
  payload: { ratioData },
});
export const setTimeData = (timeList) => ({
  type: SET_RATIO_DATA,
  payload: { timeList },
});
export const setCurrencyPair = (currencyPairs) => ({
  type: SET_CURRENCY_PAIR,
  payload: { currencyPairs },
});
export const setSortRates = (sortRates) => ({
  type: SET_SORT_RATES,
  payload: { sortRates },
});
export const setChartData = (chartData) => ({
  type: SET_CHART_DATA,
  payload: { chartData },
});
export const setChartExtraData = (chartExtraData) => ({
  type: SET_CHART_EXTRA_DATA,
  payload: { chartExtraData },
});
export const setChartCurrency = (chartCurrency) => ({
  type: SET_CHART_CURRENCY,
  payload: { chartCurrency },
});
export const setInstantTradeCheck = (instantTradeCheck) => ({
  type: SET_INSTANT_TRADE_CHECK,
  payload: { instantTradeCheck },
});
export const setExecutionTime = (instantTradeCheck) => ({
  type: SET_EXECUTION_TIME,
  payload: { instantTradeCheck },
});

export const setInstantTradeCheckRequestError = (
  instantTradeCheckRequestError
) => ({
  type: SET_INSTANT_TRADE_CHECK_REQUEST_ERROR,
  payload: { instantTradeCheckRequestError },
});
export const setForwardPointsInterpolation = (forwardPointsInterpolation) => ({
  type: GET_FORWARD_POINTS_INTERPOLATION,
  payload: { forwardPointsInterpolation },
});
export const setFormSubmitting = (payload) => ({
  type: SET_FORM_SUBMITTING,
  payload,
});

export const getRates = (currencyPairs, basicAuth) => async (dispatch) => {
  const data = await currencyAPI.getRates(currencyPairs, basicAuth);
  dispatch(setRates(data[0] || []));
  dispatch(setRatesLoader(false));
};

export const getCurrencyPairs = (basicAuth) => async (dispatch) => {
  const data = await currencyAPI.getCurrencyPairs(basicAuth);
  dispatch(setCurrencyPair(data.currencyPairs || []));
  dispatch(setCurrencyPairsLoaded(true));
};

export const getChartData = (time, currencyPairs) => async (dispatch) => {
  dispatch(setChartData([]));
  const data = await chartAPI.fetchChartData(
    getTimeInSecondsFromStringName(time),
    currencyPairs
  );
  const chartData = (data[0] || []).map((item) => ({
    date: moment(item.time).format(getFormatDateByTime(time)),
    Price: item.close,
  }));
  dispatch(setChartData(chartData));
  dispatch(setChartExtraData(data[1] || []));
};

export const setSpotCheck = (data) => async (dispatch) => {
  try {
    const res = await submitForms.setSpotCheck(data);
    if (res.code === 404) {
      console.error(res);
    } else {
      dispatch(setInstantTradeCheck(res.data));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setForwardCheck = (data) => async (dispatch) => {
  try {
    const res = await submitForms.setForwardCheck(data);
    if (res.code === 404) {
      console.error(res);
    } else {
      dispatch(setInstantTradeCheck(res.data));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setSwapCheck = (data) => async (dispatch) => {
  try {
    const res = await submitForms.setSwapCheck(data);
    if (res.code === 404) {
      console.error(res);
    } else {
      dispatch(setInstantTradeCheck(res.data));
    }
  } catch (e) {
    console.error(e);
  }
};

export const resetInstantTradeCheck = () => async (dispatch) => {
  const initialValues = {
    ncfx_benchmark: "0.00",
    usd_cost: "0.00",
    usd_bp_cost: "0.00",
  };

  dispatch(setInstantTradeCheck(initialValues));
};

export const getForwardPointsInterpolation = (data) => async (dispatch) => {
  try {
    dispatch(setFormSubmitting({ isSubmitting: true, formName: "forward" }));
    const res = await submitForms.getForwardPointsInterpolation(data);
    dispatch(setFormSubmitting({ isSubmitting: false, formName: "forward" }));
    if (res.code === 404) {
      console.error(res);
    } else {
      dispatch(setForwardPointsInterpolation(res.data));
    }
  } catch (e) {
    console.error(e);
  }
};

export default exchangeReducer;
