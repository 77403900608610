import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  dubaigold: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
};

/* fetch dvnc*/
const fetchDubaiGoldRequest = (state, action) =>
  update(state, {
    dubaigold: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchDubaiGoldSuccess = (state, action) =>
  update(state, {
    dubaigold: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchDubaiGoldError = (state, action) =>
  update(state, {
    dubaigold: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

export default handleActions(
  {
    [constants.FETCH_DUBAI_GOLD_REQUEST]: fetchDubaiGoldRequest,
    [constants.FETCH_DUBAI_GOLD_SUCCESS]: fetchDubaiGoldSuccess,
    [constants.FETCH_DUBAI_GOLD_ERROR]: fetchDubaiGoldError,
  },
  initialState
);
