import {ExportToCsv} from "export-to-csv";
import {format} from "date-fns";

const exportCsv = (series, financialInstrument, dataView, form) => {
    
    if (series && series.length > 0) {
        var data = [];
        series.forEach((t) => {
            data.push({
                Date: format(new Date(t.time), "yyyy-MM-dd"),
                Time: format(new Date(t.time), "HH:mm:ss:SSS"),
                Rate: t.rate,
            });
        });

        let filename = filenameGenerator(financialInstrument, dataView, form);
        const options = {
            filename: filename,
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
        
    }
};

export const exportOhlcCsv = (series, financialInstrument, dataView, form) => {  
    if (series && series.length > 0) {
        var data = [];
        series.forEach((t) => {
            data.push({
                Date: format(new Date(t.time), "yyyy-MM-dd"),
                Time: format(new Date(t.time), "HH:mm:ss:SSS"),
                Open: t.open,
                High: t.high,
                Low: t.low,
                Close: t.close
            });
        });

        let filename = filenameGenerator(financialInstrument, dataView, form);
        const options = {
            filename: filename,
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
};

const filenameGenerator = (financialInstrument, dataView, form) => {
    let filename = "NCFX"

        let instrument = financialInstrument == 0? "Spot" : "Forward";
        filename = filename.concat("_" + instrument);

        let view = null;
        switch (dataView) {
            case 0:
                view = "LastRate";
                break;
            case 1:
                view = "TimeSeries";
                break;
            case 2:
                view = "TWAP";
                break;
            case 3:
                view = "OHLC";
                break;
        }
        filename = filename.concat("_" + view);

        let currencyPair = form.currencyPair;
        filename = filename.concat("_" + currencyPair);

        if (form.tenor){
            filename = filename.concat("_" + form.tenor);
        }

        let startDate = format(new Date(form.startDate), "do-MMM-yyyy");
        filename = filename.concat("_Start" + startDate);

        if (form.endDate){
            let endDate = format(new Date(form.endDate), "do-MMM-yyyy");
            filename = filename.concat("_End" + endDate);
        }

        return filename;
}

export default exportCsv;