import React from 'react';
import cn from "classnames";

import {checkObj} from "../../../../utils/helpers";
import {connect} from "react-redux";
import {getChartData} from "../../../../redux/exchange/reducer";

const TimePopular = ({timeList, timeValue, chooseTime, getChartData, chartCurrency}) => {

    const handleTime = time => {
        chooseTime(time);
        getChartData(time.value, chartCurrency);
    };

    return (
        <ul className="exchange-time__list exchange-time-list">
            {timeList.map((item, index) =>
                item.popular ? <li onClick={() => handleTime(item)} key={index}
                                   className={cn('exchange-time-list__item', {'active': checkObj(item, timeValue)})}
                >{item.label}</li> : null
            )}
        </ul>
    )
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {getChartData})(TimePopular);