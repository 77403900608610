import React, {Component} from "react";
import cn from 'classnames';

import logo from "../../assets/img/logo.svg";
import logoDark from "../../assets/img/logo_dark.svg";
import {Link} from "react-router-dom";

import './_header.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileMenu: false,
            isFixedHeader: false
        };
    }

    _toggleMobileMenu = () => {
        this.setState({
            showMobileMenu: !this.state.showMobileMenu,
        });
    };

    _redirectMobileMenu = (e, path) => {
        e.preventDefault();
        this.props.mainHistory.push(path);
        this._toggleMobileMenu();
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showMobileMenu !== this.state.showMobileMenu) {
            if (this.state.showMobileMenu) {
                document.documentElement.classList.add('overflow');
                document.body.classList.add('overflow');
            } else {
                document.documentElement.classList.remove('overflow');
                document.body.classList.remove('overflow');
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    // handleScroll() {
    //     if (window.pageYOffset > 150) {
    //         this.setState({
    //             isFixedHeader: true
    //         });
    //     } else {
    //         this.setState({
    //             isFixedHeader: false
    //         });
    //     }
    // }

    handleScroll() {
        this.setState({
            isFixedHeader: true
        });
    }
    

    _renderMobileMenu = () => {
        return (
            <div className={cn('main-mobile-menu', {'show': this.state.showMobileMenu})}>
                <div className="account-mobile-menu__container">
                    {/* <div className="main-mobile-menu__button">
                        <Link
                            style={window.location.pathname == "/Register"? {}:{color: 'pink', backgroundColor: 'black'}}
                            className="active"
                            to="/Register"
                            onClick={(e) => this._redirectMobileMenu(e, "/Register")}>
                            Sign Up
                        </Link>
                        <Link
                            className=""
                            to="/login"
                            onClick={(e) => this._redirectMobileMenu(e, "/login")}>
                            Sign In
                        </Link>
                    </div> */}
                    <Link
                        className="main-mobile-menu__back"
                        to="/"
                        onClick={(e) => this._redirectMobileMenu(e, "/")}>
                        Back to homepage
                    </Link>
                    <ul className="main-mobile-menu__list">
                        <li>
                            <Link to="/">About Us</Link>
                        </li>
                        <li>
                            <Link to="/">Data</Link>
                        </li>
                        <li>
                            <Link to="/">TCA</Link>
                        </li>
                        <li>
                            <Link to="/">Regulatory Library</Link>
                        </li>
                        <li>
                            <Link to="/">Insights</Link>
                        </li>
                        <li>
                            <Link to="/">Contact Us</Link>
                        </li>
                    </ul>
                    <div className="main-mobile-menu__contact-info">
                        <h3>Information</h3>
                        <div className="main-mobile-menu__contact-info-item">
                            <span>Email:</span>
                            <a href="mailto:info@newchangefx.com">info@newchangefx.com</a>
                        </div>
                        <div className="main-mobile-menu__contact-info-item">
                            <span>Telephone:</span>
                            <a href="tel:+4402074171820">+44 (0)20 7417 1820</a>
                        </div>
                    </div>
                    <ul className="main-mobile-menu__socials">
                        <li>
                            <a href="/">
                                <img src="./../../assets/img/twitter.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <img src="./../../assets/img/linkedin.svg" alt=""/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    render() {
        return (
            <header className={cn('header', {'fixed': this.state.isFixedHeader})}>
                <div className="container">
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.mainHistory.push("/");
                        }}
                    >
                        <img
                            className="header__logo header__logo_desktop"
                            src={logo}
                            alt=""
                        />
                        <img
                            className="header__logo header__logo_mobile"
                            src={logoDark}
                            alt=""
                        />
                    </Link>
                    <div className="header__right">
                        {/*<Link*/}
                        {/*    className="header__back"*/}
                        {/*    to="/"*/}
                        {/*    onClick={(e) => {*/}
                        {/*        e.preventDefault();*/}
                        {/*        this.props.mainHistory.push("/");*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    Back to homepage*/}
                        {/*</Link>*/}
                        {/* <div className="header__buttons">
                            <Link
                                className="active"
                                to="/Register"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.mainHistory.push("/Register");
                                }}
                            >
                                SIGN UP
                            </Link>
                            <Link
                                className=""
                                to="/login"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.mainHistory.push("/login");
                                }}
                            >
                                SIGN IN
                            </Link>
                        </div> */}
                        <div className={cn('header__burger', {'active': this.state.showMobileMenu})}
                             onClick={() => this._toggleMobileMenu()}
                        >
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                </div>
                {this._renderMobileMenu()}
            </header>
        );
    }
}

export default Header;
