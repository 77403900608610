import axios from "axios";
import header from "basic-auth-header";
import { config } from "../constants";
import jwt from "jwt-decode";

const API_URL = config.url.API_URL;

export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const JWT_TOKEN = "jwtToken";
export const USER_ROLES_SESSION = "userRoles";

// const logoutUrl = config.url.API_URL + "/api/v1/logout";

class AuthenticationService {


  executeJwtAuthenticationService(username, password) {
    return axios.post(`${API_URL}/user/authenticate`, {
    }, {headers: {
      'Authorization': header(username, password),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Accept': "application/json",
      "Cache-Control": "no-cache",
      mode: "no-cors"
    }});
  }

  executePasswordChangeService(oldPassword, password, token) {
    return axios.post(`${API_URL}/user/changePassword`, 
    {oldPassword, password},
    {headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': "Bearer " + token,
      'AuthorizationBackup': "Bearer " + token,
      'Content-Type': 'application/json',
      'Accept': "application/json",
      'Cache-Control': "no-cache",
      mode: "no-cors"
    }});
  }

  executePasswordChangeServiceFromReset(password, token) {
    return axios.post(`${API_URL}/user/changePasswordFromReset`, 
    {password},
    {headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': "Bearer " + token,
      'AuthorizationBackup': "Bearer " + token,
      'Content-Type': 'application/json',
      'Accept': "application/json",
      'Cache-Control': "no-cache",
      mode: "no-cors"
    }});
  }

  executeForgotPasswordService(email, jwt) {
    return axios.post(`${API_URL}/user/forgotPassword`, {email},
    {headers: {
      'Authorization': "Bearer " + sessionStorage.getItem("jwtToken"),
      'AuthorizationBackup': "Bearer " + sessionStorage.getItem("jwtToken"),
    }});
  }

  checkExpiredPassword(password, passwordCheck, oldPassword, userId) {
    return axios.post(`${API_URL}/user/checkExpiredPassword`, {password, passwordCheck, oldPassword, userId});
  }

  registerSuccessfulLoginForJwt(email, token) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, email);
    sessionStorage.setItem(JWT_TOKEN, token);
    const user = jwt(token);
    sessionStorage.setItem("passwordExpiry", JSON.stringify(user.passwordExpiry))
    sessionStorage.setItem("jwtExpiry", JSON.stringify(user.jwtExpiry));
    sessionStorage.setItem(USER_ROLES_SESSION, JSON.stringify(user.roles));
    sessionStorage.setItem("name", JSON.stringify(user.name));
    this.setupAxiosInterceptors(this.createJWTToken(token));
  }

  createJWTToken(token) {
    return "Bearer " + token;
  }

  logout() {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    sessionStorage.removeItem(JWT_TOKEN);
    sessionStorage.removeItem(USER_ROLES_SESSION);
    sessionStorage.removeItem("passwordExpiry");
    sessionStorage.removeItem('basicAuth');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('jwtExpiry');
    sessionStorage.removeItem('tAndC');
    sessionStorage.removeItem('loggedIn');
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (user === null) return false;
    return true;
  }

  isUserAdmin() {
    let userRoles = sessionStorage.getItem(USER_ROLES_SESSION);
    if (userRoles === null) {
      return false;
    } else {
      let admin = userRoles.search("Admin");
      if (admin === -1) return false;
      return true;
    }
  }

  isUserAllowedTCAHub() {
    let userRoles = sessionStorage.getItem(USER_ROLES_SESSION);
    if (userRoles === null) {
      return false;
    } else {
      let admin = userRoles.search("TCAHub");
      if (admin === -1) return false;
      return true;
    }
  }

  isUserAllowedData() {
    let userRoles = sessionStorage.getItem(USER_ROLES_SESSION);
    if (userRoles === null) {
      return false;
    } else {
      let data = userRoles.search("Data");
      if (data === -1) return false;
      return true;
    }
  }

  getLoggedInUserName() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (user === null) return "";
    return user;
  }

  setupAxiosInterceptors(token) {}

  /* new functions */
  getLoggedUser() {
    let token = sessionStorage.getItem(JWT_TOKEN);
    if (token) {
      let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
      let userRoles = sessionStorage.getItem(USER_ROLES_SESSION);
      return {
        token: token,
        user: user,
        userRoles: userRoles,
      };
    }
    return null;
  }

  getUserToken() {
    return sessionStorage.getItem(JWT_TOKEN) || "";
  }
}

export default new AuthenticationService();
