import React, {useEffect} from 'react';
import {connect} from "react-redux";

import Chart from "./Chart";
import {selectChartData, selectChartExtraData} from "../../../redux/exchange/exchange-selector";
import {getChartData} from "../../../redux/exchange/reducer";

const ChartContainer = props => {
    const { chartData, getChartData } = props;

    useEffect(() => {
        if(!chartData.length) {
            getChartData('1 day');
        }
        // eslint-disable-next-line
    }, []);

    return <Chart {...props} data={chartData} />;
};

const mapStateToProps = state => ({
    chartData: selectChartData(state),
    chartExtraData: selectChartExtraData(state)
});

export default connect(mapStateToProps, {getChartData})(ChartContainer);