import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {getOhlc} from "../../../redux/data/spot/reducer.js";
import OhlcDisplay from "./OhlcDisplay.jsx";
import DataForm from "../DataForm.jsx";
import moment from "moment";
import {exportOhlcCsv} from "../../ExportCsv";


const OhlcContainer = props => {
    const {financialInstrument, dataView} = props;

    const {ohlc, ohlcRequestError, ohlcLoading, getOhlc} = props;

    const [exportToCsv, setExportToCsv] = useState(false);

    const [isCsvDownloadFinished, setIsCsvDownloadFinished] = useState(false);

    const [form, setForm] = useState({})

    const submit = (request) => {
        setIsCsvDownloadFinished(false);
        const data = {
            currencyPair: request.currencyPair.value,
            startDate: moment(request.startDate).format('YYYY-MM-DD') + " " + request.startTime,
            endDate: moment(request.endDate).format('YYYY-MM-DD') + " " + request.endTime,
            interval: request.interval.value
        }
        setForm(data);
        getOhlc(data);
    }

    useEffect(() => {
        if (exportToCsv){
            setIsCsvDownloadFinished(false);
            exportOhlcCsv(ohlc.series, financialInstrument, dataView, form);
            setExportToCsv(false);
            setIsCsvDownloadFinished(true);
        }
    },[exportToCsv]);

    return (
    <>
            <div>
                <DataForm {...props} onSubmit={submit}/>
                <OhlcDisplay ohlc={ohlc} setExportToCsv={setExportToCsv} ohlcLoading={ohlcLoading}
                 ohlcRequestError={ohlcRequestError} isCsvDownloadFinished={isCsvDownloadFinished}/>  
            </div>
    </>)
};


const mapStateToProps = state => ({
    ohlc: state.spot.ohlc,
    ohlcRequestError: state.spot.ohlcRequestError,
    ohlcLoading: state.spot.ohlcLoading,

    financialInstrument: state.data.financialInstrument,
    dataView: state.data.dataView
    
});

export default connect(mapStateToProps,{getOhlc})(OhlcContainer);