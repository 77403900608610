import React from "react";
import {Field, reduxForm} from "redux-form";
import {required, commaCheck, checkNumber} from "../../utils/validators/validators";
import {RenderField, RenderSelect, RenderDatePicker} from "../../components/common/FormsControls/FormsControls";

import "../../assets/css/main.css";
import "../../assets/css/reset.css";


const form = props => {

    const {handleSubmit, currencyPairs, currentCalculation} = props;

    return (
            <div className="calculations__form">
                
                <form className="right-sidebar-form forward-points-form" onSubmit={handleSubmit}>
                    <div className="right-sidebar-form__item right-sidebar-form__item--flex">
                        <Field name="sellBuy" value={'b'} text={'Buy'} component={RenderField} checked={'checked'} type="radio"/>
                        <Field name="sellBuy" value={'s'} text={'Sell'} component={RenderField} type="radio"/>
                    </div>
                    <div className="right-sidebar-form__item" style={{zIndex: 5}}>
                        <Field validate={[required]} name="currencyPair" isSearchable={true} component={RenderSelect} placeholder={"Currency Pair"} options={currencyPairs}/>
                    </div>
                    {currentCalculation == 3 && (
                    <>
                    <div className="right-sidebar-form__item">
                        <Field
                        name="originationTime"
                        label="Origination Time (Opt.)"
                        component={RenderDatePicker}
                        type="text"
                        className="form-control"
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        />
                    </div>
                    <div className="right-sidebar-form__item">
                        <Field
                        name="deskTime"
                        label="Desk Time (Opt.)"
                        component={RenderDatePicker}
                        type="text"
                        className="form-control"
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        />
                    </div>
                    </>)}
                    {(currentCalculation == 2 || currentCalculation == 3) && (
                    <div className="right-sidebar-form__item">
                        <Field
                        name="submissionTime"
                        label="Submission Time (Opt.)"
                        component={RenderDatePicker}
                        type="text"
                        className="form-control"
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="dd/MM/yyyy HH:mm:ss"/>
                        </div>)
                    }
                    <div className="right-sidebar-form__item" style={currentCalculation == 0?{width: "100%"}:{}}>
                        <Field
                        validate={[required]}
                        name="fillTime"
                        label="Fill Time"
                        component={RenderDatePicker}
                        type="text"
                        className="form-control"
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        />
                    </div>
                    {currentCalculation == 1 && (<div className="right-sidebar-form__item">
                        <Field validate={[required]} name="timeUnit" isSearchable={true} component={RenderSelect} placeholder={"Time Unit"} options={["Seconds", "Minutes"]}/>
                    </div>)}
                    <div className="right-sidebar-form__item">
                    <Field name="amount"
                            validate={[required, checkNumber]}
                            component={RenderField} label="Amount" type="text"/>
                    </div>
                    <div className="right-sidebar-form__item">
                    <Field name="dealtRate"
                            validate={[required, checkNumber]}
                            component={RenderField} label="Dealt Rate" type="text"/>
                    </div>
                    <div className="right-sidebar-form__item--full" style={{paddingTop: "15px"}}>
                        <input className="right-sidebar-form__submit" type="submit" value="Submit"/>
                    </div>
                </form>
            </div>
    )
};

export default reduxForm({
        form: 'Calculations',

        initialValues: {
            sellBuy: 'b',
            timeUnit: 0,
            tenor: 4
        }
    })(form);