import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  optionFixings: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  sirens: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
};

/* fetch optionFixings*/
const fetchOptionFixingsRequest = (state, action) =>
  update(state, {
    optionFixings: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchOptionFixingsSuccess = (state, action) =>
  update(state, {
    optionFixings: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchOptionFixingsError = (state, action) =>
  update(state, {
    optionFixings: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

/* fetch sirens*/
const fetchSirensRequest = (state, action) =>
  update(state, {
    sirens: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const fetchSirensSuccess = (state, action) =>
  update(state, {
    sirens: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const fetchSirensError = (state, action) =>
  update(state, {
    sirens: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });

export default handleActions(
  {
    [constants.FETCH_OPTION_FIXINGS_REQUEST]: fetchOptionFixingsRequest,
    [constants.FETCH_OPTION_FIXINGS_SUCCESS]: fetchOptionFixingsSuccess,
    [constants.FETCH_OPTION_FIXINGS_ERROR]: fetchOptionFixingsError,

    [constants.FETCH_SIRENS_REQUEST]: fetchSirensRequest,
    [constants.FETCH_SIRENS_SUCCESS]: fetchSirensSuccess,
    [constants.FETCH_SIRENS_ERROR]: fetchSirensError,
  },
  initialState
);
