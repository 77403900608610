import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Link, withRouter} from "react-router-dom";
import * as actions from "../redux/actions";
import {checkEmail, checkTel, maxLengthCreator, minLengthCreator, required} from "../utils/validators/validators";
import {RenderField} from "../components/common/FormsControls/FormsControls";
import LeftSide from "../components/LeftSide";
import Terms from "../TextPage/TextPage";

import "../assets/css/reset.css";
import "../assets/css/main.css";

const maxLength20 = maxLengthCreator(20);
const minLength3 = minLengthCreator(3);

// const industryOptions = [
//   {value: 1, label: 'Asset Manager'},
//   {value: 2, label: 'Bank'},
//   {value: 3, label: 'Broker'},
//   {value: 4, label: 'Central Bank'},
//   {value: 5, label: 'Compliance/Regulatory Firm'},
//   {value: 6, label: 'Consultant/Advisory'},
//   {value: 7, label: 'Corporate'},
//   {value: 8, label: 'Data Platform'},
//   {value: 9, label: 'Endowment'},
//   {value: 10, label: 'Family Office'},
//   {value: 11, label: 'Hedge Fund/CTA'},
//   {value: 12, label: 'Insurance Company'},
//   {value: 13, label: 'Pension Fund'},
//   {value: 14, label: 'Press'},
//   {value: 15, label: 'Private Bank'},
//   {value: 16, label: 'Regulator'},
//   {value: 17, label: 'Sovereign Wealth Fund'},
//   {value: 18, label: 'Trade Body'},
//   {value: 19, label: 'Wealth Manager'},
//   {value: 20, label: 'Vendor/Supplier'},
//   {value: 21, label: 'Other'}
// ];

const RegisterForm = (props) => {

    const {handleSubmit, reset, login} = props;

    const submit = (values) => {
        props.registerRequest({
            company: values.company,
            email: values.email,
            fullName: values.fullName,
            jobTitle: values.jobTitle,
            tel: values.tel,
            username: values.username
        });
        
        preventDoubleTap();
        //reset('reg');
    };

    const successfulRegistration = props.login.isSuccess;

    const [openedTermsAndConditions, setOpenedTermsAndConditions] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

     const preventDoubleTap = () => {
        setSubmitDisabled(true);
        
        setTimeout(()=>{
           setSubmitDisabled(false);
        }, 5000)
      }

    const testClick = (e, value) => {
        setOpenedTermsAndConditions(value);
    } 

    useEffect(()=>{
        console.log(openedTermsAndConditions);
    },[openedTermsAndConditions])

    return (
        <>
        { openedTermsAndConditions? 
            (<><Terms inPageText={true} setOpenedTermsAndConditions={setOpenedTermsAndConditions}/></>):(
        <main className="main home-page">
            <LeftSide/>
            <div className="home-page__right">
                <div className="home-page__forms">
                    <div className="form-item form-item_registration form-item_active">
                            {successfulRegistration ? (
                                <><h3>REGISTRATION SUCCESSFUL</h3></>
                            ) : (
                                <><h3>REGISTRATION</h3></>
                            )}
                        {/* <span style={{color: "red"}} className="form-item__server-message form-item__server-message--error">{login.message}</span> */}
                        
                        {successfulRegistration ? (
                            <>
                                <h5>You have successfully signed up to the NewChangeFX Client Portal.</h5>
                                <h5>Please check your emails for steps to login.</h5>
                            </>                
                            ) : (
                            <>
                                <form className="form-item__form" onSubmit={handleSubmit(submit)}>
                                    <Field name="fullName"
                                        validate={[required, minLength3, maxLength20]}
                                        component={RenderField} label="Full Name" type="text"/>
                                    <Field name="username"
                                        validate={[required, minLength3, maxLength20]}
                                        component={RenderField} label="Username" type="text"/>
                                    <Field name="email"
                                        validate={[required, checkEmail]}
                                        component={RenderField} label="Email Address" type="text"/>
                                    <Field name="tel"
                                        validate={[required, minLength3, checkTel]}
                                        component={RenderField} label="Telephone" type="tel"/>
                                    <Field name="company"
                                        validate={[required, minLength3, maxLength20]}
                                        component={RenderField} label="Company Name / Private Individual" type="text"/>
                                    {/* <Field name="jobTitle"
                                        validate={[required, minLength3, maxLength20]}
                                        component={RenderField} label="Job Title" type="text"/> */}
                                    {/*<Field validate={[required]} name="industry" component={RenderSelect} options={industryOptions}/>*/}

                                    <Field name="remember" component={RenderField} type="checkbox"
                                        validate={[required]}
                                        // text={'I agree to the NCFX Client Portal Terms and Conditions'}
                                        text={<a className="form-item__link" style={{marginLeft: '0px'}} onClick={() => {setOpenedTermsAndConditions(true)}}>I agree to the NCFX Client Portal Terms and Conditions</a>}
                                        labelAsLink
                                        // linkPath={testClick}
                                        // linkText={'TextPage of Use'}
                                        />
                                    <input className="form-item__submit" type="submit" disabled={submitDisabled} value="Register me"/>
                                    <div style={{justifyContent: 'left'}} className="form-item__bottom">
                                        Have an account?
                                        <Link className="form-item__link" to="/">
                                            Sign in here
                                        </Link>
                                    </div>
                                </form>
                            </>)}
                        
                    </div>
                </div>
            </div>
        </main>
        )}</>
    )
};

const Register = reduxForm({
    form: 'reg'
})(RegisterForm);

const mapStateToProps = (state) => ({
    login: state.auth.register,
});

const mapDispatchToProps = (dispatch) => ({
    registerRequest: (data) => dispatch(actions.registerRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
