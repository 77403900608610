import {dataAPI} from "../../../exhange-api/api";
import moment from 'moment';

const SET_TIME_SERIES = 'spotData/SET_TIME_SERIES';
const SET_TIME_SERIES_REQUEST_ERROR = 'spotData/SET_TIME_SERIES_REQUEST_ERROR';
const SET_TIME_SERIES_LOADING = 'spotData/SET_TIME_SERIES_LOADING'

const SET_LAST_REQUEST_BODY = 'spotData/SET_LAST_REQUEST_BODY';
const SET_IS_FULL_UP_TO_DATE = 'spotData/SET_IS_FULL_UP_TO_DATE';

const SET_TIME_SERIES_FULL = 'spotData/SET_TIME_SERIES_FULL';
const SET_TIME_SERIES_FULL_REQUEST_SENT = 'spotData/SET_TIME_SERIES_FULL_REQUEST_SENT';
const SET_TIME_SERIES_FULL_REQUEST_ERROR = 'spotData/SET_TIME_SERIES_FULL_REQUEST_ERROR';

const SET_LAST_RATE = 'spotData/SET_LAST_RATE';
const SET_LAST_RATE_REQUEST_ERROR = 'spotData/SET_LAST_RATE_REQUEST_ERROR';

const SET_TWAP = 'spotData/SET_TWAP';
const SET_TWAP_REQUEST_ERROR = 'spotData/SET_TWAP_REQUEST_ERROR';
const SET_TWAP_LOADING = 'spotData/SET_TWAP_LOADING';

const SET_OHLC = 'spotData/SET_OHLC';
const SET_OHLC_REQUEST_ERROR = 'spotData/SET_OHLC_REQUEST_ERROR';
const SET_OHLC_LOADING = 'spotData/SET_OHLC_LOADING';

const initialState = {
    timeSeries:{
        series: [],
        maxRate: 0,
        minRate: 0,
        numberOfSamples: 0,
        isSampled: false
    },
    timeSeriesRequestError: false,
    timeSeriesLoading: false,
    lastRequestBody:{
        currencyPair: null,
        startDate: null,
        endDate: null
    },
    isFullUpToDate: false,
    timeSeriesFull:{
        series: []
    },
    timeSeriesFullRequestSent: false,
    timeSeriesFullRequestError: false,
    lastRate: [],
    lastRateRequestError: false,
    twap:{
        series: [],
        maxRate: 0,
        minRate: 0,
        numberOfSamples: 0,
        isSampled: false
    },
    twapRequestError: false,
    twapLoading: false,
    ohlc:{
        series: [],
        open: [],
        high: [],
        low: [],
        close: [],
        maxOpen: 0,
        minOpen: 0,
        maxHigh: 0,
        minHigh: 0,
        maxLow: 0,
        minLow: 0,
        maxClose: 0,
        minClose: 0,
        numberOfSamples: 0,
        isSampled: false
    },
    ohlcRequestError: false,
    ohlcLoading: false,
};

const spotDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIME_SERIES:
        case SET_TIME_SERIES_REQUEST_ERROR:
        case SET_TIME_SERIES_LOADING:
        case SET_LAST_REQUEST_BODY:
        case SET_IS_FULL_UP_TO_DATE:
        case SET_TIME_SERIES_FULL:
        case SET_TIME_SERIES_FULL_REQUEST_SENT:
        case SET_TIME_SERIES_FULL_REQUEST_ERROR:
        case SET_LAST_RATE:
        case SET_LAST_RATE_REQUEST_ERROR:
        case SET_TWAP:
        case SET_TWAP_REQUEST_ERROR:
        case SET_TWAP_LOADING:
        case SET_OHLC:
        case SET_OHLC_REQUEST_ERROR:
        case SET_OHLC_LOADING:
            case SET_OHLC:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const setTimeSeries = timeSeries => ({type: SET_TIME_SERIES, payload: {timeSeries}});
export const setTimeSeriesRequestError = timeSeriesRequestError => ({type: SET_TIME_SERIES_REQUEST_ERROR, payload: {timeSeriesRequestError}});
export const setTimeSeriesLoading = timeSeriesLoading => ({type: SET_TIME_SERIES_LOADING, payload: {timeSeriesLoading}});
export const setLastRequestBody = lastRequestBody => ({type: SET_LAST_REQUEST_BODY, payload: {lastRequestBody}});
export const setIsFullUpToDate = isFullUpToDate => ({type: SET_IS_FULL_UP_TO_DATE, payload: {isFullUpToDate}});
export const setTimeSeriesFull = timeSeriesFull => ({type: SET_TIME_SERIES_FULL, payload: {timeSeriesFull}});
export const setTimeSeriesFullRequestSent = timeSeriesFullRequestSent => ({type: SET_TIME_SERIES_FULL_REQUEST_SENT, payload: {timeSeriesFullRequestSent}});
export const setTimeSeriesFullRequestError = timeSeriesFullRequestError => ({type: SET_TIME_SERIES_FULL_REQUEST_ERROR, payload: {timeSeriesFullRequestError}});

export const setLastRate = lastRate => ({type: SET_LAST_RATE, payload: {lastRate}});
export const setLastRateRequestError = lastRateRequestError => ({type: SET_LAST_RATE_REQUEST_ERROR, payload: {lastRateRequestError}});

export const setTwap = twap => ({type: SET_TWAP, payload: {twap}});
export const setTwapRequestError = twapRequestError => ({type: SET_TWAP_REQUEST_ERROR, payload: {twapRequestError}});
export const setTwapLoading = twapLoading => ({type: SET_TWAP_LOADING, payload: {twapLoading}});

export const setOhlc = ohlc => ({type: SET_OHLC, payload: {ohlc}});
export const setOhlcRequestError = ohlcRequestError => ({type: SET_OHLC_REQUEST_ERROR, payload: {ohlcRequestError}});
export const setOhlcLoading = ohlcLoading => ({type: SET_OHLC_LOADING, payload: {ohlcLoading}});

export const getTimeSeries = data => async (dispatch) => {
try {

    dispatch(setTimeSeriesLoading(true));
    dispatch(setTimeSeriesRequestError(false));
    const result = await dataAPI.getTimeSeries(data, "spot");
    if(result.code === 404) {
        console.error(result);
    } else {
        if (result.data.rates){
            const timeSeriesObject = readGraphData(result);
            dispatch(setTimeSeriesLoading(false));
            dispatch(setLastRequestBody(data));
            dispatch(setTimeSeries(timeSeriesObject));
            dispatch(setIsFullUpToDate(false));
        }
        else if (result.data.error) {
            dispatch(setTimeSeriesLoading(false));
            dispatch(setTimeSeriesRequestError(result.data.error))
            dispatch(setTimeSeries({timeSeries: initialState.timeSeries}));
        }
        else {
            console.log("Unforseen timeSeries error.")
        }
    }
    
    } catch (e) {
        console.error(e);
        dispatch(setTimeSeriesLoading(false));
        dispatch(setTimeSeriesRequestError(e));
        dispatch(setTimeSeries({timeSeries: initialState.timeSeries}));
    }
};

export const getTimeSeriesFull = data => async (dispatch) => {
    try {
        dispatch(setTimeSeriesFullRequestSent(true));
        dispatch(setTimeSeriesFullRequestError(false));
        const result = await dataAPI.getTimeSeriesFull(data, "spot");
        if(result.code === 404) {
            console.error(result);
        } else {
            if (result.data.rates){
                const timeSeriesObject = readGraphData(result);
                
                dispatch(setTimeSeriesFull(timeSeriesObject));
                dispatch(setIsFullUpToDate(true));
                dispatch(setTimeSeriesFullRequestSent(false));
            }
            else if (result.data.error) {
                dispatch(setTimeSeriesFullRequestError(result.data.error));
                dispatch(setTimeSeriesFull({timeSeries: initialState.timeSeries}));
                dispatch(setTimeSeriesFullRequestSent(false));
            }
            else {
                console.log("Unforseen timeSeries error.")
            }
        }
        
    } catch (e) {
        console.error(e);
        dispatch(setTimeSeriesFullRequestError(e));
        dispatch(setTimeSeriesFull({timeSeries: initialState.timeSeries}));
        dispatch(setTimeSeriesFullRequestSent(false));
    }
};

export const getLastRate = data => async (dispatch) => {
    try {
        dispatch(setLastRateRequestError(false));
        const result = await dataAPI.getLastRate(data, "spot");
        if(result.code === 404) {
            console.error(result);
        } else {
            if (result.data.time){
                let lastRateData = [];
                let datapoint = {
                        rate: result.data.rate,
                        time: moment(result.data.time).format('yyyy-MM-DD HH:mm:ss.SSS')
                    };
                lastRateData.push(datapoint);
                dispatch(setLastRate(lastRateData));
            }
            else if (result.data.error) {
                dispatch(setLastRateRequestError(result.data.error))
                dispatch(setLastRate(initialState.lastRate));
            }
            else {
                console.log("Unforseen lastRate error.")
            }
        }
        
        } catch (e) {
            dispatch(setLastRateRequestError(e));
            dispatch(setLastRate(initialState.lastRate));
        }
    };


export default spotDataReducer;

export const getTwap = data => async (dispatch) => {
    try {
        dispatch(setTwapLoading(true));
        dispatch(setTwapRequestError(false));
        const result = await dataAPI.getTwap(data, "spot");
        if(result.code === 404) {
            console.error(result);
        } else {
            if (result.data.rates){
                
                const twapObject = readGraphData(result);
                dispatch(setTwapLoading(false));
                dispatch(setTwap(twapObject));
            }
            else if (result.data.error) {
                dispatch(setTwapLoading(false));
                dispatch(setTwapRequestError(result.data.error))
                dispatch(setTwap({twap: initialState.twap}));
            }
            else {
                console.log("Unforseen twap error.")
            }
        }
        
        } catch (e) {
            console.error(e);
            dispatch(setTwapLoading(false));
            dispatch(setTwapRequestError(e));
            dispatch(setTwap({twap: initialState.twap}));
        }
    };

export const getOhlc = data => async (dispatch) => {
    try {
        dispatch(setOhlcLoading(true));
        dispatch(setOhlcRequestError(false));
        const result = await dataAPI.getOhlc(data, "spot");
        if(result.code === 404) {
            console.error(result);
        } else {
            if (result.data.closes){
                const ohlcObject = readOhlcGraphData(result);
                dispatch(setOhlcLoading(false));
                dispatch(setOhlc(ohlcObject));
            }
            else if (result.data.error) {
                dispatch(setOhlcLoading(false));
                dispatch(setOhlcRequestError(result.data.error))
                dispatch(setOhlc({ohlc: initialState.ohlc}));
            }
            else {
                console.log("Unforseen ohlc error.")
            }
        }
        
        } catch (e) {
            console.error(e);
            dispatch(setOhlcLoading(false));
            dispatch(setOhlcRequestError(e));
            dispatch(setOhlc({ohlc: initialState.ohlc}));
        }
    };

export const readGraphData = result => {
    let series = [];
    for (let i = 0; i < result.data.rates.length; i++){
        
        let datapoint = {
            time: moment(result.data.times[i]).format('yyyy-MM-DD HH:mm:ss.SSS'),
            rate: result.data.rates[i]
        }
        series.push(datapoint);
    }

    const timeSeriesObject = {
        series: series,
        maxRate: result.data.maxRate,
        minRate: result.data.minRate,
        isSampled: result.data.isSampled,
        numberOfSamples: result.data.numberOfSamples
    };

    return timeSeriesObject;
}

export const readOhlcGraphData = result => {
    let open = [];
    let high = [];
    let low = [];
    let close = [];
    let series = [];
    for (let i = 0; i < result.data.opens.length; i++){

        const formattedTime = moment(result.data.times[i]).format('yyyy-MM-DD HH:mm:ss.SSS');
        open.push({
            time: formattedTime,
            rate: result.data.opens[i]
        });
        high.push({
            time: formattedTime,
            rate: result.data.highs[i]
        });
        low.push({
            time: formattedTime,
            rate: result.data.lows[i]
        });
        close.push({
            time: formattedTime,
            rate: result.data.closes[i]
        });

        let datapoint = {
            time: formattedTime,
            open: result.data.opens[i],
            high: result.data.highs[i],
            low: result.data.lows[i],
            close: result.data.closes[i]
        }
        series.push(datapoint);
    }

    const timeSeriesObject = {
        series: series,
        open: open,
        high: high,
        low: low,
        close: close,
        maxOpen: result.data.maxOpen,
        minOpen: result.data.minOpen,
        maxHigh: result.data.maxHigh,
        minHigh: result.data.minHigh,
        maxLow: result.data.maxLow,
        minLow: result.data.minLow,
        maxClose: result.data.maxClose,
        minClose: result.data.minClose,
        numberOfSamples: result.data.numberOfSamples
    };

    return timeSeriesObject;
}