import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi } from "../../service";
import moment from "moment";
import {setServerError} from "../app-reducer";

export function* fetchDvncRequest(action) {
  // const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/gui/optionsFixings/findAllOptionFixingConfigByDate?ldt=`
        +
        moment(action.payload.ldt).format('ddd MMM DD YYYY'),
        //      action.payload.ldt,
        // {},
        // header
    );
    if (response) {
      yield put(actions.fetchDvncSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchDvncError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}
