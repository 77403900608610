import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import header from 'basic-auth-header';
import AuthenticationService from "../../service/AuthenticationService";
import { fireApi, fireApiBasic } from "../../service";
import {setBasicAuth, setServerError, setUserIsLoggedIn} from "../app-reducer";
import jwt from "jwt-decode";
import { showNotification } from "../../service/index";

export function* loginRequest(action) {
  try {
    const response = yield call(
      fireApiBasic,
      "POST",
      `/user/authenticate`,
        {},
        {},
        action.payload.username,
        action.payload.password
      
    );

    //yield sessionStorage.setItem('basicAuth', header(action.payload.username, action.payload.password));
    if(response.data.hasOwnProperty('Token') && response.data?.Token) {
      const user = jwt(response.data.Token);
      sessionStorage.setItem("name", JSON.stringify(user.name));
      sessionStorage.setItem("userRoles", JSON.stringify(user.roles));
      sessionStorage.setItem('jwtToken', response.data.Token);
      sessionStorage.setItem("tAndC", JSON.stringify(user.tAndC));
      yield put(setUserIsLoggedIn(true));
      sessionStorage.setItem('loggedIn', 'true');
    }

    if (response) {
      if (action.payload.isChecked) {
        localStorage.email = action.payload.username;
        localStorage.password = action.payload.password;
        localStorage.checkbox = action.payload.isChecked;
      } else {
        localStorage.email = "";
        localStorage.password = "";
        localStorage.checkbox = false;
      }

      AuthenticationService.registerSuccessfulLoginForJwt(
        action.payload.username,
        response.data.Token
      );

      
      let loggedUserData = AuthenticationService.getLoggedUser();
      yield put(actions.loginSuccess(loggedUserData));
      yield put(setBasicAuth(header(action.payload.username, action.payload.password)));
    }
  } catch (e) {
    yield put(actions.loginError(e));
  }
}

export function* setAuthorised(action) {
  const user = jwt(action.payload.token);
  sessionStorage.setItem("name", JSON.stringify(user.name));
  sessionStorage.setItem("userRoles", JSON.stringify(user.roles));
  sessionStorage.setItem('jwtToken', action.payload.token);
  sessionStorage.setItem("tAndC", JSON.stringify(user.tAndC));
  yield put(setUserIsLoggedIn(true));
  sessionStorage.setItem('loggedIn', 'true');

  if (action.payload.isChecked) {
    localStorage.email = action.payload.username;
    localStorage.password = action.payload.password;
    localStorage.checkbox = action.payload.isChecked;
  } else {
    localStorage.email = "";
    localStorage.password = "";
    localStorage.checkbox = false;
  }

  AuthenticationService.registerSuccessfulLoginForJwt(
    JSON.stringify(user.name),
    action.payload.token
  );
  
  let loggedUserData = AuthenticationService.getLoggedUser();
  yield put(actions.loginSuccess(loggedUserData));
  yield put(setBasicAuth(header(action.payload.username, action.payload.password)));
}

export function* isLoggedInUser(action) {
  let loggedUserData = AuthenticationService.getLoggedUser();
  if (loggedUserData && loggedUserData.token) {
    yield put(actions.loginSuccess(loggedUserData));
  }
}

export function* logoutRequest(action) {
  // const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/user/logout`,
      {},
      header
    );
    yield put(actions.logoutSuccess());
  } catch (e) {
    yield put(actions.logoutError());
  }
  AuthenticationService.logout();
}

export function* changedPassword(action){
  return put(actions.changedPassword());
}

export function* termsRequest(action) {
  try{
    const response = yield call(
      fireApi,
      "GET",
      `/user/terms`,
      {}
    );
    if (response){
      showNotification({ type: "success", title: "Terms and Conditions Accepted Successfully", message: response.data});
      const user = jwt(response.data.value);
      sessionStorage.setItem("name", JSON.stringify(user.name));
      sessionStorage.setItem('jwtToken', response.data.value);
      sessionStorage.setItem("tAndC", JSON.stringify(user.tAndC));
        yield put(actions.termsSuccess(response.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.termsError(e.response.data.message));
  }
}

export function* registerRequest(action) {
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/user/saveUser`,
      action.payload
    );
    if (response) {
      if (response.data == "User has been registered, email with change password link has been sent."){
        //showNotification({ type: "success", title: "Password Updated", message: "Registered successfully! Check your emails for steps to login."});
        yield put(actions.registerSuccess(response.data));
      }
      else {
        showNotification({ type: "error", title: "Password Updated", message: response.data});
        yield put(actions.registerError(response.data));
      }
      console.log(response);
    }
  } catch (e) {
    console.log(e);
    yield put(actions.registerError(e.response.data.message));
  }
}
