import React, { Component } from "react";
import "./LiveBenchmark.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import { config } from "../constants";

class LiveBenchmarks extends Component {
  mountPost(optionsConfig) {
    return (
      <div className="LiveBenchmark">
        <div className="lander">
          <Tabs>
            {optionsConfig.map((fixings, index) => (
              <Tab eventKey={fixings.name} title={fixings.name} key={index}>
                <div className="utc">
                  {!!fixings.utc_fixing_time
                    ? "UTC Fixing Time: " + fixings.utc_fixing_time
                    : ""}
                </div>

                {fixings.optionsFixings.map((fixing, index) => (
                  <div className="fixings" key={index}>
                    <span className="leftDiv">{fixing.currency_pair}</span>
                    <span className="rightDiv">{fixing.rate}</span>
                  </div>
                ))}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default LiveBenchmarks;
