import React, {useRef, useState, useEffect} from 'react';
import { curveCardinal } from 'd3-shape';
import {AreaChart, Brush, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine} from 'recharts';
import {useContainerDimensions} from '../../../utils/helpers';

const DecayDisplay = ({decay, decayError}) => {
    const containerRef = useRef();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000);
    const { width, height } = useContainerDimensions(containerRef);
    const cardinal = curveCardinal.tension(1);

    function CustomTooltip({ payload, label, active}) {
        if (active && payload){
            return (
                <div>
                    <h6><b>{`${payload[0].payload.time}`}</b></h6>
                    <h6 style={{color: "#F95987"}}><b>{`${payload[0].payload.cost}`}</b></h6>
                </div>
            );
        }
        return null;
    }

    const wrapperStyle = { padding: "10px", backgroundColor: '#FFF', border: '1px solid #d5d5d5' };

    useEffect(() => {
        setMax(decay.maxCost);
        setMin(decay.minCost);
    }, [decay.minCost, decay.maxCost]);

    return (<>
                {decayError &&
                (<><span className="calculations__display__error">{decayError.message}</span></>)}

                <div className="exchange-dashboard__chart" ref={containerRef}>
                    <AreaChart width={width} height={height} data={decay.decays}
                            margin={{top: 50, right: 0, left: 10, bottom: 10}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F95987" stopOpacity={0.3}/>
                                <stop offset="95%" stopColor="#F95987" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#eee"/>
                        <XAxis dataKey="time" minTickGap={15} padding={window.innerWidth < 767 ? { right: 50 } : {}} />
                        <YAxis dataKey="cost" orientation="right" hide={window.innerWidth < 767} /* label={{value: "Cost", position: "insideLeft", angle: -90, dy: 30}}*/
                            domain={[min, max]}
                        />
                        <ReferenceLine x={decay.centreTime} stroke="#6fdeaf" strokeWidth={4} label={<text>{'Fill Time'}</text>} />
                        <Tooltip wrapperStyle={wrapperStyle} content={<CustomTooltip />}/>
                        <Area type={cardinal} dataKey="cost" stroke="#F95987" strokeWidth={4} fill="url(#colorUv)"/>
                        
                    </AreaChart>
                </div>

                {decay.decays.length > 0 && 
                (<>
                    <table className="calculations__table account-page__table">
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <span>Timestamp</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span>Cost</span>
                                </div>
                            </th>
                            
                        </tr>
                        </thead>

                        <tbody>
                            {decay.decays.map((result, index) => (
                            <tr key={index}>
                                <td>{result.time}</td>
                                <td>{result.cost}</td>
                            </tr>))}
                        </tbody>
                    </table>
                </>)}
            </>
        )
}

export default DecayDisplay;