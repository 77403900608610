import React from 'react';
import {connect} from "react-redux";
import {getDecay} from "../../../redux/calculations/reducer.js";
import DecayDisplay from "./DecayDisplay.jsx";
import CalculationsForm from "../CalculationsForm.jsx";
import moment from "moment";

const DecayContainer = props => {
    const {currencyPairs, decay, decayRequestSuccess, decayRequestError, getDecay, currentCalculation} = props;

    const submit = ({currencyPair, sellBuy, fillTime, timeUnit, amount, dealtRate}) => {
        let baseCurrency = currencyPair.value.substring(0,3);
        let termCurrency = currencyPair.value.substring(3,6);
        let buyCurrency = sellBuy == 'b'? baseCurrency : termCurrency;
        let sellCurrency = sellBuy == 'b'? termCurrency : baseCurrency;

        let unit = timeUnit.label == "Seconds"? "s" : "m";

        const data = {
            buyCurrency: buyCurrency,
            sellCurrency: sellCurrency,
            tradeSide: sellBuy,
            fillTime: moment(fillTime).format('YYYY-MM-DD HH:mm:ss'),
            decays: [30, 60, 90, 120, 150, 180, 210, 240, 270, 300],
            timeUnit: unit,
            amount: amount,
            dealtRate: dealtRate
        }
        getDecay(data);
    }


    return (
    <>
        <div>
            <CalculationsForm currencyPairs={currencyPairs} onSubmit={submit} currentCalculation={currentCalculation}/>
            <DecayDisplay decay={decay} decayError={decayRequestError}/>  
        </div>
    </>);
};


const mapStateToProps = state => ({
    decay: state.calculations.decay,
    decayRequestSuccess: state.calculations.decayRequestSuccess,
    decayRequestError: state.calculations.decayRequestError,

    currentCalculation: state.calculations.currentCalculation
});

export default connect(mapStateToProps,{getDecay})(DecayContainer);