import React from 'react';
import {connect} from "react-redux";
import {getTca} from "../../../redux/calculations/reducer.js";
import TcaDisplay from "./TcaDisplay.jsx";
import CalculationsForm from "../CalculationsForm.jsx";
import moment from "moment";

const TcaContainer = props => {
    const {currencyPairs, tca, tcaError, getTca, currentCalculation} = props;

    const submit = ({amount, currencyPair, dealtRate, sell_buy, fillTime}) => {
        
        let sellBuy = sell_buy == 'b'? "buy" : "sell";
        const spotFormData = {
            amount,
            rate: dealtRate,
            sell_buy: sellBuy,
            currency_pair: currencyPair.value,
            timestamp: moment(fillTime).format('YYYY-MM-DD HH:mm:ss')
        };
        getTca(spotFormData);

        setTimeout(() => {
        }, 500);
    };

    return (
    <>
            <div>
                <CalculationsForm currencyPairs={currencyPairs} onSubmit={submit} currentCalculation={currentCalculation}/>
                <TcaDisplay tca={tca} tcaError={tcaError}/>  
            </div>
    </>);
};


const mapStateToProps = state => ({
    tca: state.calculations.tca,
    tcaError: state.calculations.tcaRequestError,
    currentCalculation: state.calculations.currentCalculation
});

export default connect(mapStateToProps,{getTca})(TcaContainer);