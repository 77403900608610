import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import validator from "validator";
import { Button, Form } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";

import * as actions from "../../redux/actions";

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      formSubmissionError: "",
      address: "",
    };
  }

  componentDidMount() {
    const { loggedUserData } = this.props;
    this.props.fetchUserInfoByUsernameRequest({
      username: loggedUserData.user,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfoByUsername !== prevProps.userInfoByUsername) {
      this._fillExistingDetails();
    }
  }

  _fillExistingDetails = () => {
    const { userInfoByUsername } = this.props;

    if (userInfoByUsername) {
      this.setState({
        id: userInfoByUsername.id || "",
        fullName: userInfoByUsername.fullName || "",
        loginName: userInfoByUsername.loginName || "",
        emailAddress: userInfoByUsername.emailAddress || "",
        title: userInfoByUsername.title || "",
      });
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  _getFormBody = () => {
    const { userInfoByUsername } = this.props;
    let selectedRoles = [];
    userInfoByUsername.roles.forEach((item) => {
      selectedRoles.push(item.id);
    });
    let body = {
      id: userInfoByUsername.id || "",
      fullName: this.state.fullName || "",
      loginName: this.state.loginName || "",
      emailAddress: this.state.emailAddress || "",
      title: this.state.title || "",
      selectedRoles: selectedRoles,
      admin: userInfoByUsername.admin,
      approved: userInfoByUsername.approved,
      approvedBy: userInfoByUsername.approvedBy ? 1 : 0,
      organization: userInfoByUsername.organization,
    };
    return body;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let body = this._getFormBody();
    let errMessage = "";

    if (body.fullName === "") {
      errMessage = "Enter Full Name!!";
    } else if (!validator.isByteLength(body.fullName, { min: 4 })) {
      errMessage = "Full Name - Minimum 4 Characters!!";
    } else if (!validator.isByteLength(body.fullName, { max: 128 })) {
      errMessage = "Full Name - Maximum 128 Characters!!";
    } else if (body.title === "") {
      errMessage = "Enter Job Title!!";
    } else if (!validator.isByteLength(body.title, { min: 3 })) {
      errMessage = "Job Title - Minimum 3 Characters!!";
    } else if (!validator.isByteLength(body.title, { max: 128 })) {
      errMessage = "Job Title - Maximum 128 Characters!!";
    }

    this.setState(
      {
        formSubmissionError: errMessage,
      },
      () => {
        if (errMessage === "") {
          // submit form
          this.props.updateContactRequest(body);
        }
      }
    );
  };

  render() {
    return (
      <div id="update-password">
        <div className="">
          <div className="register-photo">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <h2 className="text-center">
                  <strong>Contact</strong> Info.
                </h2>
                <div className="formErrorValidation">
                  {this.state.formSubmissionError &&
                    this.state.formSubmissionError !== "" && (
                      <span>{this.state.formSubmissionError}</span>
                    )}
                </div>

                <div className="form-group">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    ref="fullNameField"
                    type="text"
                    name="fullName"
                    placeholder={"Full Name"}
                    value={this.state.fullName}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>

                <div className="form-group">
                  <Form.Label>Login Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="loginName"
                    placeholder={"Login Name"}
                    value={this.state.loginName}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    disabled
                    type="email"
                    name="emailAddress"
                    placeholder={"Email Address"}
                    value={this.state.emailAddress}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>

                <div className="form-group">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder={"Job Title"}
                    value={this.state.title}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>

                <br />

                {this.state.newPassword && this.state.newPassword !== "" ? (
                  <PasswordStrengthBar
                    password={this.state.newPassword}
                    onChangeScore={(score) => {
                      this.setState({
                        passwordStrength: score,
                      });
                    }}
                  />
                ) : null}

                <div className="form-group">
                  <Button className="btn btn-primary btn-block" type={"submit"}>
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data,
  userInfoByUsername: state.users.userInfoByUsername.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateContactRequest: (data) => dispatch(actions.updateContactRequest(data)),
  fetchUserInfoByUsernameRequest: (data) =>
    dispatch(actions.fetchUserInfoByUsernameRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactInfo)
);
