import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../service";

export function* fetchUsersRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/api/v1/user/findAll`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.fetchUsersSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchUsersError(e.response.data));
  }
}

export function* updateUsersRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "PUT",
      `/api/v1/user/updateAdminUser`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Updated!!",
      });
      yield put(actions.updateUsersSuccess(response.data));
      yield put(actions.fetchUsersRequest());
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while updating!!",
    });
    yield put(actions.updateUsersError(e.response.data));
  }
}

export function* deleteUsersRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "DELETE",
      `/api/v1/user/delete/` + action.payload.id,
      {},
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully deleted!!",
      });
      yield put(actions.deleteUsersSuccess(response.data));
      yield put(actions.fetchUsersRequest(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while deleting!!",
    });
    yield put(actions.deleteUsersError(e.response.data));
  }
}

export function* addUsersRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/api/v1/user/saveAdminUser`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Added!!",
      });
      yield put(actions.addUsersSuccess(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: e.response.data.message || "Error while adding!!",
    });
    yield put(actions.addUsersError(e.response.data));
  }
}

export function* fetchUserInfoByIdRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/api/v1/user/findById/` + action.payload.id,
      {},
      header
    );
    if (response) {
      yield put(actions.fetchUserInfoByIdSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchUserInfoByIdError(e.response.data));
  }
}

export function* fetchUserInfoByUsernameRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/api/v1/user/findByLoginName/` + action.payload.username,
      {},
      header
    );
    if (response) {
      yield put(actions.fetchUserInfoByUsernameSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchUserInfoByUsernameError(e.response.data));
  }
}

export function* updateContactRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "PUT",
      `/api/v1/user/updateAdminUser`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Updated!!",
      });
      yield put(actions.updateContactSuccess(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while updating!!",
    });
    yield put(actions.updateContactError(e.response.data));
  }
}

export function* updatePasswordRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/api/v1/user/changePassword`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Updated!!",
      });
      yield put(actions.updatePasswordSuccess(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: e.response.data.message || "Error while updating!!",
    });
    yield put(actions.updatePasswordError(e.response.data));
  }
}
