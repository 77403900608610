import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AuthenticationService from "../service/AuthenticationService";
import * as actions from "../redux/actions";
import {selectIsUserLoggedIn, selectServerError} from "../redux/select-app-reducer";
import {setUserIsLoggedIn} from "../redux/app-reducer";
import { showNotification } from "../service/index";
import { Flag } from "react-bootstrap-icons";

class AuthenticatedRoute extends Component {
  constructor(props) {
    super(props);
    this.props.isLoggedInUser();
  }

  componentDidMount() {
    this.props.isLoggedInUser();
  }

  _isNonAuthPage = (path) => {
    let arr = [
      "/",
      "/login",
      "/Register",
      "/ForgotPassword",
      "/ChangePasswordFromReset"
    ];
    if (arr.indexOf(path) !== -1) {
      return true;
    }
    return false;
  };

  isJwtExpired = () => {
    const expiryDateString = sessionStorage.getItem("jwtExpiry");
    return this.isDateExpired(expiryDateString);
  };

  isPasswordExpired = () => {
    const expiryDateString = sessionStorage.getItem("passwordExpiry");
    return this.isDateExpired(expiryDateString);
  };

  isDateExpired = (expiryDateString) => {
    
      var match;
      var expiryDate;
      var todaysDate = new Date();
      if (expiryDateString){

        if (expiryDateString == "null")
        {
          return true;
        }

        match = expiryDateString.slice(1,-1).match(/^(\d+)-(\d+)-(\d+) (\d+)\:(\d+)\:(\d+)$/);
        expiryDate = new Date(match[1], match[2] - 1, match[3], match[4], match[5], match[6]);
        
        if (todaysDate > expiryDate){
          return true;
        }
        else{
          return false;
        }

      }
      else{
        return false;
      }
  };

  isTAndC = () => {
    const TAndC = sessionStorage.getItem("tAndC");
    if (TAndC){
    if (TAndC.slice(1,-1) === "1"){
      return true;
    } 
    else {
      return false;
    }
  }
  else{
    return false;
  }
  }

  render() {
    if(this.props.serverError) {
      return <Redirect to={'/error'} />;
    }

    let isNonAuthPage = this._isNonAuthPage(this.props.location.pathname);
    let isUserLoggedIn = false;
    let isUserExpired = this.isPasswordExpired();
    let isJwtExpired = this.isJwtExpired();
    let isTAndCAccepted = this.isTAndC();

    const { login } = this.props;
    if (login && login.data && login.data.token && this.props.isUserLoggedIn) {
      isUserLoggedIn = true;
    }
    // check if it is direct call, then check direct session also
    if (!isUserLoggedIn) {
      if (this.props.isUserLoggedIn) {
        isUserLoggedIn = true;
      }
    }



    if (isUserLoggedIn) {
      if ((isJwtExpired == true) && !(this.props.location.pathname === ("/login"))){
        return <Redirect to="/login" />;
      }
      if ((isJwtExpired == true) && (this.props.location.pathname === ("/login"))){
        this.props.logoutRequest();
        this.props.setUserIsLoggedIn(false);
        showNotification({
          type: "error",
          title: "Session Expired",
          message: "Your session has expired, please login again to begin a new session",
        });
        return <Redirect to="/login" />;
      }
      if ((isTAndCAccepted == false) && !(this.props.location.pathname === ("/TermsAndConditions"))){
        return <Redirect to="/TermsAndConditions" />;
      }
      if ((isTAndCAccepted == true) && (this.props.location.pathname === ("/TermsAndConditions"))){
        return <Redirect to="/Home" />;
      }
      if (this.props.location.pathname === ("/TermsAndConditions")){
        return <Route {...this.props} />;
      }
      if (isUserExpired && !(this.props.location.pathname === ("/ChangeExpiredPassword"))){
        return <Redirect to="/ChangeExpiredPassword" />;
      }
      if (!isUserExpired && (this.props.location.pathname === ("/ChangeExpiredPassword"))) {
        return <Redirect to="/Home" />;
      }
      if (isNonAuthPage) { 
          return <Redirect to="/Home" />;
      } else {
          return <Route {...this.props} />;
        
      }
    } else {
      if (isNonAuthPage) {
        return <Route {...this.props} />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  }
}

const mapStateToProps = state => ({
  login: state.auth.login,
  serverError: selectServerError(state),
  isUserLoggedIn: selectIsUserLoggedIn(state),
  terms: state.auth.terms,
  password: state.auth.password
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  setUserIsLoggedIn: (data) => dispatch(setUserIsLoggedIn(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute)
);
