import React, {useState} from 'react';
import cn from "classnames";
import SpotMenu from "./Menus/SpotMenu";
import ForwardMenu from "./Menus/ForwardMenu";
import NDFMenu from "./Menus/NDFMenu";

const Data = props => {

    const {currencyPairs, financialInstrument, setFinancialInstrument} = props;

    const tabs = [
        {
            name: 'Spot',
            component: <SpotMenu currencyPairs={currencyPairs}/>
        },
        {
            name: 'Forward',
            component: <ForwardMenu currencyPairs={currencyPairs}/>
        }
    ];

    return (
        <main className="main exchange-page">
            <div className="container">
                <div className="exchange-page__top exchange-page-top">
                    <div className="exchange-page-top__left">
                        <h1>
                            <span>Data</span>
                        </h1>
                    </div>
                </div>
                <div className="calculations">
                    <div className="form-tabs" >
                        <div className="form-tabs__header">
                            {tabs ? tabs.map((item, index) =>
                                <div key={index}
                                    className={cn('calculations_tabs__data form-tabs__switch', {'form-tabs__switch--active': index === financialInstrument})}
                                    onClick={() => 
                                    {
                                        setFinancialInstrument(index);
                                    }
                                    }>
                                    {item.name}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-tabs__content">
                            {tabs ? tabs.map((item, index) =>
                                <div key={index}
                                    className={cn('form-tabs__item', {'form-tabs__item--active': index === financialInstrument})}>
                                    {item.component}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default Data;