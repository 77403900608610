import constants from "./constants";
import { takeLatest, takeEvery, all } from "redux-saga/effects";
import {
  loginRequest,
  setAuthorised,
  isLoggedInUser,
  logoutRequest,
  registerRequest,
  termsRequest,
  changedPassword,
} from "./auth/action";
import {
  fetchOrganizationsRequest,
  updateOrganizationsRequest,
  deleteOrganizationsRequest,
  addOrganizationsRequest,
  fetchOrganizationInfoRequest,
} from "./organization/action";
import {
  fetchRolesRequest,
  updateRolesRequest,
  deleteRolesRequest,
  addRolesRequest,
} from "./roles/action";
import {
  fetchUsersRequest,
  updateUsersRequest,
  deleteUsersRequest,
  addUsersRequest,
  fetchUserInfoByIdRequest,
  fetchUserInfoByUsernameRequest,
  updateContactRequest,
  updatePasswordRequest,
} from "./users/action";
import { fetchOptionFixingsRequest, fetchSirensRequest } from "./siren/action";
import { fetchDvncRequest } from "./dvnc/action";
import { fetchDubaiGoldRequest } from "./dubaigold/action";

function* watchActions() {
  /*login*/
  yield takeLatest(constants.LOGIN_REQUEST, loginRequest);
  /* Set user authorised if logging in via website */
  yield takeLatest(constants.SET_AUTHORISED, setAuthorised);
  /* check user is logged in*/
  yield takeLatest(constants.IS_LOGGEDIN_USER, isLoggedInUser);
  /*logout*/
  yield takeLatest(constants.LOGOUT_REQUEST, logoutRequest);
  /*register*/
  yield takeLatest(constants.REGISTER_REQUEST, registerRequest);
  /*terms*/
  yield takeLatest(constants.TERMS_REQUEST, termsRequest);

  yield takeLatest(constants.CHANGED_PASSWORD, changedPassword);

  /****************************/
  /********ORGANIZATION***************/
  /****************************/
  /* fetch organization*/
  yield takeLatest(
    constants.FETCH_ORGANIZATION_REQUEST,
    fetchOrganizationsRequest
  );
  /*update organization*/
  yield takeLatest(
    constants.UPDATE_ORGANIZATION_REQUEST,
    updateOrganizationsRequest
  );
  /*delete organization*/
  yield takeLatest(
    constants.DELETE_ORGANIZATION_REQUEST,
    deleteOrganizationsRequest
  );
  /*add organization*/
  yield takeLatest(constants.ADD_ORGANIZATION_REQUEST, addOrganizationsRequest);
  /* fetch organization */
  yield takeLatest(
    constants.FETCH_ORGANIZATION_INFO_REQUEST,
    fetchOrganizationInfoRequest
  );

  /****************************/
  /********ROLES***************/
  /****************************/
  /* fetch roles*/
  yield takeLatest(constants.FETCH_ROLES_REQUEST, fetchRolesRequest);
  /* update roles*/
  yield takeLatest(constants.UPDATE_ROLES_REQUEST, updateRolesRequest);
  /* delete roles*/
  yield takeLatest(constants.DELETE_ROLES_REQUEST, deleteRolesRequest);
  /* add roles*/
  yield takeLatest(constants.ADD_ROLES_REQUEST, addRolesRequest);

  /****************************/
  /********USERS***************/
  /****************************/
  /* fetch users*/
  yield takeLatest(constants.FETCH_USERS_REQUEST, fetchUsersRequest);
  /* update users*/
  yield takeLatest(constants.UPDATE_USERS_REQUEST, updateUsersRequest);
  /* update contact*/
  yield takeLatest(constants.UPDATE_CONTACT_REQUEST, updateContactRequest);
  /* update password*/
  yield takeLatest(constants.UPDATE_PASSWORD_REQUEST, updatePasswordRequest);
  /* delete users*/
  yield takeLatest(constants.DELETE_USERS_REQUEST, deleteUsersRequest);
  /* add users*/
  yield takeLatest(constants.ADD_USERS_REQUEST, addUsersRequest);
  /* fetch user by id */
  yield takeLatest(
    constants.FETCH_USER_INFO_BY_ID_REQUEST,
    fetchUserInfoByIdRequest
  );
  /* fetch user by username */
  yield takeLatest(
    constants.FETCH_USER_INFO_BY_USERNAME_REQUEST,
    fetchUserInfoByUsernameRequest
  );

  /* siren */
  yield takeLatest(
    constants.FETCH_OPTION_FIXINGS_REQUEST,
    fetchOptionFixingsRequest
  );
  yield takeLatest(constants.FETCH_SIRENS_REQUEST, fetchSirensRequest);

  /* dvnc */
  yield takeLatest(constants.FETCH_DVNC_REQUEST, fetchDvncRequest);

  /* dubai gold */
  yield takeLatest(constants.FETCH_DUBAI_GOLD_REQUEST, fetchDubaiGoldRequest);
}

export default function* rootSaga() {
  yield all([watchActions()]);
}
