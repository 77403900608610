import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Link, withRouter} from "react-router-dom";
import * as actions from "../redux/actions";
import {checkEmail, checkTel, maxLengthCreator, minLengthCreator, required} from "../utils/validators/validators";
import {RenderField} from "../components/common/FormsControls/FormsControls";
import LeftSide from "../components/LeftSide";

import "../assets/css/reset.css";
import "../assets/css/main.css";

const TermsAndConditionsForm = (props) => {

    const {handleSubmit, reset, terms} = props;

    const submit = (values) => {
        props.termsRequest(values);
        reset('termsAndConditions');
    };

    return (
        <main className="main home-page">
            <LeftSide/>
            <div className="home-page__right">
                <div className="home-page__forms">
                    <div className="form-item form-item_registration form-item_active">
                        <h3>You must accept the NCFX ClientPortal Terms and Conditions to access this website.</h3>
                        <span className="form-item__server-message form-item__server-message--error">{terms.message}</span>
                        <form className="form-item__form" onSubmit={handleSubmit(submit)}>
                            <Field name="remember" component={RenderField} type="checkbox"
                                   validate={[required]}
                                   text={'I agree to the NCFX Client Portal Terms and Conditions'}
                                   labelAsLink
                                   linkPath={'/terms'}
                                   linkText={'TextPage of Use'}/>
                            <input className="form-item__submit" type="submit" value="Submit"/>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
};

const TermsAndConditions = reduxForm({
    form: 'termsAndConditions'
})(TermsAndConditionsForm);

const mapStateToProps = (state) => ({
    terms: state.auth.terms
});

const mapDispatchToProps = (dispatch) => ({
    termsRequest: (data) => dispatch(actions.termsRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions));
