import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";
import validator from "validator";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Form } from "react-bootstrap";

import MultiSelect from "@khanacademy/react-multi-select";

import * as actions from "../../redux/actions";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmissionError: "",
      userId: "",
      fullName: "",
      loginName: "",
      emailAddress: "",
      title: "",
      selectedRoles: "",
      existingRoles: [],
      approved: false,
      approvedBy: "",
      approvedByUsername: "",
      admin: false,

      approvedByUserDetails: false,
    };
  }

  componentDidMount() {
    const { loggedUserData } = this.props;
    const params = QueryString.parse(this.props.location.search);
    this.props.fetchUserInfoByIdRequest({ id: params.id });
    this.setState({
      userId: params.id,
    });

    if (loggedUserData && loggedUserData.user) {
      this.props.fetchUserInfoByUsernameRequest({
        username: loggedUserData.user,
      });
    }
    this.props.fetchRolesRequest();
    this.refs.fullNameField.focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rolesList !== prevProps.rolesList) {
      this._setExistingRoles(this.props.rolesList);
    }
    if (
      this.props.addUsers.isSuccess &&
      this.props.addUsers.isSuccess !== prevProps.addUsers.isSuccess
    ) {
      this.props.history.push(`/Users`);
    }

    if (this.props.userInfoById !== prevProps.userInfoById) {
      if (this.state.approvedByUserDetails) {
        if (this.props.userInfoById) {
          this.setState({
            approvedBy: this.props.userInfoById.id,
            approvedByUsername: this.props.userInfoById.loginName,
          });
        }
      } else {
        this._setExistingUserDetails();
      }
    }
  }

  _setExistingUserDetails() {
    const { userInfoById } = this.props;

    let selectedRoles = [];

    if (userInfoById) {
      if (userInfoById.roles) {
        userInfoById.roles.forEach((obj) => {
          selectedRoles.push(obj.id);
        });
      }

      // fetch approved by user id username from other api
      if (userInfoById.approvedBy && userInfoById.approvedBy !== "") {
        this.setState(
          {
            approvedByUserDetails: true,
          },
          () => {
            this.props.fetchUserInfoByIdRequest({
              id: userInfoById.approvedBy,
            });
          }
        );
      }

      this.setState({
        fullName: userInfoById.fullName || "",
        loginName: userInfoById.loginName || "",
        emailAddress: userInfoById.emailAddress || "",
        title: userInfoById.title || "",
        approved: userInfoById.approved || 0,
        approvedBy: userInfoById.approvedBy || "",
        admin: userInfoById.admin || 0,
        selectedRoles: userInfoById.selectedRoles || [],
      });
    }
  }

  _setExistingRoles = (roles) => {
    if (roles) {
      let formattedArr = [];
      roles.forEach((element) => {
        formattedArr.push({
          label: element.name,
          value: element.id,
        });
      });
      this.setState({
        existingRoles: formattedArr,
      });
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  _getEditUserBody = () => {
    const { selectedRoles } = this.state;

    let body = {
      id: this.state.userId || "",
      fullName: this.state.fullName || "",
      loginName: this.state.loginName || "",
      emailAddress: this.state.emailAddress || "",
      title: this.state.title || "",
      selectedRoles: selectedRoles,
      admin: this.state.admin ? 1 : 0,
      approved: this.state.approved,
      approvedBy: this.state.approvedBy,
      organization: "",
    };

    return body;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let body = this._getEditUserBody();

    let errMessage = "";

    if (body.fullName === "") {
      errMessage = "Enter Full Name!!";
    } else if (!validator.isByteLength(body.fullName, { min: 4 })) {
      errMessage = "Full Name - Minimum 4 Characters!!";
    } else if (!validator.isByteLength(body.fullName, { max: 128 })) {
      errMessage = "Full Name - Maximum 128 Characters!!";
    } else if (body.title === "") {
      errMessage = "Enter Job Title!!";
    } else if (!validator.isByteLength(body.title, { min: 3 })) {
      errMessage = "Job Title - Minimum 3 Characters!!";
    } else if (!validator.isByteLength(body.title, { max: 128 })) {
      errMessage = "Job Title - Maximum 128 Characters!!";
    } else if (body.selectedRoles.length === 0) {
      errMessage = "Please Choose a Role!!";
    }

    this.setState(
      {
        formSubmissionError: errMessage,
      },
      () => {
        if (errMessage === "") {
          this.props.updateUsersRequest(body);
        }
      }
    );
  };

  _onApprovedChange = () => {
    this.setState(
      {
        approved: !this.state.approved,
      },
      () => {
        if (this.state.approved) {
          this.setState({
            approvedBy: this.props.userInfoByUsername.id,
            approvedByUsername: this.props.userInfoByUsername.loginName,
          });
        } else {
          this.setState({
            approvedBy: "",
            approvedByUsername: "",
          });
        }
      }
    );
  };

  render() {
    const { approvedBy, approvedByUsername } = this.state;
    let approvedLabel = "Approved";
    if (approvedBy) {
      approvedLabel += " ( Approved By:  " + approvedBy;
      if (approvedByUsername && approvedByUsername !== "") {
        approvedLabel += " - " + approvedByUsername;
      }
      approvedLabel += " ) ";
    }

    return (
      <div className="EditUser">
        <div className="lander">
          <div className="register-photo">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <h2 className="text-center">
                  <strong>Update</strong> user.
                </h2>
                <div className="formErrorValidation">
                  {this.state.formSubmissionError &&
                    this.state.formSubmissionError !== "" && (
                      <span>{this.state.formSubmissionError}</span>
                    )}
                </div>

                <div className="form-group">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    ref="fullNameField"
                    type="text"
                    name="fullName"
                    placeholder={"Full Name"}
                    value={this.state.fullName}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>Login Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="loginName"
                    placeholder={"Login Name"}
                    value={this.state.loginName}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    disabled
                    type="email"
                    name="emailAddress"
                    placeholder={"Email Address"}
                    value={this.state.emailAddress}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder={"Job Title"}
                    value={this.state.title}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>Select Roles</Form.Label>
                  <MultiSelect
                    overrideStrings={{
                      selectSomeItems: "Select Roles...",
                    }}
                    options={this.state.existingRoles}
                    selected={this.state.selectedRoles}
                    onSelectedChanged={(selectedRoles) =>
                      this.setState({ selectedRoles })
                    }
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <Form.Group className="border-checkbox">
                  <Form.Check
                    isChecked={this.state.approved ? "1" : "0"}
                    type="checkbox"
                    label={approvedLabel}
                    onClick={this._onApprovedChange}
                  />
                </Form.Group>

                <Form.Group className="border-checkbox">
                  <Form.Check
                    isChecked={this.state.admin ? "1" : "0"}
                    type="checkbox"
                    label="Admin"
                    onClick={() => {
                      this.setState({
                        admin: !this.state.admin,
                      });
                    }}
                  />
                </Form.Group>
                <div className="form-group">
                  <Button className="btn btn-primary btn-block" type={"submit"}>
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data,
  rolesList: state.roles.roles.data,
  addUsers: state.users.addUsers,
  userInfoById: state.users.userInfoById.data,
  userInfoByUsername: state.users.userInfoByUsername.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserInfoByIdRequest: (data) =>
    dispatch(actions.fetchUserInfoByIdRequest(data)),
  fetchRolesRequest: (data) => dispatch(actions.fetchRolesRequest(data)),
  addUsersRequest: (data) => dispatch(actions.addUsersRequest(data)),
  updateUsersRequest: (data) => dispatch(actions.updateUsersRequest(data)),
  fetchUserInfoByUsernameRequest: (data) =>
    dispatch(actions.fetchUserInfoByUsernameRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUser)
);
