import React from 'react';

import RightSidebarHeader from "./RightSidebarHeader";
import RightSidebarTabs from "./RightSidebarTabs/RightSidbarTabs";
import AuthenticationService from "../../service/AuthenticationService";

const isUserAdmin = AuthenticationService.isUserAdmin();
let failedRequest = false;
let message = "";
const RightSidebar = ({direction, currencyPairs, instantTradeCheck}) => {
    if (instantTradeCheck != null){
        if (typeof instantTradeCheck === 'string') {
            failedRequest = true;
            message = instantTradeCheck;
        }
        else {
            failedRequest = false;
        }
    }
    return (
        <div className="exchange-page__right-sidebar right-sidebar" >
            <RightSidebarHeader />
            <div >
                <RightSidebarTabs direction={direction} currencyPairs={currencyPairs} />
                {failedRequest ? 
                (<>
                    <div className="right-sidebar-form__item right-sidebar-form__item">
                        <h3>{message}</h3>
                    </div>
                </>) : (
                    <div className="right-sidebar__bottom right-sidebar-bottom" >
                        <div className="right-sidebar-bottom__item">
                            <div className="right-sidebar-bottom__cost">
                                {instantTradeCheck?.ncfx_benchmark || '0.00'}
                            </div>
                            <div className="right-sidebar-bottom__label">
                                NCFX Benchmark
                            </div>
                        </div>
                        <div className="right-sidebar-bottom__item">
                            <div className="right-sidebar-bottom__cost">
                                $ {instantTradeCheck?.usd_cost || '0.00'}
                            </div>
                            <div className="right-sidebar-bottom__label">
                                USD Cost
                            </div>
                        </div>
                        <div className="right-sidebar-bottom__item">
                            <div className="right-sidebar-bottom__cost">
                                {instantTradeCheck?.usd_bp_cost || '0.00'}
                            </div>
                            <div className="right-sidebar-bottom__label">
                                bp Cost
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default RightSidebar;