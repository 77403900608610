import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../service";
import {setServerError} from "../app-reducer";

export function* fetchRolesRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/api/v1/role/findAll`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.fetchRolesSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchRolesError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* updateRolesRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "PUT",
      `/api/v1/role/updateRole`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Updated!!",
      });
      yield put(actions.updateRolesSuccess(response.data));
      yield put(actions.fetchRolesRequest());
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while updating!!",
    });
    yield put(actions.updateRolesError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* deleteRolesRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "DELETE",
      `/api/v1/role/delete/` + action.payload.id,
      {},
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully deleted!!",
      });
      yield put(actions.deleteRolesSuccess(response.data));
      yield put(actions.fetchRolesRequest(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while deleting!!",
    });
    yield put(actions.deleteRolesError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* addRolesRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/api/v1/role/saveRole`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Added!!",
      });
      yield put(actions.addRolesSuccess(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while adding!!",
    });
    yield put(actions.addRolesError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}
