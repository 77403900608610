import React, {useState} from "react";
import "./ChangePassword.css";
import AuthenticationService from "../service/AuthenticationService";
import LeftSide from "../components/LeftSide";
import {checkEmail, required} from "../utils/validators/validators";
import {RenderField} from "../components/common/FormsControls/FormsControls";
import {Field, reduxForm} from "redux-form";
import { showNotification } from "../service/index";

const ForgotPasswordForm = (props) => {

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [hasLoginFailed, setHasLoginFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {handleSubmit, reset} = props;

    const [submitDisabled, setSubmitDisabled] = useState(false);

     const preventDoubleTap = () => {
        setSubmitDisabled(true);
        
        setTimeout(()=>{
           setSubmitDisabled(false);
        }, 5000)
      }


    const submit = (values) => {
        const jwt = "Bearer " + sessionStorage.getItem("jwtToken");
        preventDoubleTap();
        AuthenticationService.executeForgotPasswordService(values.email, jwt)
            .then((response) => {
              setShowSuccessMessage(true);
              setHasLoginFailed(false);
            })
            .catch((error) => {
              setErrorMessage(error.response.data.message);
              setShowSuccessMessage(false);
              setHasLoginFailed(true);
            });
        //reset('forgot_pass');
    };

    return (
        <main className="main home-page">
            <LeftSide/>
            <div className="home-page__right">
                <div className="home-page__forms">
                    <div className="form-item form-item_reset-pass form-item_active">
                    {showSuccessMessage ? (
                        <>
                            <h3>Email Sent</h3>
                            <h5>If your email address has a user account associated with it, you will receive an email to change your password shortly.</h5>
                            <h5>If you didn't receive an email as expected, refresh the page to try again.</h5>                        
                        </>                
                        ) : (
                        <>
                        
                            <h3>Change your password by entering a valid email address</h3>
                            {hasLoginFailed && (
                                <span className="form-item__server-message form-item__server-message--error">{errorMessage}</span>
                            )}
                            {showSuccessMessage && (
                                <div className="success">
                                    <span className="form-item__server-message form-item__server-message--success">If your email address has a user account associated with it, you will receive an email to change your password shortly.</span>
                                </div>
                            )}
                            <form className="form-item__form" onSubmit={handleSubmit(submit)}>
                                <Field name="email"
                                    validate={[required, checkEmail]}
                                    component={RenderField} label="Email address" type="text"/>
                                <input className="form-item__submit" type="submit" disabled={submitDisabled} value="Change password"/>
                            </form>
                        </>)}
                    </div>
                </div>
            </div>
        </main>
    )
}

const ForgotPassword = reduxForm({
    form: 'forgot_pass'
})(ForgotPasswordForm);

export default ForgotPassword;