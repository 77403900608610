import moment from 'moment';
import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi } from "../../service";
import {setServerError} from "../app-reducer";

export function* fetchDubaiGoldRequest(action) {
  // const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/gui/dubaiGold/findAllDubaiGoldByDate?ldt=`
        +
        moment(action.payload.ldt).format('ddd MMM DD YYYY'),

    );
    if (response) {
      yield put(actions.fetchDubaiGoldSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchDubaiGoldError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}
