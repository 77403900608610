import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {getLastRate as getSpotLastRate, getLastRateFull as getSpotLastRateFull, getLastRateFull} from "../../../redux/data/spot/reducer.js";
import {getLastRate as getForwardLastRate, getLastRateFull as getForwardLastRateFull} from "../../../redux/data/forward/reducer.js";
import LastRateDisplay from "./LastRateDisplay.jsx";
import DataForm from "../DataForm.jsx";
import moment from "moment";

const LastRateContainer = props => {
    const {financialInstrument} = props;

    const {spotLastRate, spotLastRateRequestError, getSpotLastRate} = props;
    const {forwardLastRate, forwardLastRateRequestError, getForwardLastRate} = props;

    const [lastRate, setLastRate] = useState(spotLastRate);
    const [lastRateRequestError, setLastRateRequestError] = useState(spotLastRateRequestError);

    const submit = (data) => {

        switch(financialInstrument) {
            case 0:
                return spotSubmit(data);
            case 1:
                return forwardSubmit(data);
            default:
              // code block
        }
    }

    const spotSubmit = ({currencyPair,  startDate, startTime}) => {
        
        const data = {
            currencyPair: currencyPair.value,
            date: moment(startDate).format('YYYY-MM-DD') + " " + startTime,
        }
        getSpotLastRate(data);
    }

    const forwardSubmit = ({currencyPair, startDate, startTime, tenor}) => {

        const data = {
            currencyPair: currencyPair.value,
            date: moment(startDate).format('YYYY-MM-DD') + " " + startTime,
            tenor: tenor.value
        }
        getForwardLastRate(data);
    }

    useEffect(() => {
        switch(financialInstrument) {
        case 0:
            setLastRate(spotLastRate);
            setLastRateRequestError(spotLastRateRequestError);
        
            break;
        case 1:
            setLastRate(forwardLastRate);
            setLastRateRequestError(forwardLastRateRequestError);
            
          break;
        default:
          // code block
        }
    }, [financialInstrument, spotLastRate, spotLastRateRequestError,
        forwardLastRate, forwardLastRateRequestError]);

    return (
    <>
            <div>
                <DataForm {...props} onSubmit={submit}/>
                <LastRateDisplay lastRate={lastRate}  lastRateRequestError={lastRateRequestError}/>  
            </div>
    </>)
};


const mapStateToProps = state => ({
    spotLastRate: state.spot.lastRate,
    spotLastRateRequestError: state.spot.lastRateRequestError,

    forwardLastRate: state.forward.lastRate,
    forwardLastRateRequestError: state.forward.lastRateRequestError,

    financialInstrument: state.data.financialInstrument,
    dataView: state.data.dataView
});

export default connect(mapStateToProps,{getSpotLastRate, getForwardLastRate})(LastRateContainer);