import React from 'react';
import {connect} from "react-redux";

import Footer from "./Footer";

const FooterContainer = () => {
    return <Footer />
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, null)(FooterContainer);