import { createAction } from "redux-actions";
import constants from "./constants";

export const loginRequest = createAction(constants.LOGIN_REQUEST);
export const setAuthorised = createAction(constants.SET_AUTHORISED);
export const loginSuccess = createAction(constants.LOGIN_SUCCESS);
export const loginError = createAction(constants.LOGIN_ERROR);

export const isLoggedInUser = createAction(constants.IS_LOGGEDIN_USER);

export const logoutRequest = createAction(constants.LOGOUT_REQUEST);
export const logoutSuccess = createAction(constants.LOGOUT_SUCCESS);
export const logoutError = createAction(constants.LOGOUT_ERROR);

export const registerRequest = createAction(constants.REGISTER_REQUEST);
export const registerSuccess = createAction(constants.REGISTER_SUCCESS);
export const registerError = createAction(constants.REGISTER_ERROR);

export const termsRequest = createAction(constants.TERMS_REQUEST);
export const termsSuccess = createAction(constants.TERMS_SUCCESS);
export const termsError = createAction(constants.TERMS_ERROR);

export const changedPassword = createAction(constants.CHANGED_PASSWORD);

/* organization */
export const fetchOrganizationsRequest = createAction(
  constants.FETCH_ORGANIZATION_REQUEST
);
export const fetchOrganizationsSuccess = createAction(
  constants.FETCH_ORGANIZATION_SUCCESS
);
export const fetchOrganizationsError = createAction(
  constants.FETCH_ORGANIZATION_ERROR
);

export const updateOrganizationsRequest = createAction(
  constants.UPDATE_ORGANIZATION_REQUEST
);
export const updateOrganizationsSuccess = createAction(
  constants.UPDATE_ORGANIZATION_SUCCESS
);
export const updateOrganizationsError = createAction(
  constants.UPDATE_ORGANIZATION_ERROR
);

export const deleteOrganizationsRequest = createAction(
  constants.DELETE_ORGANIZATION_REQUEST
);
export const deleteOrganizationsSuccess = createAction(
  constants.DELETE_ORGANIZATION_SUCCESS
);
export const deleteOrganizationsError = createAction(
  constants.DELETE_ORGANIZATION_ERROR
);

export const addOrganizationsRequest = createAction(
  constants.ADD_ORGANIZATION_REQUEST
);
export const addOrganizationsSuccess = createAction(
  constants.ADD_ORGANIZATION_SUCCESS
);
export const addOrganizationsError = createAction(
  constants.ADD_ORGANIZATION_ERROR
);

export const fetchOrganizationInfoRequest = createAction(
  constants.FETCH_ORGANIZATION_INFO_REQUEST
);
export const fetchOrganizationInfoSuccess = createAction(
  constants.FETCH_ORGANIZATION_INFO_SUCCESS
);
export const fetchOrganizationInfoError = createAction(
  constants.FETCH_ORGANIZATION_INFO_ERROR
);
/* organization */

/* roles */
export const fetchRolesRequest = createAction(constants.FETCH_ROLES_REQUEST);
export const fetchRolesSuccess = createAction(constants.FETCH_ROLES_SUCCESS);
export const fetchRolesError = createAction(constants.FETCH_ROLES_ERROR);

export const updateRolesRequest = createAction(constants.UPDATE_ROLES_REQUEST);
export const updateRolesSuccess = createAction(constants.UPDATE_ROLES_SUCCESS);
export const updateRolesError = createAction(constants.UPDATE_ROLES_ERROR);

export const deleteRolesRequest = createAction(constants.DELETE_ROLES_REQUEST);
export const deleteRolesSuccess = createAction(constants.DELETE_ROLES_SUCCESS);
export const deleteRolesError = createAction(constants.DELETE_ROLES_ERROR);

export const addRolesRequest = createAction(constants.ADD_ROLES_REQUEST);
export const addRolesSuccess = createAction(constants.ADD_ROLES_SUCCESS);
export const addRolesError = createAction(constants.ADD_ROLES_ERROR);
/* roles */

/* users */
export const fetchUsersRequest = createAction(constants.FETCH_USERS_REQUEST);
export const fetchUsersSuccess = createAction(constants.FETCH_USERS_SUCCESS);
export const fetchUsersError = createAction(constants.FETCH_USERS_ERROR);

export const updateUsersRequest = createAction(constants.UPDATE_USERS_REQUEST);
export const updateUsersSuccess = createAction(constants.UPDATE_USERS_SUCCESS);
export const updateUsersError = createAction(constants.UPDATE_USERS_ERROR);

export const updateContactRequest = createAction(
  constants.UPDATE_CONTACT_REQUEST
);
export const updateContactSuccess = createAction(
  constants.UPDATE_CONTACT_SUCCESS
);
export const updateContactError = createAction(constants.UPDATE_CONTACT_ERROR);

export const deleteUsersRequest = createAction(constants.DELETE_USERS_REQUEST);
export const deleteUsersSuccess = createAction(constants.DELETE_USERS_SUCCESS);
export const deleteUsersError = createAction(constants.DELETE_USERS_ERROR);

export const addUsersRequest = createAction(constants.ADD_USERS_REQUEST);
export const addUsersSuccess = createAction(constants.ADD_USERS_SUCCESS);
export const addUsersError = createAction(constants.ADD_USERS_ERROR);

export const fetchUserInfoByIdRequest = createAction(
  constants.FETCH_USER_INFO_BY_ID_REQUEST
);
export const fetchUserInfoByIdSuccess = createAction(
  constants.FETCH_USER_INFO_BY_ID_SUCCESS
);
export const fetchUserInfoByIdError = createAction(
  constants.FETCH_USER_INFO_BY_ID_ERROR
);

export const fetchUserInfoByUsernameRequest = createAction(
  constants.FETCH_USER_INFO_BY_USERNAME_REQUEST
);
export const fetchUserInfoByUsernameSuccess = createAction(
  constants.FETCH_USER_INFO_BY_USERNAME_SUCCESS
);
export const fetchUserInfoByUsernameError = createAction(
  constants.FETCH_USER_INFO_BY_USERNAME_ERROR
);

export const updatePasswordRequest = createAction(
  constants.UPDATE_PASSWORD_REQUEST
);
export const updatePasswordSuccess = createAction(
  constants.UPDATE_PASSWORD_SUCCESS
);
export const updatePasswordError = createAction(
  constants.UPDATE_PASSWORD_ERROR
);

/* users */

/* sirens */
export const fetchOptionFixingsRequest = createAction(
  constants.FETCH_OPTION_FIXINGS_REQUEST
);
export const fetchOptionFixingsSuccess = createAction(
  constants.FETCH_OPTION_FIXINGS_SUCCESS
);
export const fetchOptionFixingsError = createAction(
  constants.FETCH_OPTION_FIXINGS_ERROR
);

export const fetchSirensRequest = createAction(constants.FETCH_SIRENS_REQUEST);
export const fetchSirensSuccess = createAction(constants.FETCH_SIRENS_SUCCESS);
export const fetchSirensError = createAction(constants.FETCH_SIRENS_ERROR);
/* sirens */

/* dvnc cuts*/
export const fetchDvncRequest = createAction(constants.FETCH_DVNC_REQUEST);
export const fetchDvncSuccess = createAction(constants.FETCH_DVNC_SUCCESS);
export const fetchDvncError = createAction(constants.FETCH_DVNC_ERROR);
/* dvnc cuts*/

/* dubai gold*/
export const fetchDubaiGoldRequest = createAction(
  constants.FETCH_DUBAI_GOLD_REQUEST
);
export const fetchDubaiGoldSuccess = createAction(
  constants.FETCH_DUBAI_GOLD_SUCCESS
);
export const fetchDubaiGoldError = createAction(
  constants.FETCH_DUBAI_GOLD_ERROR
);
/* dubai gold*/
