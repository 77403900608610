import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import * as actions from "../../redux/actions";

import UpdatePassword from "./UpdatePassword";
import ContactInfo from "./ContactInfo";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesMenu: [
        {
          label: "Contact Info",
          url: "/SettingsContactInfo",
        },
        {
          label: "Password Update",
          url: "/SettingsUpdatePassword",
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  _renderWebFilters = () => {
    let { pagesMenu } = this.state;
    return (
      <div className="account-page__settings">
        <ul className="account-page__times settings-menu-list">
          {pagesMenu.map((page, index) => (
            <div
              key={index}
              id={index}
              onClick={() => {
                this.props.history.push(page.url);
              }}
            >
              <li
                id={"li" + index}
                className={
                  page.url === this.props.history.location.pathname
                    ? "js-time active"
                    : "js-time"
                }
              >
                <span>{page.label}</span>
              </li>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  _renderMobileFilters = () => {
    let { pagesMenu } = this.state;

    return (
      <div className="mobile-times settings-menu-list-mobile">
        <ul>
          {pagesMenu.map((page, index) => (
            <div
              key={index}
              id={index}
              onClick={() => {
                this.props.history.push(page.url);
              }}
            >
              <li
                id={"li" + index}
                className={
                  page.url === this.props.history.location.pathname
                    ? "js-time active"
                    : "js-time"
                }
              >
                <span>{page.label}</span>
              </li>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    let componentToRender = <ContactInfo />;
    if (
      this.props.history.location &&
      this.props.history.location.pathname === "/SettingsUpdatePassword"
    ) {
      componentToRender = <UpdatePassword />;
    }

    return (
      <div id="page-settings">
        <Container className="main-container">
          <Row className="row-settings">
            <Col lg={3} sm={12} className="settings-filter-menu">
              {this._renderWebFilters()}
              {this._renderMobileFilters()}
            </Col>

            <Col lg={9} sm={12} className="settings-content">
              {componentToRender}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDvncRequest: (data) => dispatch(actions.fetchDvncRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
