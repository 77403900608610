import {useEffect, useState} from 'react';
import {store} from "../redux/store";

export const checkObj = (x, y) => {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same
    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects
    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.
    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor
        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined
        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal
        if (typeof (x[p]) !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal
        if (!Object.equals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }
    for (p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
        // allows x[ p ] to be set to undefined
    }
    return true;
}

export const useContainerDimensions = myRef => {
    const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
    })

    const [dimensions, setDimensions] = useState({width: 0, height: 0})

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])

    return dimensions;
};


export const sortRates = (array) => {
    let params = store.getState().exchange.sortRates || [];
    let selectedValues = array.filter(rate => (!params.includes(rate.currencyPair)));
    let noSelectedValues = array.filter(rate => (params.includes(rate.currencyPair)));
    let sortArray = noSelectedValues.concat(selectedValues);

    return params.length ? sortArray : array;
};

export const getTimeInSecondsFromStringName = time => {
    let timeInSec = 0;

    switch (time) {
        case '1 min':
            timeInSec = 60;
            break;
        case '30 min':
            timeInSec = 1800;
            break;
        case '1 hour':
            timeInSec = 3600;
            break;
        case '1 day':
            timeInSec = 86400;
            break;
        case '1 week':
            timeInSec = 604800;
            break;
        case '1 month':
            timeInSec = 2592000;
            break;
        case '6 months':
            timeInSec = 15552000;
            break;
        case '1 year':
            timeInSec = 31556952;
            break;
        case '10 years':
            timeInSec = 315569520;
            break;
        default:
            timeInSec = 86400;
            console.error('Default Chart Interval, time input in getTimeInSecondsFromStringName function argument is not correct');
    }

    return timeInSec;
};

export const getFormatDateByTime = time => {
    switch (time) {
        case '1 min':
            return 'HH:mm:ss.SSS'
        case '30 min':
        case '1 hour':
            return 'HH:mm:ss'
        case '1 week':
        case '1 day':
        case '1 month':
        case '6 months':
        case '1 year':
            return 'DD MMM HH:mm';
        case '10 years':
            return 'MMM YYYY';
        default:
            console.error('Default Chart Time Format, time input in getFormatDateByTime function argument is not correct');
            return 'MM/YYYY';
    }
};
