import React, {useRef, useState, useEffect} from 'react';
import { curveCardinal } from 'd3-shape';
import {AreaChart, Brush, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import {useContainerDimensions} from '../../../utils/helpers';
import cn from "classnames";

const OhlcDisplay = ({ohlc, ohlcRequestError, ohlcLoading, setExportToCsv, isCsvDownloadFinished}) => {
    const containerRef = useRef();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000);
    const { width, height } = useContainerDimensions(containerRef);
    const cardinal = curveCardinal.tension(1);

    const [graph, setGraph] = useState(0);
    const [series, setSeries] = useState(ohlc.open);

    function CustomTooltip({ payload, label, active}) {
        if (active && payload){
            return (
                <div>
                    <h6><b>{`${payload[0].payload.time}`}</b></h6>
                    <h6 style={{color: "#F95987"}}><b>{`${payload[0].payload.rate}`}</b></h6>
                </div>
            );
        }
        return null;
    }

    const wrapperStyle = { padding: "10px", backgroundColor: '#FFF', border: '1px solid #d5d5d5' };

    useEffect(() => {
        switch (graph) {
            case 0:
                setMax(ohlc.maxOpen);
                setMin(ohlc.minOpen);
                setSeries(ohlc.open);
                break;
            case 1:
                setMax(ohlc.maxHigh);
                setMin(ohlc.minHigh);
                setSeries(ohlc.high);
                break;
            case 2:
                setMax(ohlc.maxLow);
                setMin(ohlc.minLow);
                setSeries(ohlc.low);
                break;
            case 3:
                setMax(ohlc.maxClose);
                setMin(ohlc.minClose);
                setSeries(ohlc.close);
                break;
        }
    }, [graph, ohlc])

    const tabs = [
        {
            name: 'Open'
        },
        {
            name: 'High'
        },
        {
            name: 'Low'
        },
        {
            name: 'Close'
        }
    ];

    return (<>
                {ohlcRequestError &&
                    (<div className="calculations__display">
                        <span className="calculations__display__error">{ohlcRequestError.message}</span>
                    </div>)
                }

                {!ohlcRequestError && !ohlcLoading &&
                (<div className="account-page__controls web-action-buttons" style={{marginTop: "10px"}}>
                    <div className="nice-select open" tabIndex="0">
                        <span
                            className="current"
                            onClick={() => (setExportToCsv(true))}
                            style={{cursor: "pointer"}}
                        >
                            Export to CSV
                        </span>
                    </div>

                    {ohlcRequestError && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#F95987", fontWeight: "bold"}}>{ohlcRequestError}</h2> )}
                    {isCsvDownloadFinished && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#6fdeaf", fontWeight: "bold"}}>Your CSV download is complete.</h2> )}
                </div>)}

                {!ohlcRequestError && (ohlc.numberOfSamples > 0) && !ohlcLoading && (
                <div className="form-tabs" >
                    <div className="form-tabs__header">
                        {tabs ? tabs.map((item, index) =>
                            <div key={index}
                                className={cn('calculations_tabs__spot form-tabs__switch', {'form-tabs__switch--active': index === graph})}
                                onClick={() => 
                                {
                                    setGraph(index);
                                }
                                }>
                                {item.name}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-tabs__content">
                        {tabs ? tabs.map((item, index) =>
                            <div key={index}
                                className={cn('form-tabs__item', {'form-tabs__item--active': index === graph})}>
                            </div>
                        ) : null}
                    </div>
                </div>)}

                {!ohlcLoading? (
                <div className="exchange-dashboard__chart" ref={containerRef}>
                    <AreaChart width={width} height={height} data={series}
                            margin={{top: 50, right: 0, left: 10, bottom: 10}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F95987" stopOpacity={0.3}/>
                                <stop offset="95%" stopColor="#F95987" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#eee"/>
                        <XAxis dataKey="time" minTickGap={15} padding={window.innerWidth < 767 ? { right: 50 } : {}} />
                        <YAxis dataKey="rate" orientation="right" hide={window.innerWidth < 767}
                            domain={[min, max]}
                        />
                        <Tooltip wrapperStyle={wrapperStyle} content={<CustomTooltip />}/>
                        <Area type={cardinal} dataKey="rate" stroke="#F95987" strokeWidth={4} fill="url(#colorUv)"/>
                        
                    </AreaChart>
                </div> ) : (<div className="rates-loader"/>)}

                <div className="calculations__display">
        {!ohlcRequestError && (ohlc.numberOfSamples < 100) && (ohlc.numberOfSamples > 0) && !ohlcLoading &&
            (<>
                {/* <p><span className="calculations__display__title">Implementation Shortfall (NOT FINISHED):</span>{implementationShortfall}</p> */}
                <table className="calculations__table account-page__table">
                    <thead>
                    <tr>
                        <th>
                            <div>
                                <span>Timestamp</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Open</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>High</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Low</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Close</span>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {ohlc.series.map((fixing, index) => {
                        return (
                            <tr key={index}>
                                <td>{fixing.time}</td>
                                <td>{fixing.open}</td>
                                <td>{fixing.high}</td>
                                <td>{fixing.low}</td>
                                <td>{fixing.close}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
                </>
                )
            }
        </div>


            </>
        )
}

export default OhlcDisplay;