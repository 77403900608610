import React, { Component } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import "./ChangePassword.css";
import AuthenticationService from "../service/AuthenticationService";
import { Button, Form } from "react-bootstrap";
import * as QueryString from "query-string";
import BG from "../assets/img/BG.png";
import $ from "jquery";
import { ReactSVG } from "react-svg";
import closeIcon from "../assets/img/close-eye-icon.svg";
import openIcon from "../assets/img/eye-icon.svg";
import { showNotification } from "../service/index";

class ChangePasswordFromReset extends Component {
  constructor(props) {
    super(props);
    const params = QueryString.parse(this.props.location.search);
    this.state = {
      passwordCheck: "",
      password: "",
      tempPass: params.tempPass,
      token: params.token,
      hasLoginFailed: false,
      showSuccessMessage: false,
      errorMessage: "",
      isOldPassVisible: false,
      oldPassType: "password",
      isPassVisible: false,
      passType: "password",
      isNewPassVisible: false,
      newPassType: "password",
      passwordStrength: 0,
      submitDisabled: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
  }

  showPass = () => {
    this.setState({
      isPassVisible: !this.state.isPassVisible,
      passType: this.state.isPassVisible ? "password" : "text",
    });
  };

  showNewPass = () => {
    this.setState({
      isNewPassVisible: !this.state.isNewPassVisible,
      newPassType: this.state.isNewPassVisible ? "password" : "text",
    });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  loginClicked() {

   if (!this.state.password) {
      const message = "Please choose a password!";
      this.setState({ hasLoginFailed: true });
      this.setState({ errorMessage: message });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (this.state.password.length > 12) {
      const message = "The password has a max limit 12 characters!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message,
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (this.state.passwordStrength < 2) {
      const message = "Please choose a strong password!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message,
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (!this.state.passwordCheck) {
      const message = "Please choose a password confirmation!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message,
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    } else if (this.state.password !== this.state.passwordCheck) {
      const message = "Password confirmation is not same as password!";
      this.setState({ hasLoginFailed: true });
      this.setState({
        errorMessage: message
      });
      showNotification({ type: "error", title: "Password Updated", message: message});
      return;
    }

    this.setState({ submitDisabled: true });
    
    setTimeout(()=>{
        this.setState({ submitDisabled: false });
    }, 5000)

    AuthenticationService.executePasswordChangeServiceFromReset(
      this.state.password,
      this.state.token,
    )
      .then((response) => {
        if (response.data == "Password has been changed"){
        this.setState({ showSuccessMessage: true });
        this.setState({ hasLoginFailed: false });
        //showNotification({ type: "success", title: "Password Updated", message: "Your password has updated successfully. You can now login."});
        }
        else {
          this.setState({ errorMessage: response.data });
          this.setState({ showSuccessMessage: false });
          this.setState({ hasLoginFailed: true });
          showNotification({ type: "error", title: "Password Updated", message: response.data});
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: "NCFX was unable to send an email to your address. Please contact info@newchangefx.com." });
        this.setState({ showSuccessMessage: false });
        this.setState({ hasLoginFailed: true });
      });
  }

  componentDidMount() {
    // AuthenticationService.checkExpiredPassword(
    //   this.state.password,
    //   this.state.passwordCheck,
    //   this.state.tempPass,
    //   this.state.userId
    // )
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     this.setState({ errorMessage: error.response.data.message });
    //     this.setState({ showSuccessMessage: false });
    //     this.setState({ hasLoginFailed: true });
    //   });

    // const mainHeader = $(".header");

    // $(".field-item input").change(function () {
    //   if ($(this).val().length !== 0) {
    //     $(this).parent().addClass("filled");
    //   } else {
    //     $(this).parent().removeClass("filled");
    //   }
    // });

    // $(window).scroll(function () {
    //   if (mainHeader) {
    //     if ($(window).scrollTop() > 50) {
    //       mainHeader.addClass("fixed");
    //     } else {
    //       mainHeader.removeClass("fixed");
    //     }
    //   }
    // });
  }

  render() {
    return (
      <main className="main home-page">
        <div className="home-page__left">
          <img src={BG} alt="" />
        </div>
        <div className="home-page__right">
          <div className="home-page__forms">
            <div className="form-item form-item_reset-pass form-item_active">
            {this.state.showSuccessMessage ? (
              <>
                  <h3>Password Change Successful</h3>
                  <h5>Your password has been changed successfully, you may now login to the New Change FX Client Portal.</h5>
              </>                
              ) : (
              <>
                <h3>Create your new password</h3>
                {this.state.hasLoginFailed && (
                  <span>{this.state.errorMessage}</span>
                )}
                {this.state.showSuccessMessage && (
                  <div className="success">
                    <span>
                      Password creation successful!
                    </span>
                  </div>
                )}
                <form className="form-item__form">
                  <label className="field-item field-item_password">
                    <Form.Control
                      type={this.state.passType}
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                    <span className="field-item__title">New password</span>
                    <span
                      className="field-item__icon field-item__icon_eye"
                      onClick={this.showPass}
                    >
                      {this.state.isPassVisible ? (
                        <ReactSVG src={closeIcon} />
                      ) : (
                        <ReactSVG src={openIcon} />
                      )}
                    </span>
                  </label>
                  <label className="field-item field-item_password">
                    <Form.Control
                      type={this.state.newPassType}
                      name="passwordCheck"
                      value={this.state.passwordCheck}
                      onChange={this.handleChange}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                    <span className="field-item__title">
                      New password confirmation
                    </span>
                    <span
                      className="field-item__icon field-item__icon_eye"
                      onClick={this.showNewPass}
                    >
                      {this.state.isNewPassVisible ? (
                        <ReactSVG src={closeIcon} />
                      ) : (
                        <ReactSVG src={openIcon} />
                      )}
                    </span>
                  </label>
                  {this.state.password && this.state.password !== "" ? (
                    <PasswordStrengthBar
                      password={this.state.password}
                      onChangeScore={(score) => {
                        this.setState({
                          passwordStrength: score,
                        });
                      }}
                    />
                  ) : null}
                  <Button
                    variant="btn btn-success"
                    className="form-item__submit"
                    onClick={this.loginClicked}
                    disabled={this.state.submitDisabled}
                  >
                    Create new password
                  </Button>
                </form>
              </>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ChangePasswordFromReset;
