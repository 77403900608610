import React, {useState} from 'react';

import {connect} from "react-redux";
import {selectChartCurrency} from "../../../../redux/exchange/exchange-selector";

import TimePopular from "./TimePopular";
import TimeSelect from "./TimeSelect";

const TimeChoosing = ({timeList, timeValue, setTimeValue, chartCurrency}) => {

    return (
        <div className='exchange-dashboard__time exchange-time'>
            <TimePopular chooseTime={setTimeValue}
                         timeValue={timeValue}
                         timeList={timeList}
                         chartCurrency={chartCurrency}/>
            <TimeSelect chooseTime={setTimeValue}
                        timeValue={timeValue}
                        timeList={timeList}
                        chartCurrency={chartCurrency}/>
        </div>
    )
};

const mapStateToProps = state => ({
    chartCurrency: selectChartCurrency(state)
});

export default connect(mapStateToProps, {})(TimeChoosing);