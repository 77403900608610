const tenors = [
    {value: "SN", label: "Spot Next"},
    {value: "01W", label: "1 Week"},
    {value: "02W", label: "2 Week"},
    {value: "01M", label: "1 Month"},
    {value: "02M", label: "2 Month"},
    {value: "03M", label: "3 Month"},
    {value: "04M", label: "4 Month"},
    {value: "05M", label: "5 Month"},
    {value: "06M", label: "6 Month"},
    {value: "07M", label: "7 Month"},
    {value: "08M", label: "8 Month"},
    {value: "09M", label: "9 Month"},
    {value: "10M", label: "10 Month"}
];
export default tenors;