import React, {useState} from 'react';
import PriceImpactContainer from "./PriceImpact/PriceImpactContainer";
import TcaContainer from './TCA/TcaContainer';
import DecayContainer from './Decay/DecayContainer';
import ImplementationShortfallContainer from './ImplementationShortfall/ImplementationShortfallContainer';
import cn from "classnames";


const Calculations = props => {

    const {currencyPairs, currentCalculation, setCurrentCalculation} = props;

    const tabs = [
        {
            name: 'TCA',
            component: <TcaContainer currencyPairs={currencyPairs}/>
        },
        {
            name: 'Decay',
            component: <DecayContainer currencyPairs={currencyPairs}/>
        },
        {
            name: 'Price Impact',
            component: <PriceImpactContainer currencyPairs={currencyPairs}/>
        },
        {
            name: 'Implementation Shortfall',
            component: <ImplementationShortfallContainer currencyPairs={currencyPairs}/>
        }
    ];

    return (
        <main className="main exchange-page">
            <div className="container">
                <div className="exchange-page__top exchange-page-top">
                    <div className="exchange-page-top__left">
                        <h1>
                            <span>Calculations</span>
                        </h1>
                    </div>
                </div>
                <div className="calculations">
                    <div className="form-tabs" >
                        <div className="form-tabs__header">
                            {tabs ? tabs.map((item, index) =>
                                <div key={index}
                                    className={cn('form-tabs__switch', {'form-tabs__switch--active': index === currentCalculation})}
                                    onClick={() => 
                                    {
                                        setCurrentCalculation(index);
                                    }
                                    }>
                                    {item.name}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-tabs__content">
                            {tabs ? tabs.map((item, index) =>
                                <div key={index}
                                    className={cn('form-tabs__item', {'form-tabs__item--active': index === currentCalculation})}>
                                    {item.component}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default Calculations;