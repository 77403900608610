import React from 'react';
import { FileX } from 'react-bootstrap-icons';

import BG from "../assets/img/BG.png";
import tick from "../assets/img/tick.svg";
import homescreenshot from "../assets/img/homescreenshot.png";
import dvncscreenshot from "../assets/img/dvncscreenshot.png";
import itcscreenshot from "../assets/img/itcscreenshot.PNG";
import sirenscreenshot from "../assets/img/sirenscreenshot.png";

const LeftSide = () => {
    const ua = navigator.userAgent.toLowerCase(); 
    var isSafari = window.safari !== undefined;
    return (
        <div className="home-page__left">
            <div className="home-page__left__background-image" />
            <div className="home-page__left__product-info">
                <h3 className="home-page__left__product-info__title">Register for free access to our Client Portal</h3>
                <div className="home-page__left__product-info__info-box">
                    <img src ={tick} className="home-page__left__product-info__info-box__tick"/>
                    <ul className="home-page__left__product-info__info-box__bullets">
                        <li>- Live FX data and charts</li>
                        <li>- Public Benchmarks</li>
                        <li>- NCFX 'Instant Tradecheck' tool</li>
                    </ul>
                </div>

                <h3 className="home-page__left__product-info__title">+ 30 day trial access to the NCFX API suite*</h3>
                <div className="home-page__left__product-info__info-box">
                    <img src ={tick} className="home-page__left__product-info__info-box__tick"/>
                    <ul className="home-page__left__product-info__info-box__bullets">
                        <li>- Historic Spot, Forward and NDF data </li>
                        <li>- Series, OHLC and TWAP data requests</li>
                        <li>- Temporary and Permanent Price Impact, and Decay</li>
                        <li>- Advanced TCA calculations</li>
                    </ul>
                </div>

                {!isSafari && (<div style={{marginLeft: '50px', marginTop: '20px'}}>
                    <div className="home-page__left__product-info__info-box">
                        <img className="home-page__left__product-info__info-box__computer-screenshot__top home-page__left__product-info__info-box__computer-screenshot" src={dvncscreenshot}></img>
                        <img className="home-page__left__product-info__info-box__phone-screenshot" src={sirenscreenshot}></img>
                    </div>

                    <div className="home-page__left__product-info__info-box">
                        <img className="home-page__left__product-info__info-box__phone-screenshot__bottom home-page__left__product-info__info-box__phone-screenshot" src={itcscreenshot}></img>
                        <img className="home-page__left__product-info__info-box__computer-screenshot__bottom home-page__left__product-info__info-box__computer-screenshot" src={homescreenshot}></img>
                    </div>
                </div>)}
                <p className="home-page__left__product-info__disclaimer">* Trial access where granted is offered subject to the approval and at the sole discretion of NCFX</p>
            </div>
        </div>
    )
}

export default LeftSide;