import React, {useState} from 'react';
import {connect} from "react-redux";
import TimeSeriesContainer from "../TimeSeries/TimeSeriesContainer";
import LastRateContainer from "../LastRate/LastRateContainer";
import TwapContainer from "../TWAP/TwapContainer";
import OhlcContainer from "../OHLC/OhlcContainer";
import cn from "classnames";
import {setDataView} from "../../../redux/data/reducer.js";

const ForwardMenu = props => {

    const {currencyPairs, dataView, setDataView} = props;

    const tabs = [
        {
            name: 'Last Rate',
            component: <LastRateContainer currencyPairs={currencyPairs}/>
        },
        {
            name: 'Time Series',
            component: <TimeSeriesContainer currencyPairs={currencyPairs}/>
        }
    ];


    return (

        <div className="form-tabs" >
            <div className="calculations_tabs__forward-menu form-tabs__header">
                {tabs ? tabs.map((item, index) =>
                    <div key={index}
                        className={cn('calculations_tabs__forward form-tabs__switch', {'form-tabs__switch--active': index === dataView})}
                        onClick={() => 
                        {
                            setDataView(index);
                        }
                        }>
                        {item.name}
                    </div>
                ) : null}
            </div>
            <div className="form-tabs__content">
                {tabs ? tabs.map((item, index) =>
                    <div key={index}
                        className={cn('form-tabs__item', {'form-tabs__item--active': index === dataView})}>
                        {item.component}
                    </div>
                ) : null}
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    dataView: state.data.dataView
});

export default connect(mapStateToProps,{setDataView})(ForwardMenu);