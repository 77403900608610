import React from 'react';

const LastRateDisplay = ({lastRate, lastRateRequestError}) => {

    return (
    <>
        {lastRateRequestError &&
                    (<div className="calculations__display">
                        <span className="calculations__display__error">{lastRateRequestError.message}</span>
                        </div>)
        }
        
        {!lastRateRequestError && (lastRate.length > 0) &&
            (<>
                <table className="calculations__table account-page__table">
                    <thead>
                    <tr>
                        <th>
                            <div>
                                <span>Timestamp</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Rate</span>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                        {lastRate.map((fixing, index) => {
                            return (
                                <tr key={index}>
                                    <td>{fixing.time}</td>
                                    <td>{fixing.rate}</td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </>)
        }
    </>)      
}

export default LastRateDisplay;