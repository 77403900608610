import React, { Component } from "react";
import { connect } from "react-redux";
import {Link, withRouter} from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { Datatable } from "@o2xp/react-datatable";
import "./Users.css";

import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import history from "./../../history";

import * as actions from "../../redux/actions";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      name: "",
      fullObjectMap: [],
      options: "",
    };
  }

  _doFullEdit(event) {
    history.push("/EditUser?id=" + event.id);
  }

  componentDidMount() {
    this.props.fetchUsersRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props.usersList !== prevProps.usersList) {
      this.setOptions(this.props.usersList);
    }
  }

  setOptions(users) {
    let options = {
      actions: {},
      dimensions: {
        datatable: {
          width: "100%",
          height: "500px",
        },
      },
      keyColumn: "id",
      data: {
        columns: [
          {
            id: "id",
            label: "id",
            colSize: "10px",
            editable: false,
          },
          {
            id: "username",
            label: "userName",
            colSize: "30px",
            editable: false,
          },
          {
            id: "password",
            label: "password",
            colSize: "100px",
            editable: false,
          },
          {
            id: "fullName",
            label: "fullName",
            colSize: "150px",
            editable: true,
          },
          {
            id: "email",
            label: "email",
            colSize: "50px",
            editable: false,
          },
          {
            id: "jobTitle",
            label: "title",
            colSize: "50px",
            editable: true,
          },
          {
            id: "approved",
            label: "approved",
            colSize: "10px",
            dataType: "boolean",
            editable: false,
          },
          {
            id: "approverName",
            label: "approvedBy",
            colSize: "10px",
            editable: false,
          },
          {
            id: "tempPassExpDate",
            label: "tempPassExpDate",
            colSize: "50px",
            dataType: "date",
            editable: false,
          },
        ],
        rows: users,
      },
      features: {
        canEdit: true,
        canDelete: true,
        additionalActions: [
          {
            title: "Full Edit",
            icon: <EditAttributesIcon />,
            onClick: this._doFullEdit,
          },
        ],
      },
    };

    this.setState({ options: options });
  }

  _getEditUserBody = (rawUserBody) => {
    let selectedRoles = [];
    if (rawUserBody.roles && rawUserBody.roles.length > 0) {
      rawUserBody.roles.forEach((role) => {
        selectedRoles.push(role.id);
      });
    }

    let body = {
      id: rawUserBody.id || "",
      fullName: rawUserBody.fullName || "",
      loginName: rawUserBody.username || "",
      password: rawUserBody.password || "",
      emailAddress: rawUserBody.email || "",
      title: rawUserBody.jobTitle || "",
      selectedRoles: selectedRoles,
      admin: rawUserBody.admin,
      approved: rawUserBody.approved,
      approvedBy: rawUserBody.approvedBy,
      approverName: rawUserBody.approverName,
      organization: rawUserBody.organization,
    };

    return body;
  };

  actionsRow = ({ type, payload }) => {
    if (type === "save") {
      let editUserBody = this._getEditUserBody(payload);
      this.props.updateUsersRequest(editUserBody);
    } else if (type === "delete") {
      this.props.deleteUsersRequest({ id: payload.id });
    }
  };

  render() {
    if (Object.keys(this.state.options).length === 0) {
      return [
        <div key="1" className="Users">
          <div className="lander">Loading Users...</div>
        </div>,
      ];
    } else {
      return [
        <div key="2" className="Users">
          <div className="lander">
            <div className="users-category">
              <form>
                <span className="current">Users</span>
              </form>
            </div>
            <div>
              <Link
                to="/InsertUser"
              >
                <PersonAddIcon /> Insert New User
              </Link>
            </div>
            <div>
              <Datatable
                options={this.state.options}
                actions={this.actionsRow}
              />
            </div>
          </div>
        </div>,
      ];
    }
    // return [
    //   <div key="3" className="Users">
    //     <div className="lander">
    //       if (Object.keys(this.state.options).length == 0){" "}
    //       {<Datatable options={this.state.options} actions={this.actionsRow} />}
    //     </div>
    //   </div>,
    // ];
  }
}

const mapStateToProps = (state) => ({
  usersList: state.users.users.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersRequest: (data) => dispatch(actions.fetchUsersRequest(data)),
  updateUsersRequest: (data) => dispatch(actions.updateUsersRequest(data)),
  deleteUsersRequest: (data) => dispatch(actions.deleteUsersRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
