import React from 'react';
import {Link} from "react-router-dom";

import './TextPage.scss';

export default props => {

    const {inPageText, setOpenedTermsAndConditions} = props;
    window.scrollTo(0, 0)
    return(
        <main className="main text-page page">
            <div className="container">
                { inPageText ?
                (<button className="text-page__back" style={{border:'none', backgroundColor: 'transparent'}} onClick={() => {setOpenedTermsAndConditions(false)}}>Back to Registration</button>):
                (<Link className="text-page__back" to="/Home">Back</Link>)
                }
                <h3>
                    Terms & Conditions
                </h3>
                <ol>
                    <li>
                        <p>
                            <span className="bold">Disclaimer</span> 
                        </p>
                        <p><span className="bold">
                            The Service is provided for your general information only.
                            In particular, the Service does not constitute any form of advice, recommendation, representation,
                            endorsement or arrangement by us. It is not intended to be and should not be relied upon by users
                            in making (or refraining from making) any specific investment, purchase, sale or other decisions.
                            Appropriate independent advice should always be obtained before making any such decision, such as
                            from a qualified financial adviser, lawyer or accountant. Save as explicitly set out elsewhere in
                            these terms, by viewing any material or using the information within this portal or the website
                            you agree that you will not hold us or anybody liable for loss or damages resulting from the
                            information provided in this portal or the website. 
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="bold">Who we are.</span> 
                        </p>
                        <p>
                            The NCFX client portal, access via the website
                            <a href="https://www.newchangefx.com" className="text-page__inline"> www.newchangefx.com/ </a>
                             (the "Portal") is operated by and is a trading name of New Change Currency Consultants Limited ("NCFX", "we", "us" and "our"). 
                            We are a limited company registered in England and Wales under company number 08069933 and our registered
                            office is at 8th Floor, 71 Queen Victoria Street, London,  EC4V 4AY. Our registered VAT number
                            is 193736180. To contact us, please email us at 
                            <a href="mailto:info@newchangefx.com" className="text-page__inline"> info@newchangefx.com </a> 
                            or telephone us on +44
                            (0) 7826 866 414 during office hours (Monday – Friday: 09:30 – 17:30).
                        </p>
                    </li>
                    <li>
                        <p><span className="bold">By using the Portal you accept these Terms.</span></p>
                        <ol>
                            <li>
                                <p>
                                    These are the terms and conditions on which you are able to subscribe to access the NCFX client portal
                                    (the "Service") and the digital content we make available to you via the Service.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Please read these terms carefully before you register for our Service. These terms tell you who we are, how we will provide
                                    our digital content to you, how you and we may change or end the contract, what to do if there is a problem and other
                                    important information. If you think that there is a mistake in these terms, please contact us at
                                    <a href="mailto:info@newchangefx.com" className="text-page__inline"> info@newchangefx.com</a> 
                                    .
                                </p>
                            </li>
                            <li>
                                <p>
                                    By registering to use our Service, you indicate that you accept these Terms, and that you agree to abide by them and
                                    that you are entering into a legally binding contract with us (the "contract"). You can terminate this Contract at an
                                    time by no longer accessing or using the Platform and, if applicable, closing your account. If you do not agree to any
                                    of these terms, please refrain from using the Portal immediately.
                                </p>
                            </li>
                            <li>
                                <p>
                                    "Writing" includes emails. When we use the words "writing" or "written" in these terms, this includes emails and any
                                    notification via the Portal or our website.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <span className="bold">Are you a business customer or a consumer?</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    In some areas you will have different rights under these terms depending on whether you are a business or consumer.
                                    <br/>You are a "Consumer" if:
                                </p>
                                <ul>
                                    <li className = "text-page__bulletlist">
                                        <p>You are an individual; and</p>
                                    </li>
                                    <li className = "text-page__bulletlist">
                                        <p>You are subscribing to the Service wholly or mainly for your personal use (not for use in connection 
                                            with your   trade, business, craft or profession).</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Provisions specific to consumers only are in red. 
                                </p>
                            </li>
                            <li>
                                <p>
                                    If you are a business customer that has not entered into full commercial terms with NCFX, this is our entire agreement
                                     with you ("New Business Customer"). You acknowledge that you have not relied on any statement, promise, representation,
                                      assurance or warranty made or given by or on behalf of us which is not set out in these terms and that you shall have
                                       no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this agreement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    If you are a business customer that has previously entered into full commercial terms with NCFX ("Current Business Customer"),
                                     these terms, together with the full commercial agreement we have entered into with you, constitute the entire agreement between
                                      us in relation to your use of the Service. In the event of any conflict between these terms, and the terms of our commercial
                                       agreement with you, the latter shall prevail. You acknowledge that you have not relied on any statement, promise, representation,
                                        assurance or warranty made or given by or on behalf of us which is not set out in these terms and that you shall have no claim
                                         for innocent or negligent misrepresentation or negligent misstatement based on any statement in this agreement. 
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Eligibility</span></p>
                        <ol>
                            <li>
                                <p>
                                In order to access or use the Service, you agree and understand that the Service and the contents of it are intended for people who are
                                 18 years of age or above and you hereby represent that you are 18 years of age or above.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <span className="bold">Availability of the Platform</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    The Platform is made available free of charge.
                                </p>
                            </li>
                            <li>
                                <p>
                                    We do not guarantee that the Platform, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or
                                     restrict the availability of all or any part of the Platform for business and operational reasons. We will try to give you reasonable notice
                                      of any suspension or withdrawal.
                                </p>
                            </li>
                            <li>
                                <p>
                                    From time to time, we may restrict access to some parts or all of the Platform – see Section 7.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Your Subscription</span></p>
                        <ol>
                            <li>
                                <p>
                                Contract formation. In order to access the Platform you are required to subscribe for an Account.
                                 We will try to process your subscription promptly but we do not guarantee that your subscription
                                  will be activated by any specified time. We reserve the right to reject any registration at our
                                   discretion, for any or no reason. 
                                </p>
                            </li>
                            <li>
                                <p>
                                Registration. By subscribing, you agree that you have given us accurate registration details,
                                 and will ensure that we always have up to date contact information. If we have to contact you
                                  we will do so by telephone or by writing to you at the email address or postal address you
                                   provided to us when you registered with us. 
                                </p>
                            </li>
                            <li>
                                <p>
                                    Account details. By subscribing, you agree that you will keep your user name and/or password
                                     confidential and will not share this information with any third party. You are responsible
                                      for restricting access to your computer and devices, and to the extent permitted by applicable
                                       laws, you agree to accept responsibility for all activities that occur under your account or password.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Unauthorised access. You should take all necessary steps to ensure that your account password are kept
                                     confidential and secure and you should inform us immediately if you have any reason to believe that your
                                      password has become known to anyone else, or if the password is being used or is likely to be used in
                                       an unauthorised manner.
                                </p>
                            </li>
                            <li>
                                <p>
                                Suspension. We may suspend or terminate your subscription, without any prior notice to you, upon receipt of
                                 any notice or communication from any user or any governmental authority, regulatory agency or any other person
                                  or in case in our opinion you have failed to comply with any of the provisions of these Terms or any other
                                   provision of applicable laws or regulations and standards prescribed in connection with the use of the Service
                                    or as we may decide (acting reasonably) in order to protect us, the Service and its content and/or any other
                                     visitor or any other person.
                                </p>
                            </li>
                            <li>
                                <p>
                                Further, we may restrict or suspend your access to the Service and we may restrict, suspend or terminate you
                                 subscription, if we determine, in our sole opinion, that:
                                <ol>
                                    <li className="text-page__letteredlist">You have misrepresented any information you have provided to us;</li>
                                    <li className="text-page__letteredlist">You are ineligible to access the Service in accordance with applicable laws; and/or</li>
                                    <li className="text-page__letteredlist">You are in breach of any of the Terms.</li>
                                </ol>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">The Service</span></p>
                        <ol>
                            <li>
                                <p>
                                    The Service enables users to access the following content in the Platform:
                                 
                                <ol>
                                    <li className="text-page__letteredlist">content made generally available to all users ("Public Content");</li>
                                    <li className="text-page__letteredlist">content made available only to Current Business Customers, and to
                                     Consumers and New Business Customers for the first 4 weeks of their subscription ("Restricted Content");</li>
                                    <li className="text-page__letteredlist">paid-for content and services in the Platform accessible only to
                                     Current Business Customers ("Paid-For Content") </li>
                                </ol>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Consumers and New Business Customers. If you are a Consumer or New Business Customer:
                                 
                                <ol>
                                    <li className="text-page__letteredlist">by subscribing for the Service, you are entitled to 4 weeks' access
                                     to Public Content and Restricted Content. You are not entitled to access any Paid-For Content. After 4 weeks
                                      we will restrict your access to the Platform to Public Content only. New Business Customers may purchase
                                       access to Paid-For Content by entering into a separate commercial agreement with us.</li>
                                    <li className="text-page__letteredlist">if you do not access the Service for a period of 3 months, your account
                                     will be deactivated and your subscription terminated.</li>
                                     
                                </ol>
                                </p>
                            </li>
                            <li>
                                <p>
                                Current Business Customers. If you are a Current Business Customer you are entitled to access all content in the Platform
                                 (Public Content, Restricted Content and Paid-For Content), subject to the terms of our commercial agreement with you
                                  governing your access to Paid-For Content.
                                 </p>
                            </li>
                            <li>
                                <p>
                                    Updates. We may update the digital content provided in the Service to reflect changes to our services, our users'
                                     needs and   our business priorities. We will try to give you reasonable notice of any major changes. 
                                 </p>
                            </li>
                            <li>
                                <p>
                                We are not responsible for delays outside out control. We will not be held responsible for any delay or failure to
                                 comply with our obligations under these Terms if the delay or failure arises from any cause which is beyond our
                                  reasonable control. This condition does not affect your statutory rights.
                                 </p>
                            </li>
                            <li>
                                <p>
                                Restrictions on Use
                                 
                                 <ol>
                                    <li className="text-page__letteredlist">You may not use the Service for any illegal purpose, for the facilitation
                                     of any law or regulation, or in any manner inconsistent with these Terms. </li>
                                    <li className="text-page__letteredlist"> You agree not to resell any information contained in the Service in any manner.</li>
                                    <li className="text-page__letteredlist"> You agree not to copy, disseminate or otherwise distribute any information contained in the Service to any third party.</li>
                                </ol>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Linking / Third Party Websites</span></p>
                        <ol>
                            <li>
                                <p>
                                    You must not establish a link to the Portal, but you may link to our website 
                                    <a href="https://www.newchangefx.com" className="text-page__inline"> www.newchangefx.com </a> 
                                    provided you do so in accordance with the website's Terms of Use (
                                        <a href=" https://www.newchangefx.com/terms-of-use/" className="text-page__inline">https://www.newchangefx.com/terms-of-use/</a> 
                                    ). 
                                </p>
                            </li>
                            <li>
                                <p>
                                    You must not establish a link from any website that is not owned or operated by you.
                                </p>
                            </li>
                            <li>
                                <p>
                                    We reserve the right to withdraw linking permission without notice. 
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Intellectual Property Rights</span></p>
                        <ol>
                            <li>
                                <p>
                                All intellectual property rights in the Service including any text, graphics, information,
                                 motifs, logos, designs and databases contained in the Service including the domain names,
                                  organisation and layout of the information provided by the Service are owned by us or our
                                   licensors. No intellectual property rights in the Service are transferred to you.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Our rights to make changes to these Terms</span></p>
                        <ol>
                            <li>
                                <p>
                                    We reserve the right to make minor changes to these Terms from time to time as we see fit
                                     by posting an updated version on our website or on the Portal and your continued use of 
                                     the Service will signify your acceptance of any adjustment to these Terms. You should visit
                                      this page periodically to review the most current Terms as they are binding on you. All
                                       changes to these Terms shall be effective immediately upon being posted on the Portal or
                                        on our website.
                                </p>
                            </li>
                            <li>
                                <p>
                                    We reserve the right to make more significant changes to the Service and these Terms, but
                                     if we do so we will notify you before the changes take effect. 
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Your rights to end the contract</span></p>
                        <ol>
                            <li>
                                <p>
                                Ending your Subscription. You may end your subscription at any time. To end the contract with
                                 us, please let us know by emailing us at 
                                 <a href="mailto:info@newchangefx.com" className="text-page__inline"> info@newchangefx.com</a>
                                 , Please provide your username and password using the email linked to the account. 
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Our rights to end the contract</span></p>
                        <ol>
                            <li>
                                <p>
                                We may terminate this Contract at any time by providing you with written notice.  
                                </p>
                            </li>
                            <li>
                                <p>
                                Current Business Customers. For the avoidance of doubt, if you are a Current Business
                                 Customer, our respective rights of termination in respect of Paid-For Content are subject
                                  to the terms of our commercial agreement with you.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">If there is a problem with the Service</span></p>
                        <ol>
                            <li>
                                <p>
                            If you have any questions or complaints about the Service, please contact us. You can email us at 
                             <a href="mailto:info@newchangefx.com" className="text-page__inline"> info@newchangefx.com </a>
                              or telephone us on +44
                            (0) 7826 866 414 during office hours (Monday – Friday: 09:30 – 17:30)
                              </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Disclaimer</span></p>
                        <ol>
                            <li>
                                <p>
                                The Service is provided for your general information only. In particular, the Service does
                                 not constitute any form of advice, recommendation, representation, endorsement or arrangement
                                  by us. It is not intended to be and should not be relied upon by users in making (or refraining
                                   from making) any specific investment, purchase, sale or other decisions. Appropriate 
                                   independent advice should always be obtained before making any such decision, such as
                                    from a qualified financial adviser, lawyer or accountant. 
                                </p>
                            </li>
                            <li>
                                <p>
                                We make no representations, warranties or guarantees, whether express or implied, that the Service
                                 and its content are accurate, complete or up to date or suitable and accordingly should not be relied
                                  on as such. The Service is provided on an   "as is" and "as available" basis. This means that we do
                                   not make any promises in respect of the Services or the information and functions available through
                                    the Service, or of the quality, completeness or accuracy of the information published on the Service. 
                                </p>
                            </li>
                            <li>
                                <p>
                                By viewing any material or using the information within the Portal or this website you agree that you will
                                 not hold us or anybody liable for loss or damages resulting from the information provided in the Portal or this website.  
                                </p>
                            </li>
                            <li>
                                <p>
                                We do not warrant or represent that the Service shall be uninterrupted, error-free, or completely secure. You acknowledge 
                                that there are risks inherent in operating conditions, internet connectivity, third party software components and other 
                                reasons beyond our control that could result in the loss of content or unavailability of services or loss of any other 
                                information relating to activities carried out through the Service. 
                                </p>
                            </li>
                            <li>
                                <p>
                                We do not guarantee that the Service will be secure or free from bugs or viruses. You agree that material downloaded or 
                                otherwise accessed through the use of the Service is obtained entirely at your own risk and that you shall be entirely 
                                responsible for any resulting damage to software or computer systems and/or any resulting loss of data, even if such loss
                                 and damage was reasonably foreseeable and we had been advised of the possibility of the same.
                                </p>
                            </li>
                            <li>
                                <p>
                                We shall not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other 
                                technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary 
                                 material due to your use of the Service or to your downloading of any material posted on them, or on any website linked to
                                  them.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li className="text-page__redtext"><div className="text-page__redtext">
                        <p><span style={{color: "red"}} >Our responsibility for loss or damage suffered by you if you are a consumer </span></p>
                        <ol>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                If you are a consumer we are under a legal duty to supply products that are in conformity with this
                                 contract. For detailed information please visit the Citizens Advice website 
                                 <a href="https://www.adviceguide.org.uk" className="text-page__inline"> www.adviceguide.org.uk </a>
                                 or call 03454 04 05 06. 
                                </p>
                            </li>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                Our promises to you. If you are a consumer, we promise to operate the Service with reasonable care
                                 and skill and will use reasonable efforts to promptly remedy any faults of which we are aware.
                                  However, we do not make certain promises, as outlined in clause 15.2 above.
                                </p>
                            </li>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                We are not responsible for what you choose to do with the information contained in the Service.
                                 The information contained in the Service is for information purposes only and does not
                                  constitute advice. By providing the Service we do not offer any form of advice, recommendation,
                                   representation, endorsement or arrangement by us. You must always obtain appropriate independent
                                    advice should always be obtained before making any such decision, such as from a qualified
                                     financial adviser, lawyer or accountant. 
                                </p>
                            </li>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with
                                 these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our
                                  breaking this contract or our failing to use reasonable care and skill, but we are not responsible
                                   for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious
                                    that it will happen or if, at the time the contract was made, both we and you knew it might happen,
                                     for example, if you discussed it with us when purchasing your subscription. In no circumstances shall
                                      our liability exceed the amount, if any, paid by you to us for your subscription in any 6 month period. 
                                </p>
                            </li>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes
                                 liability for death or personal injury caused by our negligence or the negligence of our employees, agents
                                  or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in
                                   relation to the products as summarised at clause 16.1  
                                </p>
                            </li>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                When we are liable for damage caused by defective digital content. If defective digital content which we have
                                 supplied damages a device or digital content belonging to you and this is caused by our failure to use
                                  reasonable care and skill we will either repair the damage or pay you compensation. However, we will not
                                   be liable for damage which you could have avoided by following our advice to apply an update offered to
                                    you free of charge or to have in place the minimum system requirements advised by us.
                                </p>
                            </li>
                            <li className="text-page__redtext">
                                <p style={{color: "red"}}>
                                    We are not liable for business losses. If you are a consumer we only supply the products for to you for
                                     domestic and private use. If you use the products for any commercial, business or re-sale purpose our
                                      liability to you will be limited as set out in clause 17.
                                </p>
                            </li>
                        </ol>
                        </div>
                    </li>
                    <li>
                        <p><span className="bold">Our responsibility for loss or damage suffered by you if you are a business <u>(this clause does not apply to consumers) </u></span></p>
                        <ol>
                            <li>
                                <p>
                                Nothing in these terms shall limit or exclude our liability for:
                                <ol>
                                    <li className="text-page__letteredlist"> death or personal injury caused by our negligence, or the
                                     negligence of our employees, agents or subcontractors (as applicable);  </li>
                                    <li className="text-page__letteredlist"> fraud or fraudulent misrepresentation; or</li>
                                    <li className="text-page__letteredlist"> any other liability which cannot be excluded at law.</li>
                                </ol>
                                </p>
                            </li>
                            <li>
                                <p>
                                We exclude all implied conditions, terms, warranties, representations or other terms that may apply to the
                                 Platform or any content on it, save to the extent that such exclusion is prohibited by law.
                                </p>
                            </li>
                            <li>
                                <p>
                                We will not be liable to you for any loss or damage, whether in contract, tort (including negligence),
                                 breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                                 <ol>
                                    <li className="text-page__letteredlist"> use of, or inability to use, our site; or</li>
                                    <li className="text-page__letteredlist"> use of or reliance on any content displayed on our site.</li>
                                </ol>
                                </p>
                            </li>
                            <li>
                                <p>
                                In particular, we will not be liable for:
                                <ol>
                                    <li className="text-page__letteredlist"> loss of profits, sales, business, or revenue;</li>
                                    <li className="text-page__letteredlist"> business interruption;</li>
                                    <li className="text-page__letteredlist"> loss of anticipated savings;</li>
                                    <li className="text-page__letteredlist"> loss of business opportunity, goodwill or reputation; or</li>
                                    <li className="text-page__letteredlist"> any indirect or consequential loss or damage.</li>
                                </ol>
                                </p>
                            </li>
                            <li>
                                <p>
                                Current Business Customers. For the avoidance of doubt, if you are a Current Business Customer,
                                 our respective liability in respect of Paid-For Content shall be in accordance with the terms
                                  of our commercial agreement with you.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">How we may use your personal information</span></p>
                        <ol>
                            <li>
                                <p>
                                How we will use your personal information. We will only use your personal information as
                                 set out in our 
                             <a href="https://www.newchangefx.com/privacy-policy/" className="text-page__inline"> Privacy Policy</a>
                              .
                              </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p><span className="bold">Other important terms</span></p>
                        <ol>
                            <li>
                                <p>
                                You may not assign or transfer this contract (or your subscription) to anyone without
                                 our consent. However, you agree that we may assign this contract to our affiliates or
                                  a party that buys us without your consent.
                              </p>
                            </li>
                            <li>
                                <p>
                                This contract is between you and us. No other person shall have any rights to enforce
                                 any of its terms. Neither of us7 will need to get the agreement of any other person 
                                 in order to end the contract or make any changes to these terms.
                              </p>
                            </li>
                            <li>
                                <p>
                                If any of these Terms are deemed invalid or unenforceable for any reason, then the
                                 invalid or unenforceable provision will be severed from these Terms and the remaining
                                  Terms will continue to apply. 
                              </p>
                            </li>
                            <li>
                                <p>
                                Failure by us to enforce any of the provisions set out in these Terms, or failure to
                                 exercise any option to terminate, shall not be construed as waiver of such provisions
                                  and shall not affect the validity of these Terms or any part thereof, or the right 
                                  thereafter to enforce each and every provision. 
                              </p>
                            </li>
                            <li>
                                <p>
                                These Terms and Conditions are governed by and shall be construed in accordance with
                                 the laws of England and Wales and the parties submit to the exclusive jurisdiction
                                  of the Courts of England and Wales. 
                              </p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </main>
    )
};
