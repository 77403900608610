import React, {useEffect} from 'react';
import {connect} from "react-redux";

import {
    setDirection,
    setRatioData,
    setTimeData,
    getCurrencyPairs,
    getRates, setSortRates
} from "../redux/exchange/reducer";
import {
    selectCurrency, selectCurrencyPairs,
    selectDirection,
    selectRates, selectRatesLoader,
    selectRatioData,
    selectTimeList,
    selectCurrencyPairsLoaded,
    selectSortRates, selectInstantTradeCheck
} from "../redux/exchange/exchange-selector";
import {selectBasicAuth} from "../redux/select-app-reducer";

import ExchangePage from "./ExchangePage";


const ExchangeContainer = React.memo(({getRates, isCurrencyPairsLoaded, getCurrencyPairs, rates, basicAuth,
                                          sortRates, ...props}) => {
    useEffect(() => {
        getRates(['EURUSD',
            'GBPUSD',
            'USDCHF',
            'USDJPY',
            'AUDUSD',
            'EURGBP'], basicAuth);
    }, []);

    useEffect(() => {
        if (isCurrencyPairsLoaded) {
            const intervalId = setInterval(() => {
                getRates(['EURUSD',
                    'GBPUSD',
                    'USDCHF',
                    'USDJPY',
                    'AUDUSD',
                    'EURGBP'].concat(sortRates), basicAuth);
            }, 1000);

            return () => {
                clearInterval(intervalId);
            }
        }
    }, [getRates, isCurrencyPairsLoaded, props.currencyPairs.length, rates.length, sortRates]);

    useEffect(() => {
        getCurrencyPairs(basicAuth);
    }, [getCurrencyPairs]);

    return <ExchangePage {...props} rates={rates}/>;
});

const mapStateToProps = state => ({
    rates: selectRates(state),
    currency: selectCurrency(state),
    direction: selectDirection(state),
    ratioData: selectRatioData(state),
    timeList: selectTimeList(state),
    currencyPairs: selectCurrencyPairs(state),
    ratesLoader: selectRatesLoader(state),
    isCurrencyPairsLoaded: selectCurrencyPairsLoaded(state),
    sortRates: selectSortRates(state),
    login: state.auth.login,
    basicAuth: selectBasicAuth(state),
    instantTradeCheck: selectInstantTradeCheck(state)
});

export default connect(mapStateToProps, {
    setDirection, setRatioData, setTimeData,
    getCurrencyPairs, getRates, setSortRates
})(ExchangeContainer);