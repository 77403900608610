const SET_FINANCIAL_INSTRUMENT = 'data/SET_FINANCIAL_INSTRUMENT';
const SET_DATA_VIEW = 'data/SET_DATE_VIEW';

const initialState = {
    financialInstrument: 0,
    dataView: 0
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_INSTRUMENT:
        case SET_DATA_VIEW:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const setFinancialInstrument = financialInstrument => ({type: SET_FINANCIAL_INSTRUMENT, payload: {financialInstrument}});
export const setDataView = dataView => ({type: SET_DATA_VIEW, payload: {dataView}});

export default dataReducer;