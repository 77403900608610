import axios from "axios";
import AuthenticationService from "./AuthenticationService";
import header from 'basic-auth-header';
import { config } from "../constants";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export const fireApi = function (method, api_url, data, headers = {}, basicHeaders) {
  headers = {
    ...headers,
    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
    'Access-Control-Allow-Origin': '*',
    Accept: "application/json",
    "Content-Type": "application/json",
    withCredentials: true,
    "Cache-Control": "no-cache",
    mode: "no-cors",
    AuthorizationBackup: "Bearer " + sessionStorage.getItem('jwtToken'),
  };
  const base_url = config.url.API_URL;
  const url = base_url + api_url;
  if (method === "GET") {
    return axios.get(url, { headers });
  } else if (method === "POST") {
    return axios.post(url, data, { headers });
  } else if (method === "PUT") {
    return axios.put(url, data, { headers });
  } else if (method === "DELETE") {
    return axios.delete(url, { data, headers });
  }
};

export const fireApiBasic = function (method, api_url, data, headers = {}, username, password) {
  headers = {
    ...headers,
    Authorization: header(username,password),
    'Access-Control-Allow-Origin': '*',
    Accept: "application/json",
    "Content-Type": "application/json",
    withCredentials: true,
    "Cache-Control": "no-cache",
    mode: "no-cors",
    AuthorizationBackup: header(username,password)
  };
  const base_url = config.url.API_URL;
  const url = base_url + api_url;
  if (method === "GET") {
    return axios.get(url, { headers });
  } else if (method === "POST") {
    return axios.post(url, data, { headers });
  } else if (method === "PUT") {
    return axios.put(url, data, { headers });
  } else if (method === "DELETE") {
    return axios.delete(url, { data, headers });
  }
};

export const showNotification = ({ type, title, message }) => {
  let toastType = toast.TYPE.INFO;
  if (type === "success") {
    toastType = toast.TYPE.SUCCESS;
  } else if (type === "error") {
    toastType = toast.TYPE.ERROR;
  } else if (type === "warning") {
    toastType = toast.TYPE.WARNING;
  }
  let options = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    type: toastType,
  };
  toast(message, options);
};
