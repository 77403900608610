const SET_LOADED = 'app/SET_LOADED';
const SET_SERVER_ERROR = 'app/SET_SERVER_ERROR';
const SET_BASIC_AUTH = 'app/SET_BASIC_AUTH';
const SET_IS_USER_LOGGED_IN = 'app/SET_IS_USER_LOGGED_IN';

const initialState = {
    loading: true,
    siteMenu: [
    ],
    token: '',
    serverError: '',
    basicAuth: null,
    isUserLoggedIn: !!sessionStorage.getItem('loggedIn')
};

const appReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_LOADED:
            return {...state, loading: false};
        case SET_SERVER_ERROR:
            return {...state, serverError: action.serverError};
        case SET_BASIC_AUTH:
            return {...state, basicAuth: action.basicAuth};
        case SET_IS_USER_LOGGED_IN:
            return {...state, isUserLoggedIn: action.isUserLoggedIn};
        default:
            return state;
    }
};

export const setLoaded = () => ({type: SET_LOADED});
export const setServerError = serverError => ({type: SET_SERVER_ERROR, serverError});
export const setBasicAuth = basicAuth => ({type: SET_BASIC_AUTH, basicAuth});
export const setUserIsLoggedIn = isUserLoggedIn => ({type: SET_IS_USER_LOGGED_IN, isUserLoggedIn});

export default appReducer;