import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
import auth from "./auth/reducer";
import organization from "./organization/reducer";
import roles from "./roles/reducer";
import users from "./users/reducer";
import siren from "./siren/reducer";
import dvnc from "./dvnc/reducer";
import dubaigold from "./dubaigold/reducer";
import exchange from "./exchange/reducer";
import data from "./data/reducer";
import spot from "./data/spot/reducer";
import forward from "./data/forward/reducer";
import calculations from "./calculations/reducer";
import appReducer from "./app-reducer";

const reducers = combineReducers({
  auth,
  organization,
  roles,
  users,
  siren,
  dvnc,
  dubaigold,
  exchange,
  appReducer,
  data,
  spot,
  forward,
  calculations,
  form: formReducer
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

export default rootReducer;
