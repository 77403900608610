import React, {useState} from 'react';


const PriceImpactDisplay = props => {
    const {priceImpact, priceImpactError} = props;
    
    return (
        <div className="calculations__display">
            {priceImpactError &&
                (<><span className="calculations__display__error">{priceImpactError.message}</span></>)}

            {priceImpact.length > 0 &&
                (<>
                    <table className="calculations__table account-page__table">
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <span>Permanent Cost</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span>Temporary Cost</span>
                                </div>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                            {priceImpact.map((result, index) => (
                            <tr key={index}>
                                <td>${result.permanentUsdCost}</td>
                                <td>${result.temporaryUsdCost}</td>
                            </tr>))}
                        </tbody>
                    </table>
                </>)}
        </div>
        )
};

export default PriceImpactDisplay;