import moment from 'moment';
import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi } from "../../service";
import {setServerError} from "../app-reducer";

export function* fetchOptionFixingsRequest(action) {
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/gui/siren/findAllSirensByDate?ldt=` +
        moment(action.payload.ldt).format('ddd MMM DD YYYY')
        +
        "&time=" +
        action.payload.time,
        // {},
        // header
    );
    if (response) {
      yield put(actions.fetchOptionFixingsSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchOptionFixingsError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* fetchSirensRequest(action) {
  // const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/gui/siren/findAllSirensByDate?ldt=` +
        moment(action.payload.ldt).format('ddd MMM DD YYYY')
      //   action.payload.ldt +
        +
        "&time=" +
        action.payload.time,
        // {},
        // header
    );
    if (response) {
      yield put(actions.fetchSirensSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchSirensError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}