import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import CalculationsContainer from "./ApiCentre/Calculations/CalculationsContainer";
import DataContainer from "./ApiCentre/Data/DataContainer";
import ErrorPage from "./ErrorPage/ErrorPage";
import ExchangeContainer from "./ExchangePage/ExchangeContainer";
import LiveBenchmarkList from "./LiveBenchmark/LiveBenchmarkList";
import AuthenticatedRoute from "./Login/AuthenticatedRoute";
import ChangeExpiredPassword from "./Login/ChangeExpiredPassword";
import ChangePassword from "./Login/ChangePassword";
import ChangePasswordFromReset from "./Login/ChangePasswordFromReset";
import ForgotPassword from "./Login/ForgotPassword";
import Login from "./Login/Login";
import Logout from "./Login/Logout";
import Register from "./Login/Register";
import TermsAndConditions from "./Login/TermsAndConditions";
import TextPage from "./TextPage/TextPage";
import UnderConstructionPage from "./UnderConstructionPage/UnderConstructionPage";
import EditOrganization from "./admin/Users/EditOrganization";
import EditUser from "./admin/Users/EditUser";
import InsertOrganization from "./admin/Users/InsertOrganization";
import InsertRole from "./admin/Users/InsertRole";
import InsertUser from "./admin/Users/InsertUser";
import Organizations from "./admin/Users/Organizations";
import Roles from "./admin/Users/Roles";
import Users from "./admin/Users/Users";
import Settings from "./containers/settings/Settings";
import history from "./history";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <AuthenticatedRoute path="/" exact component={Login} />
        <AuthenticatedRoute path="/login" exact component={Login} />
        <AuthenticatedRoute
          path="/ChangePassword"
          exact
          component={ChangePassword}
        />
        <AuthenticatedRoute
          path="/ChangeExpiredPassword"
          exact
          component={ChangeExpiredPassword}
        />
        <AuthenticatedRoute
          path="/ChangePasswordFromReset"
          exact
          component={ChangePasswordFromReset}
        />
        <AuthenticatedRoute
          path="/TermsAndConditions"
          exact
          component={TermsAndConditions}
        />
        <AuthenticatedRoute path="/Home" exact component={ExchangeContainer} />
        <AuthenticatedRoute
          path="/LiveBenchmarkList"
          exact
          component={LiveBenchmarkList}
        />
        {/* <AuthenticatedRoute path="/DVNCCut" exact component={DVNCCut}/> */}
        {/* <AuthenticatedRoute path="/DubaiGold" exact component={DubaiGold} /> */}
        {/* <AuthenticatedRoute path="/Siren" exact component={Siren} /> */}
        <AuthenticatedRoute path="/Register" exact component={Register} />
        <AuthenticatedRoute
          path="/ForgotPassword"
          exact
          component={ForgotPassword}
        />

        <AuthenticatedRoute path="/Data" exact component={DataContainer} />
        <AuthenticatedRoute
          path="/Calculations"
          exact
          component={CalculationsContainer}
        />

        <AuthenticatedRoute path="/Users" exact component={Users} />
        <AuthenticatedRoute path="/Roles" exact component={Roles} />
        <AuthenticatedRoute
          path="/Organizations"
          exact
          component={Organizations}
        />
        <AuthenticatedRoute path="/InsertUser" exact component={InsertUser} />
        <AuthenticatedRoute path="/InsertRole" exact component={InsertRole} />
        <AuthenticatedRoute
          path="/InsertOrganization"
          exact
          component={InsertOrganization}
        />
        <AuthenticatedRoute
          path="/editOrganization"
          exact
          component={EditOrganization}
        />
        <AuthenticatedRoute path="/EditUser" exact component={EditUser} />
        <Route path="/Logout" exact component={Logout} />

        <AuthenticatedRoute
          path="/SettingsContactInfo"
          exact
          component={Settings}
        />
        <AuthenticatedRoute
          path="/SettingsUpdatePassword"
          exact
          component={Settings}
        />
        <Route path="/terms" exact component={TextPage} />
        <Route path="/error" exact component={ErrorPage} />
        <Route
          path="/under-construction"
          exact
          component={UnderConstructionPage}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
