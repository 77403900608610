import React from "react";
import "./Logout.css";

const Logout = () => {
  return (
      <div className="Logout">
        <div className="lander">Thank You for Using Our Application.</div>
      </div>
  );
}

export default Logout;
