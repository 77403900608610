import React, {useRef, useState, useEffect} from 'react';
import { curveCardinal } from 'd3-shape';
import {AreaChart, Brush, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import AuthenticationService from "../../../service/AuthenticationService";
import {useContainerDimensions} from '../../../utils/helpers';

const Chart = ({data, chartExtraData}) => {
    const containerRef = useRef();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000);
    const { width, height } = useContainerDimensions(containerRef);
    const cardinal = curveCardinal.tension(1);
    const isUserAllowedData = AuthenticationService.isUserAllowedData();

    useEffect(() => {
        if(chartExtraData) {
            // const minDiff = chartExtraData.max - chartExtraData.min;
            // const maxDiff = chartExtraData.max - chartExtraData.min;

            setMin(chartExtraData.min);
            setMax(chartExtraData.max);

            // setMin(chartExtraData.min + (minDiff < 100 ? minDiff * 2 : minDiff * 0.05));
            // setMax(chartExtraData.max - (maxDiff < 100 ? maxDiff * 2 : maxDiff * 0.05));
        }
    }, [chartExtraData]);

    function CustomTooltip({ payload, label, active}) {
        if (active){
            return (
                <div>
                    <h6><b>{`${payload[0].payload.date}`}</b></h6>
                    <h6 style={{color: "#F95987"}}><b>{`${payload[0].payload.Price}`}</b></h6>
                </div>
            );
        }
        return null;
    }

    return (
        <div className="exchange-dashboard__chart" ref={containerRef} >
        {/* <div className="exchange-dashboard__chart" ref={containerRef} style={isUserAllowedData ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}></div> */}
            {
                data.length ? (
                    <AreaChart width={width} height={height} data={data}
                               margin={{top: 50, right: 0, left: 10, bottom: 10}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F95987" stopOpacity={0.3}/>
                                <stop offset="95%" stopColor="#F95987" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#eee"/>
                        <XAxis dataKey="date" minTickGap={15} padding={window.innerWidth < 767 ? { right: 50 } : {}} />
                        <YAxis dataKey="Price" orientation="right" hide={window.innerWidth < 767}
                               domain={[min, max]}
                        />
                        <Tooltip wrapperStyle={{ padding: "10px", backgroundColor: '#FFF', border: '1px solid #d5d5d5' }} content={<CustomTooltip />}/>
                        <Area type={cardinal} dataKey="Price" stroke="#F95987" strokeWidth={4} fill="url(#colorUv)" />
                    </AreaChart>
                ) : (
                    <div className="rates-loader"/>
                )
            }

        </div>
    )
};

export default Chart;