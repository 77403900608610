import React, {useState, useEffect, useRef} from 'react';
import AuthenticationService from "../../../service/AuthenticationService";
import css from './DashboardMiddle.module.scss';
import {connect} from "react-redux";
import {selectChartExtraData} from "../../../redux/exchange/exchange-selector";

const noAccessMessage = "To access this feature, please contact info@newchangefx.com.";

const DashboardMiddle = ({chartExtraData: {close, high, low, open, time}}) =>
    <div className={css.dashboardMiddle} >
        <div>
            {/* {AuthenticationService.isUserAllowedData()? (<></>):(<><span style={{color: " #F95987", fontSize: "16px"}}>{noAccessMessage}</span></>)} */}
        </div>
        <ul className={css.dashboardMiddle__list} >
        {/* style={AuthenticationService.isUserAllowedData() ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}> */}
            <li className={css.dashboardMiddle__listItem}>
                <span className={css.dashboardMiddle__listItem_title}>Open:</span>
                <span className={css.dashboardMiddle__listItem_value}>{open}</span>
            </li>
            <li className={css.dashboardMiddle__listItem}>
                <span className={css.dashboardMiddle__listItem_title}>High:</span>
                <span className={css.dashboardMiddle__listItem_value}>{high}</span>
            </li>
            <li className={css.dashboardMiddle__listItem}>
                <span className={css.dashboardMiddle__listItem_title}>Low:</span>
                <span className={css.dashboardMiddle__listItem_value}>{low}</span>
            </li>
            <li className={css.dashboardMiddle__listItem}>
                <span className={css.dashboardMiddle__listItem_title}>Close:</span>
                <span className={css.dashboardMiddle__listItem_value}>{close}</span>
            </li>
        </ul>
        
    </div>;

const mapStateToProps = state => ({
    chartExtraData: selectChartExtraData(state)
});

export default connect(mapStateToProps, {})(DashboardMiddle);