import React from 'react';
import {connect} from "react-redux";
import {getPriceImpact} from "../../../redux/calculations/reducer.js";
import PriceImpactDisplay from "./PriceImpactDisplay.jsx";
import CalculationsForm from "../CalculationsForm.jsx";
import moment from "moment";

const PriceImpactContainer = props => {
    const {currencyPairs, priceImpact, priceImpactError, getPriceImpact, currentCalculation} = props;

    const submit = ({currencyPair, sellBuy, submissionTime, fillTime, amount, dealtRate}) => {
        let baseCurrency = currencyPair.value.substring(0,3);
        let termCurrency = currencyPair.value.substring(3,6);
        let buyCurrency = sellBuy == 'b'? baseCurrency : termCurrency;
        let sellCurrency = sellBuy == 'b'? termCurrency : baseCurrency;

        let submission = "";
        if (submissionTime != undefined){
            submission = moment(submissionTime).format('YYYY-MM-DD HH:mm:ss');
        }

        const data = {
            buyCurrency: buyCurrency,
            sellCurrency: sellCurrency,
            tradeSide: sellBuy,
            submissionTime: submission,
            fillTime: moment(fillTime).format('YYYY-MM-DD HH:mm:ss'),
            amount: amount,
            dealtRate: dealtRate
        }
        getPriceImpact(data);
    }

    return (
    <>
            <div>
                <CalculationsForm currencyPairs={currencyPairs} onSubmit={submit} currentCalculation={currentCalculation}/>
                <PriceImpactDisplay priceImpact={priceImpact} priceImpactError={priceImpactError}/>  
            </div>
    </>);
};


const mapStateToProps = state => ({
    priceImpact: state.calculations.priceImpact,
    priceImpactError: state.calculations.priceImpactRequestError,
    currentCalculation: state.calculations.currentCalculation
});

export default connect(mapStateToProps,{getPriceImpact})(PriceImpactContainer);