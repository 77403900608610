const prod = {
    url: {
        API_URL: 
            "https://webapi.newchangefx.com",
        TCA_HUB_URL: 
        "https://tca-hub.newchangefx.com/"
    },
};
const dev = {
    url: {
        API_URL: 
        //   "http://localhost:9004",
             "https://webapi.newchangefx.com",
            // "https://webapi.newchangefx.com",
        TCA_HUB_URL: "http://tca-hub-uat.s3-website.eu-west-2.amazonaws.com/"
    },
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
