import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

class ModalWindow extends Component {
  onClose = () => {
    this.props.onClose();
  };
  render() {
    return (
      <Modal
        className="main-modal-window"
        show={this.props.showModal}
        onHide={this.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title || ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.content || null}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalWindow;
