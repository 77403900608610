import { createStore, applyMiddleware } from "redux";
import ThunksMiddleware from "redux-thunk";

import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { createLogger } from "redux-logger";

const middleware = [];
const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

if (process.env.NODE_ENV !== "production") {
    middleware.push(
        createLogger({
            collapsed: true,
        })
    );
}

export const store = createStore(rootReducer, applyMiddleware(ThunksMiddleware, ...middleware));

sagaMiddleware.run(rootSaga);
