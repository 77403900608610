import React from 'react';
import AuthenticationService from "../../service/AuthenticationService";

const isUserAdmin = AuthenticationService.isUserAdmin();
const RightSidebarHeader = (props) => {

    return (
        <div className="right-sidebar__header right-sidebar-header">
            <div className="right-sidebar-header__left">
                <div className="right-sidebar-header__title">
                    Instant Trade Check
                </div>
                <div className="right-sidebar-header__subtitle" >
                    Please input the trade parameters. All timestamps are UTC.
                </div>
            </div>
        </div>
    )
};

export default RightSidebarHeader;