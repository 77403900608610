import React, {useRef, useState, useEffect} from 'react';
import { curveCardinal } from 'd3-shape';
import {AreaChart, Brush, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import {useContainerDimensions} from '../../../utils/helpers';


const TimeSeriesDisplay = ({timeSeries, timeSeriesFullRequestSent, timeSeriesFullRequestError, timeSeriesRequestError, timeSeriesLoading, setExportToCsv, isCsvDownloadFinished}) => {
    const containerRef = useRef();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000);
    const { width, height } = useContainerDimensions(containerRef);
    const cardinal = curveCardinal.tension(1);

    function CustomTooltip({ payload, label, active}) {
        if (active && payload){
            return (
                <div>
                    <h6><b>{`${payload[0].payload.time}`}</b></h6>
                    <h6 style={{color: "#F95987"}}><b>{`${payload[0].payload.rate}`}</b></h6>
                </div>
            );
        }
        return null;
    }

    const wrapperStyle = { padding: "10px", backgroundColor: '#FFF', border: '1px solid #d5d5d5' };

    useEffect(() => {
        setMax(timeSeries.maxRate);
        setMin(timeSeries.minRate);
    }, [timeSeries.minRate, timeSeries.maxRate]);

    return (<>
                {timeSeriesRequestError &&
                    (<div className="calculations__display">
                        <span className="calculations__display__error">{timeSeriesRequestError.message}</span>
                    </div>)
                }

                {!timeSeriesRequestError && !timeSeriesLoading &&
                (<div className="account-page__controls web-action-buttons" style={{marginTop: "10px"}}>
                    <div className="nice-select open" tabIndex="0">
                        <span
                            className="current"
                            onClick={() => (setExportToCsv(true))}
                            style={{cursor: "pointer"}}
                        >
                            Export to CSV
                        </span>
                    </div>

                {timeSeriesFullRequestSent && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#F95987", fontWeight: "bold"}}>Loading CSV...</h2> )}
                {timeSeriesFullRequestError && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#F95987", fontWeight: "bold"}}>{timeSeriesFullRequestError}</h2> )}
                {isCsvDownloadFinished && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#F95987", fontWeight: "bold"}}>Your CSV download is complete.</h2> )}</div>)}
                {timeSeries.isSampled && (<h2 style={{paddingTop: "10px", paddingLeft: "10px", color: "#000000"}}>This graph is a sampled version of the time series. For the full data, please export to CSV.</h2>)}
                {!timeSeriesLoading && !timeSeriesRequestError && (
                <div className="exchange-dashboard__chart" ref={containerRef}>
                    <AreaChart width={width} height={height} data={timeSeries.series}
                            margin={{top: 50, right: 0, left: 10, bottom: 10}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F95987" stopOpacity={0.3}/>
                                <stop offset="95%" stopColor="#F95987" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#eee"/>
                        <XAxis dataKey="time" minTickGap={15} padding={window.innerWidth < 767 ? { right: 50 } : {right: 100}} />
                        <YAxis dataKey="rate" orientation="right" hide={window.innerWidth < 767}
                            domain={[min, max]}
                        />
                        <Tooltip wrapperStyle={wrapperStyle} content={<CustomTooltip />}/>
                        <Area type={cardinal} dataKey="rate" stroke="#F95987" strokeWidth={4} fill="url(#colorUv)"/>
                        
                    </AreaChart>
                </div>)}

                {timeSeriesLoading && !timeSeriesRequestError && (<div className="rates-loader"/>)}

                <div className="calculations__display">
        {!timeSeriesRequestError && (timeSeries.numberOfSamples < 100) && (timeSeries.numberOfSamples > 0) && !timeSeriesLoading &&
            (<>
                {/* <p><span className="calculations__display__title">Implementation Shortfall (NOT FINISHED):</span>{implementationShortfall}</p> */}
                <table className="calculations__table account-page__table">
                    <thead>
                    <tr>
                        <th>
                            <div>
                                <span>Timestamp</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Forward Pips</span>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {timeSeries.series.map((fixing, index) => {
                        return (
                            <tr key={index}>
                                <td>{fixing.time}</td>
                                <td>{fixing.rate}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
                </>
                )
            }
        </div>


            </>
        )
}

export default TimeSeriesDisplay;