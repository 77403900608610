import React, {Component} from 'react';
import LiveBenchmarks from './LiveBenchmarks';
import {config} from '../constants'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {JWT_TOKEN} from "../service/AuthenticationService";

const url = config.url.API_URL + '/currencyList?ldt='

const token = 'Bearer ' + sessionStorage.getItem(JWT_TOKEN);

const requestOptions = {
    headers: { 'Content-Type': 'application/json' , 'Authorization': token}
};

class LiveBenchmarkList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            startDate: new Date(),
            name: '',
            fullObjectMap: []
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
        this.fetchOptionFixings(date)
    };

    handleFilterChange(event){

        this.setState({name:event.target.value})
        const currencyPair = event.target.value;

        if(currencyPair.length > 0 ){
            const filteredPosts = this.state.fullObjectMap.filter((post) =>
                post.optionsFixings.some((fixing) => fixing.currency_pair.indexOf(currencyPair.toUpperCase()) > -1 ))
                .map(post => {
                    return Object.assign({}, post, {optionsFixings :
                            post.optionsFixings.filter(fixing => fixing.currency_pair.indexOf(currencyPair.toUpperCase()) > -1)});
                });

            this.setState({
                posts: filteredPosts
            })
        }else {
            this.setState({
                posts: this.state.fullObjectMap
            })
        }
    }

    render() {

        return [
            <div className="LiveBenchmark">
                <div className="datePicker">
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                        dateFormat="dd-MM-yyyy"
                    />
                </div>
                <div><input type="text" placeholder="search currency pair..." value={this.state.name} name="name" onChange={this.handleFilterChange}/></div>
                <div>{new LiveBenchmarks().mountPost(this.state.posts)}</div>
            </div>
        ];
    }

    componentDidMount() {

        this.fetchOptionFixings(this.state.startDate)

    }

    fetchOptionFixings(date) {

        axios.get(url + date, requestOptions)
            .then(res => {
                this.setState({posts: res.data, fullObjectMap: res.data})
            })
            .catch(function (error) {
                console.log(error)
            });

    }
}

export default LiveBenchmarkList;