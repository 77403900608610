import * as axios from "axios";
import moment from 'moment';
import {config} from '../constants'
import { getVolatility } from "../redux/calculations/reducer";

export const mainInstance = basicAuth => axios.create({
    baseURL: '',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
        withCredentials: true,
        mode: 'no-cors', 
        AuthorizationBackup: "Bearer " + sessionStorage.getItem('jwtToken'),
    }
});

export const currencyAPI = {
    getCurrencyPairs(basicAuth) {
        console.log("JWT: " + sessionStorage.getItem('jwtToken'))
        return mainInstance(basicAuth).get(config.url.API_URL + '/gui/currencyList').then(({data}) => data).catch(err => {
            throw err;
        });
    },
    getRates(currencyPairs = ['AUDCAD','USDRUB'], basicAuth) {

        return mainInstance().post(config.url.API_URL + '/gui/rate/latest', JSON.stringify([{
                "currencyPairs": `${currencyPairs}`
            }])
        ).then(({data}) => data).catch(err => {
            throw err;
        });

    }
};

export const chartAPI = {
    fetchChartData(interval= 86400, currencyPairs = 'EURUSD') {
        const now = new Date();
        const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        return mainInstance().post(config.url.API_URL + '/gui/data/series/twapgraph', {
            "type": "TWAP",
            "currencyPairs": currencyPairs,
            "endDate": moment(utc).format('YYYY-MM-DD HH:mm:ss'),
            "interval": `${interval}s`
        }).then(res => res.data);
    }
}

export const submitForms = {
    setSpotCheck(data) {
        return mainInstance().post(config.url.API_URL + '/gui/spot', data);
    },
    setForwardCheck(data) {
        return mainInstance().post(config.url.API_URL + '/gui/forward', data);
    },
    setSwapCheck(data) {
        return mainInstance().post(config.url.API_URL + '/gui/swap', data);
    },
    getForwardPointsInterpolation(data) {
        return mainInstance().post(config.url.API_URL + '/forward_points_interpolation/last_rate_json', data);
    }
};

export const calculationAPI = {
    getPriceImpact(data) {
        return mainInstance().post(config.url.API_URL + "/api/data/cal/priceimpact", data);
    },
    getVolatility(data) {
        return mainInstance().post(config.url.API_URL + "/api/data/cal/usdVolatility", data);
    },
    getDecay(data) {
        return mainInstance().post(config.url.API_URL + "/api/data/cal/decay", data);
    },
    getImplementationShortfall(data) {
        return mainInstance().post(config.url.API_URL + "/api/data/cal/implementationshortfall", data);
    }
}

export const dataAPI  = {
    getTimeSeries(data, type) {
        return mainInstance().post(config.url.API_URL + "/data/" + type + "/timeseries_sampled", data);
    },
    getTimeSeriesFull(data, type) {
        return mainInstance().post(config.url.API_URL + "/data/" + type + "/timeseries_full", data);
    },
    getLastRate(data, type) {
        return mainInstance().post(config.url.API_URL + "/data/" + type + "/last_rate", data);
    },
    getTwap(data, type) {
        return mainInstance().post(config.url.API_URL + "/data/" + type + "/twap", data);
    },
    getOhlc(data, type) {
        return mainInstance().post(config.url.API_URL + "/data/" + type + "/ohlc", data);
    },
}