const constants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  SET_AUTHORISED: "SET_AUTHORISED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  IS_LOGGEDIN_USER: "IS_LOGGEDIN_USER",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  TERMS_REQUEST: "TERMS_REQUEST",
  TERMS_SUCCESS: "TERMS_SUCCESS",
  TERMS_ERROR: "TERMS_ERROR",

  CHANGED_PASSWORD: "CHANGED_PASSWORD",

  /* organization */
  FETCH_ORGANIZATION_REQUEST: "FETCH_ORGANIZATION_REQUEST",
  FETCH_ORGANIZATION_SUCCESS: "FETCH_ORGANIZATION_SUCCESS",
  FETCH_ORGANIZATION_ERROR: "FETCH_ORGANIZATION_ERROR",

  UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_ERROR: "UPDATE_ORGANIZATION_ERROR",

  DELETE_ORGANIZATION_REQUEST: "DELETE_ORGANIZATION_REQUEST",
  DELETE_ORGANIZATION_SUCCESS: "DELETE_ORGANIZATION_SUCCESS",
  DELETE_ORGANIZATION_ERROR: "DELETE_ORGANIZATION_ERROR",

  ADD_ORGANIZATION_REQUEST: "ADD_ORGANIZATION_REQUEST",
  ADD_ORGANIZATION_SUCCESS: "ADD_ORGANIZATION_SUCCESS",
  ADD_ORGANIZATION_ERROR: "ADD_ORGANIZATION_ERROR",

  FETCH_ORGANIZATION_INFO_REQUEST: "FETCH_ORGANIZATION_INFO_REQUEST",
  FETCH_ORGANIZATION_INFO_SUCCESS: "FETCH_ORGANIZATION_INFO_SUCCESS",
  FETCH_ORGANIZATION_INFO_ERROR: "FETCH_ORGANIZATION_INFO_ERROR",
  /* organization */

  /* roles */
  FETCH_ROLES_REQUEST: "FETCH_ROLES_REQUEST",
  FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
  FETCH_ROLES_ERROR: "FETCH_ROLES_ERROR",

  UPDATE_ROLES_REQUEST: "UPDATE_ROLES_REQUEST",
  UPDATE_ROLES_SUCCESS: "UPDATE_ROLES_SUCCESS",
  UPDATE_ROLES_ERROR: "UPDATE_ROLES_ERROR",

  DELETE_ROLES_REQUEST: "DELETE_ROLES_REQUEST",
  DELETE_ROLES_SUCCESS: "DELETE_ROLES_SUCCESS",
  DELETE_ROLES_ERROR: "DELETE_ROLES_ERROR",

  ADD_ROLES_REQUEST: "ADD_ROLES_REQUEST",
  ADD_ROLES_SUCCESS: "ADD_ROLES_SUCCESS",
  ADD_ROLES_ERROR: "ADD_ROLES_ERROR",
  /* roles */

  /* users */
  FETCH_USERS_REQUEST: "FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",

  UPDATE_USERS_REQUEST: "UPDATE_USERS_REQUEST",
  UPDATE_USERS_SUCCESS: "UPDATE_USERS_SUCCESS",
  UPDATE_USERS_ERROR: "UPDATE_USERS_ERROR",

  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_ERROR: "DELETE_USERS_ERROR",

  ADD_USERS_REQUEST: "ADD_USERS_REQUEST",
  ADD_USERS_SUCCESS: "ADD_USERS_SUCCESS",
  ADD_USERS_ERROR: "ADD_USERS_ERROR",

  FETCH_USER_INFO_BY_ID_REQUEST: "FETCH_USER_INFO_BY_ID_REQUEST",
  FETCH_USER_INFO_BY_ID_SUCCESS: "FETCH_USER_INFO_BY_ID_SUCCESS",
  FETCH_USER_INFO_BY_ID_ERROR: "FETCH_USER_INFO_BY_ID_ERROR",

  FETCH_USER_INFO_BY_USERNAME_REQUEST: "FETCH_USER_INFO_BY_USERNAME_REQUEST",
  FETCH_USER_INFO_BY_USERNAME_SUCCESS: "FETCH_USER_INFO_BY_USERNAME_SUCCESS",
  FETCH_USER_INFO_BY_USERNAME_ERROR: "FETCH_USER_INFO_BY_USERNAME_ERROR",

  UPDATE_CONTACT_REQUEST: "UPDATE_CONTACT_REQUEST",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_ERROR: "UPDATE_CONTACT_ERROR",

  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",
  /* users */

  /* siren */
  FETCH_OPTION_FIXINGS_REQUEST: "FETCH_OPTION_FIXINGS_REQUEST",
  FETCH_OPTION_FIXINGS_SUCCESS: "FETCH_OPTION_FIXINGS_SUCCESS",
  FETCH_OPTION_FIXINGS_ERROR: "FETCH_OPTION_FIXINGS_ERROR",

  FETCH_SIRENS_REQUEST: "FETCH_SIRENS_REQUEST",
  FETCH_SIRENS_SUCCESS: "FETCH_SIRENS_SUCCESS",
  FETCH_SIRENS_ERROR: "FETCH_SIRENS_ERROR",
  /* siren */

  /* dvnc cut */
  FETCH_DVNC_REQUEST: "FETCH_DVNC_REQUEST",
  FETCH_DVNC_SUCCESS: "FETCH_DVNC_SUCCESS",
  FETCH_DVNC_ERROR: "FETCH_DVNC_ERROR",
  /* dvnc cut */

  /* dubai gold */
  FETCH_DUBAI_GOLD_REQUEST: "FETCH_DUBAI_GOLD_REQUEST",
  FETCH_DUBAI_GOLD_SUCCESS: "FETCH_DUBAI_GOLD_SUCCESS",
  FETCH_DUBAI_GOLD_ERROR: "FETCH_DUBAI_GOLD_ERROR",
  /* dubai gold  */
};

export default constants;
