import React from "react";
import AuthenticationService from "../../service/AuthenticationService";
import ForwardPointsForm from "./ForwardPointsForm";

let failedRequest = false;
let message = "";
const isUserAdmin = AuthenticationService.isUserAdmin();

const ForwardPoints = ({
  currencyPairs,
  forwardPointsInterpolation,
  asyncFormState: { formName, isSubmitting },
}) => {
  if (forwardPointsInterpolation != null) {
    if (
      forwardPointsInterpolation.forwards_points_interpolation_value
        .toLowerCase()
        .includes("error")
    ) {
      failedRequest = true;
      message = forwardPointsInterpolation.forwards_points_interpolation_value;
    } else {
      failedRequest = false;
    }
  }

  return (
    <div className="exchange-page__forward-points forward-points">
      <h3 className="forward-points__title">
        Benchmark Forward Points Interpolation
      </h3>

      <div className="forward-points__container">
        {formName === "forward" && isSubmitting ? (
          <div className="spinner" />
        ) : (
          <div className="forward-points__total">
            {failedRequest ? (
              <>
                <h3 style={{ color: "red", fontSize: "15px" }}>{message}</h3>
              </>
            ) : (
              <>
                <span className="forward-points__text">
                  {forwardPointsInterpolation?.forwards_points_interpolation_value ||
                    "00.00"}
                </span>
              </>
            )}
          </div>
        )}
        <ForwardPointsForm currencyPairs={currencyPairs} />
      </div>
    </div>
  );
};

export default ForwardPoints;
