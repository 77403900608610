import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  login: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  register: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  terms: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: null,
  },
  password: {
    changed: false,
  }
};

/* LOGIN */
const loginRequest = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const loginSuccess = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Logged in successfully!" },
      data: { $set: action.payload },
    },
  });

const loginError = (state, action) => 
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: (action.payload.request.status == 401)? "Invalid Credentials" : "Error. Cannot reach server."},
      data: { $set: null },
    },

  });

const setAuthorised = (state, action) => 
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "User Authorised!" },
      data: { $set: action.payload },
    },
  });
/* LOGIN */

/* LOG OUT*/
const logoutRequest = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });
  const logoutSuccess = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });
/* LOG OUT*/

/* register */
const registerRequest = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const registerSuccess = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Registered successfully! Check your emails for steps to login." },
      data: { $set: action.payload },
    },
  });

const registerError = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });
/* register */

/* terms */
const termsRequest = (state, action) =>
  update(state, {
    terms: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: null },
    },
  });

const termsSuccess = (state, action) =>
  update(state, {
    terms: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Terms and conditions accepted successfully." },
      data: { $set: action.payload },
    },
  });

  const changedPassword = (state, action) =>
  update(state, {
    password: {
      changed: { $set: true},
    },
  });

const termsError = (state, action) =>
  update(state, {
    terms: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: null },
    },
  });
/* terms */

export default handleActions(
  {
    [constants.LOGIN_REQUEST]: loginRequest,
    [constants.SET_AUTHORISED]: setAuthorised,
    [constants.LOGIN_SUCCESS]: loginSuccess,
    [constants.LOGIN_ERROR]: loginError,

    [constants.LOGOUT_REQUEST]: logoutRequest,
    [constants.LOGOUT_SUCCESS]: logoutSuccess,

    [constants.REGISTER_REQUEST]: registerRequest,
    [constants.REGISTER_SUCCESS]: registerSuccess,
    [constants.REGISTER_ERROR]: registerError,

    [constants.TERMS_REQUEST]: termsRequest,
    [constants.TERMS_SUCCESS]: termsSuccess,
    [constants.TERMS_ERROR]: termsError,

    [constants.CHANGED_PASSWORD]: changedPassword,
  },
  initialState
);
