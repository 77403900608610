import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MultiSelect from "@khanacademy/react-multi-select";
import * as QueryString from "query-string";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Form } from "react-bootstrap";
import * as actions from "../../redux/actions";

class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: "",
      organizationName: "",
      authorized: false,
      authorisedBy: "",
      authorisedByUsername: "",
      ncfxAdmin: false,
      selectedRoles: [],
      existingRoles: [],
      selectedUsers: [],
      existingUsers: [],
    };
  }

  componentDidMount() {
    //fetch logged user details
    const { loggedUserData } = this.props;

    if (loggedUserData && loggedUserData.user) {
      this.props.fetchUserInfoByUsernameRequest({
        username: loggedUserData.user,
      });
    }

    this.props.fetchRolesRequest();
    this.props.fetchUsersRequest();
    this.refs.organizationNameField.focus();

    const params = QueryString.parse(this.props.location.search);
    this.setState({
      organizationId: params.id,
    });
    this.props.fetchOrganizationInfoRequest({ id: params.id });
  }

  _setExistingRoles = (roles) => {
    if (roles) {
      let formattedArr = [];
      roles.forEach((element) => {
        formattedArr.push({
          label: element.name,
          value: element.id,
        });
      });
      this.setState({
        existingRoles: formattedArr,
      });
    }
  };
  _setExistingUsers = (users) => {
    if (users) {
      let formattedArr = [];
      users.forEach((element) => {
        formattedArr.push({
          label: element.fullName,
          value: element.id,
        });
      });
      this.setState({
        existingUsers: formattedArr,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.addOrganization.isSuccess &&
      this.props.addOrganization.isSuccess !==
        prevProps.addOrganization.isSuccess
    ) {
      this.props.history.push(`/Organizations`);
    }

    if (this.props.rolesList !== prevProps.rolesList) {
      this._setExistingRoles(this.props.rolesList);
    }

    if (this.props.usersList !== prevProps.usersList) {
      this._setExistingUsers(this.props.usersList);
    }

    if (this.props.organizationInfo !== prevProps.organizationInfo) {
      this._setExistingOrganizationDetails();
    }

    if (this.props.userInfoById !== prevProps.userInfoById) {
      if (this.props.userInfoById) {
        this.setState({
          authorisedBy: this.props.userInfoById.id,
          authorisedByUsername: this.props.userInfoById.loginName,
        });
      }
    }
  }

  _setExistingOrganizationDetails() {
    const { organizationInfo } = this.props;

    let selectedRoles = [];
    let selectedUsers = [];

    if (organizationInfo) {
      if (organizationInfo.roles) {
        organizationInfo.roles.forEach((obj) => {
          selectedRoles.push(obj.id);
        });
      }
      if (organizationInfo.users) {
        organizationInfo.users.forEach((obj) => {
          selectedUsers.push(obj.id);
        });
      }

      // fetch authorised by user id username from other api
      if (
        organizationInfo.authorisedBy &&
        organizationInfo.authorisedBy !== ""
      ) {
        this.props.fetchUserInfoByIdRequest({
          id: organizationInfo.authorisedBy,
        });
      }

      this.setState({
        organizationName: organizationInfo.organizationName || "",
        authorized: organizationInfo.authorized || false,
        ncfxAdmin: organizationInfo.ncfxAdmin || false,
        selectedRoles: selectedRoles,
        selectedUsers: selectedUsers,
      });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  _getInsertOrganizationBody = () => {
    const {
      organizationId,
      organizationName,
      selectedRoles,
      selectedUsers,
      authorisedBy,
      authorized,
      ncfxAdmin,
    } = this.state;
    const { rolesList, usersList } = this.props;

    let roles = [];
    let users = [];

    if (selectedRoles.length > 0 && rolesList.length > 0) {
      rolesList.forEach((obj) => {
        if (selectedRoles.indexOf(obj.id) !== -1) {
          roles.push(obj);
        }
      });
    }
    if (selectedUsers.length > 0 && usersList.length > 0) {
      usersList.forEach((obj) => {
        if (selectedUsers.indexOf(obj.id) !== -1) {
          obj.password = "";
          users.push(obj);
        }
      });
    }

    let body = {
      id: organizationId,
      organizationName,
      authorized,
      authorisedBy,
      ncfxAdmin,
      roles,
      users,
    };

    return body;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let body = this._getInsertOrganizationBody();
    this.props.updateOrganizationsRequest(body);
  };

  _onAuthorizedChange = () => {
    this.setState(
      {
        authorized: !this.state.authorized,
      },
      () => {
        if (this.state.authorized) {
          this.setState({
            authorisedBy: this.props.userInfoByUsername.id,
            authorisedByUsername: this.props.userInfoByUsername.loginName,
          });
        } else {
          this.setState({
            authorisedBy: "",
            authorisedByUsername: "",
          });
        }
      }
    );
  };

  render() {
    const { authorisedBy, authorisedByUsername } = this.state;
    let authorizedLabel = "Authorized";
    if (authorisedBy) {
      authorizedLabel +=
        " ( Authorized By:  " +
        authorisedBy +
        " - " +
        authorisedByUsername +
        " ) ";
    }

    return (
      <div className="InsertOrganization">
        <div className="lander">
          <div className="register-photo">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <h2 className="text-center">
                  <strong>Edit</strong> Organization
                </h2>

                <Form.Group>
                  <Form.Label>Organization Name</Form.Label>
                  <Form.Control
                    ref="organizationNameField"
                    type="text"
                    name="organizationName"
                    placeholder={"Organization Name"}
                    required={true}
                    min={3}
                    value={this.state.organizationName}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Roles</Form.Label>
                  <MultiSelect
                    overrideStrings={{
                      selectSomeItems: "Select Roles...",
                      allItemsAreSelected: "All Roles are Selected",
                    }}
                    options={this.state.existingRoles}
                    selected={this.state.selectedRoles}
                    onSelectedChanged={(selectedRoles) =>
                      this.setState({ selectedRoles })
                    }
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Users</Form.Label>
                  <MultiSelect
                    overrideStrings={{
                      selectSomeItems: "Select Users...",
                      allItemsAreSelected: "All Users are Selected",
                    }}
                    options={this.state.existingUsers}
                    selected={this.state.selectedUsers}
                    onSelectedChanged={(selectedUsers) =>
                      this.setState({ selectedUsers })
                    }
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                <Form.Group className="border-checkbox">
                  <Form.Check
                    isChecked={this.state.authorized ? "1" : "0"}
                    type="checkbox"
                    label={authorizedLabel}
                    onClick={this._onAuthorizedChange}
                  />
                </Form.Group>

                <Form.Group className="border-checkbox">
                  <Form.Check
                    isChecked={this.state.ncfxAdmin ? "1" : "0"}
                    type="checkbox"
                    label="NCFX Admin"
                    onClick={() => {
                      this.setState({
                        ncfxAdmin: !this.state.ncfxAdmin,
                      });
                    }}
                  />
                </Form.Group>

                <div className="form-group">
                  <Button className="btn btn-primary btn-block" type={"submit"}>
                    Update
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data,
  addOrganization: state.organization.addOrganization,
  rolesList: state.roles.roles.data,
  usersList: state.users.users.data,
  organizationInfo: state.organization.organizationInfo.data,
  userInfoByUsername: state.users.userInfoByUsername.data,
  userInfoById: state.users.userInfoById.data,
});

const mapDispatchToProps = (dispatch) => ({
  addOrganizationsRequest: (data) =>
    dispatch(actions.addOrganizationsRequest(data)),
  updateOrganizationsRequest: (data) =>
    dispatch(actions.updateOrganizationsRequest(data)),
  fetchRolesRequest: (data) => dispatch(actions.fetchRolesRequest(data)),
  fetchUsersRequest: (data) => dispatch(actions.fetchUsersRequest(data)),
  fetchOrganizationInfoRequest: (data) =>
    dispatch(actions.fetchOrganizationInfoRequest(data)),
  fetchUserInfoByUsernameRequest: (data) =>
    dispatch(actions.fetchUserInfoByUsernameRequest(data)),
  fetchUserInfoByIdRequest: (data) =>
    dispatch(actions.fetchUserInfoByIdRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditOrganization)
);
