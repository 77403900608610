import React, {useState, useEffect} from 'react';
import cn from "classnames";
import {ReactSVG} from "react-svg";
import AuthenticationService from "../service/AuthenticationService";
import triangleIcon from '../assets/img/triangle.svg';
import {sortRates} from "../utils/helpers";

const Rates = ({ratesLoader, rates = [], isCurrencySelectOpen}) => {

    return (
        <>
            {ratesLoader ? (
                <div className="rates-loader" />
                // <div className="rates-loader" style={AuthenticationService.isUserAllowedData() ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}/>
            ) : null
            }

            {rates ? (
                <div className={cn('exchange-page__rates rates', {'disabled': isCurrencySelectOpen})}>
                {/* style={AuthenticationService.isUserAllowedData() ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}> */}
                    {sortRates(rates).map((item, index) => {
                        if (index > 13) {
                            return null
                        }

                        return <Rate key={index} rate={item}/>
                    })}
                </div>
            ) : null}
        </>
    )
};

const Rate = ({rate}) => {
    const [currencyRate, setCurrencyRate] = useState(null);
    const [direction, setDirection] = useState(null);

    useEffect(() => {
        setCurrencyRate(prevState => {
            if (prevState && prevState > rate.rate) {
                setDirection('down');
            } else if (prevState && prevState < rate.rate) {
                setDirection('up');
            } else {
                // setDirection(null);
            }

            return rate.rate;
        });
    }, [rate]);

    return (
        <div className="rates__item rates-item">
            <div className="rates-item__row">
                {rate.currencyPair ? (
                    <div className="rates-item__currency">
                        {rate.currencyPair}
                    </div>
                ): null}
                <div className={`rates-item__level ${direction}`}>
                    <div className='rates-item__triangle'>
                        {!direction && '-'}
                        {['down', 'up'].includes(direction) && <ReactSVG src={triangleIcon}/>}
                    </div>
                    {currencyRate && currencyRate.toFixed(5)}
                </div>
            </div>
            {/*<div className="rates-item__row">*/}
            {/*    <div className="rates-item__difference rates-item__difference--percent">*/}
            {/*        {item.difference[0]}*/}
            {/*    </div>*/}
            {/*    <div className="rates-item__difference">*/}
            {/*        {item.difference[1]}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
};

export default Rates;