import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import validator from "validator";
import { Button, Form } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";

import * as actions from "../../redux/actions";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      formSubmissionError: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordStrength: 0,
    };
  }

  componentDidMount() {
    const { loggedUserData } = this.props;
    this.refs.oldPasswordField.focus();
    this.props.fetchUserInfoByUsernameRequest({
      username: loggedUserData.user,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfoByUsername !== prevProps.userInfoByUsername) {
      this._fillExistingDetails();
    }
  }

  _fillExistingDetails = () => {
    const { userInfoByUsername } = this.props;

    if (userInfoByUsername) {
      this.setState({
        userId: userInfoByUsername.id || "",
      });
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  _getFormBody = () => {
    let body = {
      userId: this.state.userId || "",
      oldPassword: this.state.oldPassword || "",
      password: this.state.newPassword || "",
      passwordCheck: this.state.confirmPassword || "",
    };
    return body;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let body = this._getFormBody();
    let errMessage = "";
    if (body.oldPassword === "") {
      errMessage = "Enter Old Password!!";
    } else if (body.password === "") {
      errMessage = "Enter New Password!!";
    } else if (!validator.isByteLength(body.password, { min: 4 })) {
      errMessage = "New Password Minimum 4 Characters!!";
    } else if (!validator.isByteLength(body.password, { max: 12 })) {
      errMessage = "New Password max limit 12 Characters!!";
    } else if (this.state.passwordStrength < 2) {
      errMessage = "Choose a strong password !";
    } else if (this.state.password !== this.state.passwordCheck) {
      errMessage = "Password confirmation is not same as Password !";
    }

    this.setState(
      {
        formSubmissionError: errMessage,
      },
      () => {
        if (errMessage === "") {
          // submit form
          this.props.updatePasswordRequest(body);
        }
      }
    );
  };

  render() {
    return (
      <div id="update-password">
        <div className="">
          <div className="register-photo">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <h2 className="text-center">
                  <strong>Update</strong> Password.
                </h2>
                <div className="formErrorValidation">
                  {this.state.formSubmissionError &&
                    this.state.formSubmissionError !== "" && (
                      <span>{this.state.formSubmissionError}</span>
                    )}
                </div>

                <div className="form-group">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    ref="oldPasswordField"
                    type="password"
                    name="oldPassword"
                    placeholder={"Old Password"}
                    value={this.state.oldPassword}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    placeholder={"New Password"}
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>
                <div className="form-group">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    placeholder={"Confirm Password"}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </div>

                <br />

                {this.state.newPassword && this.state.newPassword !== "" ? (
                  <PasswordStrengthBar
                    password={this.state.newPassword}
                    onChangeScore={(score) => {
                      this.setState({
                        passwordStrength: score,
                      });
                    }}
                  />
                ) : null}

                <div className="form-group">
                  <Button className="btn btn-primary btn-block" type={"submit"}>
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data,
  userInfoByUsername: state.users.userInfoByUsername.data,
});

const mapDispatchToProps = (dispatch) => ({
  updatePasswordRequest: (data) =>
    dispatch(actions.updatePasswordRequest(data)),
  fetchUserInfoByUsernameRequest: (data) =>
    dispatch(actions.fetchUserInfoByUsernameRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdatePassword)
);
