import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import {Link} from "react-router-dom";
import Select from "react-select";
import {ReactSVG} from "react-svg";
import DatePicker from "react-datepicker";

import closeIcon from '../../../assets/img/close-eye-icon.svg'
import openIcon from '../../../assets/img/eye-icon.svg'

import "react-datepicker/dist/react-datepicker.css";
import '../../../assets/scss/base/_forms.scss';

export const RenderField = ({input, checked, meta: {touched, pristine, error, valid}, label, type, text, linkPath,
                                linkText, labelAsLink}) => {
    const hasError = (touched || !pristine) && error;
    const hasValid = (touched || !pristine) && valid;

    const [isPassVisible, setPassVisible] = useState(false);
    const [passType, setPassType] = useState('password');

    const showPass = () => {
        setPassVisible(!isPassVisible);
        isPassVisible ? setPassType('password') : setPassType('text');
    }

    return (
        <>
            {type === "checkbox" ? (
                <div className="checkbox-container">
                    <label className={cn('checkbox-custom', {'error': hasError})}>
                        <input {...input} checked={checked} type={type}/>
                        <span className="checkbox-custom__checkmark"/>
                        {labelAsLink ? (
                            <Link className="checkbox-custom__text checkbox-custom__text-flex" to={linkPath}>
                                {text}
                             </Link>
                        ) : (
                            <span className="checkbox-custom__text checkbox-custom__text-flex">
                                 {text}
                                {linkText && linkPath ? (
                                    <Link className="form-item__link" to={linkPath}>
                                        {linkText}
                                    </Link>
                                ) : null}
                        </span>
                        )}
                    </label>
                </div>
            ) : type === "radio" ? (
                <div className="radio-container">
                    <label className={cn('radio-custom', {'error': hasError})}>
                        <input {...input} type={type}/>
                        <span className="radio-custom__checkmark"/>
                        <span className="radio-custom__text radio-custom__text-flex">
                        {text}
                            {linkText ? (
                                <Link className="form-item__link" to="/">
                                    {linkText}
                                </Link>
                            ) : null}
                    </span>
                    </label>
                </div>
            ) : (
                <label className={cn('field-item', {'valid': hasValid}, {'error': hasError})}>
                    <input {...input} type={type !== "password" ? type : passType} placeholder={label}/>
                    <span className="field-item__title">{label}</span>
                    {type === 'password' ? (
                        <span className="field-item__icon field-item__icon_eye" onClick={showPass}>
                            {isPassVisible ? <ReactSVG src={closeIcon}/> : <ReactSVG src={openIcon}/>}
                        </span>
                    ) : <span className="field-item__icon"/>}
                    {hasError ? <span className='error'>{error}</span> : null}
                </label>
            )
            }
        </>
    )
}

export const RenderSelect = ({input, meta: {touched, pristine, error, valid}, placeholder, options, isSearchable}) => {
    const [selectOptions, setSelectOptions] = useState([]);

    const hasError = (touched || !pristine) && error;
    const hasValid = (touched || !pristine) && valid;

    const styles = {
        control: base => ({
            ...base,
            border: '2px solid #74DEB1',
            boxShadow: 'none'
        })
    };

    useEffect(() => {
        let newOptions = [];
        if (options[0].value){
            newOptions = options;
        } 
        else {
            newOptions = options.map(val => ({
                value: val, label: val
            }));
        }
        setSelectOptions(newOptions);
    }, [options]);

    return (
        <label className={cn('field-item field-item_select', {'valid': hasValid}, {'error': hasError})}>
            <Select
                className="field-select"
                classNamePrefix="field-select"
                {...input}
                isSearchable={isSearchable ? isSearchable : false}
                onChange={value => input.onChange(value)}
                onBlur={event => event.preventDefault()}
                styles={styles}
                placeholder={placeholder}
                options={selectOptions}
            />
            {hasError ? <span className='error'>{error}</span> : null}
        </label>
    )
}

export const RenderDatePicker = ({input, meta: {touched, pristine, error, valid}, label, type, showTimeSelect, dateFormat}) => {

    const hasError = (touched || !pristine) && error;
    const hasValid = (touched || !pristine) && valid;

    const setData = value => {
        input.onChange(value);
    }

    return (
        <label className={cn('field-item field-item_calendar', {'valid': hasValid}, {'error': hasError})}>
            <span className={cn('field-item__title', {'focus': input.value})}>{label}</span>
            <span className="field-item__icon field-item__icon_calendar"/>
            <DatePicker placeholder={label}
                        type={type}
                        selected={input.value}
                        onChange={setData}
                        customInput={<input type="text"/>}
                        preventOpenOnFocus={true}
                        shouldCloseOnSelect={true}
                        showTimeSelect={showTimeSelect}
                        dateFormat={dateFormat}
            />
            {hasError ? <span className='error'>{error}</span> : null}
        </label>
    )
}