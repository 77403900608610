import React, {useState, useEffect, useRef} from 'react';
import cn from "classnames";

import infoIcon from "../assets/img/info.svg";
import redCloseIcon from "../assets/img/red-close.svg";

const InfoPopup = () => {

    const [isPopupOpen, setPopupOpen] = useState(false);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    setPopupOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div ref={wrapperRef} className="exchange-page__info exchange-info">
            <div className="exchange-info__container">
                <div className="exchange-info__name">
                    Take control of your FX costs
                </div>
                <div className="exchange-info__icon" onClick={() => setPopupOpen(true)}>
                    <img src={infoIcon} alt=""/>
                </div>
                <div className={cn('exchange-info__popup', {'hide': !isPopupOpen})}>
                    <div className="exchange-info__close" onClick={() => setPopupOpen(false)}>
                        <img src={redCloseIcon} alt=""/>
                    </div>
                    <p>
                        We exist to create an efficient and transparent FX market that is fair for all. We offer award
                        winning, independent FX data and innovative analytical tools to help ensure your operational
                        efficiency and regulatory compliance.
                    </p>
                    <p>
                        NCFX is the only FCA authorised benchmark administrator streaming
                        independent Spot and Forward FX Benchmarks in real-time. Contact your NCFX representative for
                        trial access to all live services.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default InfoPopup;