const formatTime = (time, millisecond) => {
    let formattedTime = time;
    formattedTime = addColon(formattedTime, 2);
    formattedTime = addColon(formattedTime, 5);
    
    if (millisecond) {
        formattedTime = addColon(formattedTime, 8);
        if (formattedTime.length > 12) {
            formattedTime = formattedTime.slice(0,12);
        }
    } else {
        if (formattedTime.length > 8) {
            formattedTime = formattedTime.slice(0,8);
        }
    }

    return formattedTime;
}

const addColon = (time, index) => {
    let formattedTime = time;
    if (formattedTime.length > index) {
        if (formattedTime.charAt(index) != ":") {
            let firstPart = formattedTime.slice(0,index);
            let lastPart = formattedTime.slice(index, time.length);
            formattedTime = firstPart + ":" + lastPart;
        }
    }
    return formattedTime;
}

export default formatTime;