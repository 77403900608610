import cn from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import blackArrow from "../../assets/img/black_arrow.svg";
import logo from "../../assets/img/logo.svg";
import logout from "../../assets/img/logout.svg";
import person from "../../assets/img/person.svg";
import whiteArrow from "../../assets/img/white_arrow.svg";
import * as actions from "../../redux/actions";

import linkedIn from "../../assets/img/linkedin.svg";
import twitter from "../../assets/img/twitter.svg";
import { config } from "../../constants.js";
import { setUserIsLoggedIn } from "../../redux/app-reducer";
import AuthenticationService from "../../service/AuthenticationService";

class AuthenticatedHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
      isFixedHeader: false,
      showMobileMenu_benchmarks: false,
      showMobileMenu_ncfxAdmin: false,
    };
  }

  _toggleMobileMenu = (check) => {
    if (check && check !== "") {
      if (check === "ncfxAdmin") {
        this.setState({
          showMobileMenu_ncfxAdmin: !this.state.showMobileMenu_ncfxAdmin,
        });
      } else if (check === "benchmarks") {
        this.setState({
          showMobileMenu_benchmarks: !this.state.showMobileMenu_benchmarks,
        });
      }
    } else {
      this.setState({
        showMobileMenu: !this.state.showMobileMenu,
      });
    }
  };

  _resetMobileMenu = () => {
    this.setState({
      showMobileMenu: false,
      showMobileMenu_benchmarks: false,
      showMobileMenu_ncfxAdmin: false,
    });
  };

  _redirectMobileMenu = (e, path) => {
    e.preventDefault();
    this.props.mainHistory.push(path);
    this._resetMobileMenu();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showMobileMenu !== this.state.showMobileMenu) {
      if (this.state.showMobileMenu) {
        document.documentElement.classList.add("overflow");
        document.body.classList.add("overflow");
      } else {
        document.documentElement.classList.remove("overflow");
        document.body.classList.remove("overflow");
      }
    }
  }

  componentDidMount() {
    this.props.isLoggedInUser();
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    if (window.pageYOffset > 150) {
      this.setState({
        isFixedHeader: true,
      });
    } else {
      this.setState({
        isFixedHeader: false,
      });
    }
  }

  _doLogout = (e) => {
    e.preventDefault();
    this.props.logoutRequest();
    AuthenticationService.logout();
    this.props.setUserIsLoggedIn(false);
    sessionStorage.removeItem("loggedIn");
  };

  _renderMobileMenu = () => {
    const { showMobileMenu_benchmarks, showMobileMenu_ncfxAdmin } = this.state;
    const name = sessionStorage.getItem("name").slice(1, -1);
    const isUserAdmin = AuthenticationService.isUserAdmin();
    // const isUserAllowedData = AuthenticationService.isUserAllowedData();
    const isUserAllowedTCAHub = AuthenticationService.isUserAllowedTCAHub();
    const { isTCAInMaintenance } = this.props;

    return (
      <div
        className={cn("account-mobile-menu", {
          show: this.state.showMobileMenu,
        })}
      >
        <div className="account-mobile-menu__container">
          <div className="account-mobile-menu__top">
            <div className="account-mobile-menu__profile">
              <div className="account-mobile-menu__list-item header-profile-menu">
                <div
                  className="account-mobile-menu__list-header header-profile-menu"
                  onClick={() => this._toggleMobileMenu("ncfxAdmin")}
                >
                  <img className="profile-icon" src={person} alt="" />
                  <span>{name}</span>
                  {isUserAdmin && (
                    <img
                      className="arrow-icon"
                      src={showMobileMenu_ncfxAdmin ? blackArrow : whiteArrow}
                      alt=""
                    />
                  )}
                </div>
                <ul className="account-mobile-menu__list-content user-menu-drop-down">
                  {showMobileMenu_ncfxAdmin ? (
                    <>
                      <li>
                        <Link
                          to={"/ChangePassword"}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.mainHistory.push("/ChangePassword");
                            this._resetMobileMenu();
                          }}
                        >
                          Change Password
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {isUserAdmin && showMobileMenu_ncfxAdmin ? (
                    <>
                      <li>
                        <Link to="/Users" onClick={this._resetMobileMenu}>
                          Users
                        </Link>
                      </li>
                      <li>
                        <Link to="/Roles" onClick={this._resetMobileMenu}>
                          Roles
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Organizations"
                          onClick={() => this._resetMobileMenu()}
                        >
                          Organizations
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
            <button
              className="account-mobile-menu__logout"
              href="/#"
              // onClick={AuthenticationService.logout}
              onClick={this._doLogout}
            >
              <span>Log Out</span>
              <img src={logout} alt="" />
            </button>
          </div>
          {/*<SystemStatus/>*/}
          <div className="account-mobile-menu__list">
            <div className="account-mobile-menu__list-item">
              <Link
                to="/Home"
                className="account-mobile-menu__list-header"
                onClick={() => this._resetMobileMenu()}
              >
                <span>Home</span>
              </Link>
            </div>
            {/* <div className="account-mobile-menu__list-item">
              <div
                className="account-mobile-menu__list-header"
                onClick={() => this._toggleMobileMenu("benchmarks")}
              >
                <span>Benchmarks</span>
                <img
                  src={showMobileMenu_benchmarks ? blackArrow : whiteArrow}
                  alt=""
                />
              </div>
              {showMobileMenu_benchmarks ? (
                <ul className="account-mobile-menu__list-content">
                  {AuthenticationService.isUserAllowedData() ? (
                                    <>
                  <li>
                                            <Link to="/DVNCCut"
                                                onClick={(e) => this._redirectMobileMenu(e, "/DVNCCut")}
                                            >
                                                DVNC Cut
                                            </Link>
                                        </li>
                  </>
                                    ):(<>
                                        <li>
                                            <Link to="/"
                                                onClick={(e) => this._redirectMobileMenu(e, "/")}
                                                style={AuthenticationService.isUserAllowedData() ? {} : {color: "grey"}}
                                            >
                                                DVNC Cut
                                                {AuthenticationService.isUserAllowedData() ? (<></>): (<>
                                                <span className="tooltipText">
                                                Upgrade your plan for access to this item.
                                                </span></>)}
                                            </Link>
                                        </li>
                                    </>)}
                  <li>
                    <Link
                      to="/Siren"
                      onClick={(e) => this._redirectMobileMenu(e, "/Siren")}
                    >
                      SIREN
                    </Link>
                  </li>
                  <li>
                    <Link
                      DubaiGold="/DubaiGold"
                      onClick={(e) => this._redirectMobileMenu(e, "/DubaiGold")}
                    >
                      DGCX Fix
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="disabled-link">
                      Currency Beta Indices
                    </Link>
                  </li>
                </ul>
              ) : null}
            </div> */}
            <div className="account-mobile-menu__list-item">
              <Link
                to="/"
                className="account-mobile-menu__list-header"
                style={isUserAdmin ? {} : { color: "grey" }}
              >
                <span>API Centre</span>
                {!isUserAdmin && (
                  <span className="tooltipText">
                    Upgrade your plan for access to this item.
                  </span>
                )}
              </Link>
            </div>
            {isUserAllowedTCAHub && !isTCAInMaintenance && (
              <div className="account-mobile-menu__list-item">
                <Link
                  to="/"
                  className="account-mobile-menu__list-header"
                  style={isUserAdmin ? {} : { color: "grey" }}
                >
                  <span>TCA Hub</span>
                  {!isUserAdmin && (
                    <span className="tooltipText">
                      Upgrade your plan for access to this item.
                    </span>
                  )}
                </Link>
              </div>
            )}
          </div>
          <div className="account-mobile-menu__contact-info">
            <h3>Information</h3>
            <div className="account-mobile-menu__contact-info-item">
              <span>Email:</span>
              <a href="mailto:info@newchangefx.com">info@newchangefx.com</a>
            </div>
            <div className="account-mobile-menu__contact-info-item">
              <span>Telephone:</span>
              <a href="tel:+4402074171820">+44 (0)20 7417 1820</a>
            </div>
          </div>
          <ul className="account-mobile-menu__socials">
            <li>
              <a href="/">
                <img src={twitter} alt="" />
              </a>
            </li>
            <li>
              <a href="/">
                <img src={linkedIn} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  render() {
    // const {
    //     showMobileMenu,
    //     showMobileMenu_benchmarks,
    //     showMobileMenu_ncfxAdmin,
    // } = this.state;

    const TCA_HUB_URL = config.url.TCA_HUB_URL;
    const name = sessionStorage.getItem("name").slice(1, -1);
    const isUserAdmin = AuthenticationService.isUserAdmin();
    const isUserAllowedData = AuthenticationService.isUserAllowedData();
    const isUserAllowedTCAHub = AuthenticationService.isUserAllowedTCAHub();
    const { isTCAInMaintenance } = this.props;

    return (
      <>
        <header
          className={cn("header-account", { fixed: this.state.isFixedHeader })}
        >
          {isUserAllowedTCAHub && isTCAInMaintenance && (
            <div className="header-account__banner">
              <p>
                The NCFX portal, TCA Hub and Data-API will be down for 2 hours
                from 4pm (BST) Monday 9th May for routine maintenance.
              </p>
              <p>
                Please contact us at{" "}
                <a href="mailto:support@newchangefx.com">
                  support@newchangefx.com
                </a>{" "}
                if you need any support during this time.
              </p>
            </div>
          )}
          <div className="container">
            <div className="header-account__left">
              <Link
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.mainHistory.push("/");
                }}
              >
                <img className="header-account__logo" src={logo} alt="" />
              </Link>
              {/*<SystemStatus/>*/}
            </div>
            <div className="header-account__right">
              <ul className="header-account__menu">
                <li className="header-account__menu-item header-account__menu-start">
                  <Link
                    to="/Home"
                    className="header-account__menu-link"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.mainHistory.push("/Home");
                    }}
                  >
                    Home
                  </Link>
                </li>
                {/* <li className="header-account__menu-item">
                  <Link
                    className="header-account__menu-link js-dropdown-open"
                    to="/"
                  >
                    Benchmarks
                  </Link>
                  <ul className="header-account__menu-dropdown">
                    {AuthenticationService.isUserAllowedData() ? (
                                    <>
                    <li>
                      <Link
                        to="/DVNCCut"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.mainHistory.push("/DVNCCut");
                        }}
                      >
                        DVNC Cut
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Siren"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.mainHistory.push("/Siren");
                        }}
                      >
                        SIREN
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/DubaiGold"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.mainHistory.push("/DubaiGold");
                        }}
                      >
                        DGCX Fix
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        className="disabled-link"
                        style={{ color: "grey" }}
                      >
                        Currency Beta Indices
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li className="header-account__menu-item">
                  <Link
                    to="/"
                    className="header-account__menu-link js-dropdown-open"
                    //   style={isUserAdmin ? {} : {color: "grey"}}
                  >
                    API Centre
                  </Link>
                  <ul className="header-account__menu-dropdown">
                    <li>
                      <Link
                        to={"/Data"}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.mainHistory.push("/Data");
                        }}
                        style={
                          isUserAllowedData
                            ? {}
                            : { color: "grey", pointerEvents: "none" }
                        }
                      >
                        Data
                      </Link>
                      {!isUserAllowedData && (
                        <span className="tooltipText">
                          Upgrade your plan for access to this item.
                        </span>
                      )}
                    </li>
                    {isUserAdmin && (
                      <li>
                        <Link
                          to={"/Calculations"}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.mainHistory.push("/Calculations");
                          }}
                          style={
                            isUserAdmin
                              ? {}
                              : { color: "grey", pointerEvents: "none" }
                          }
                        >
                          Calculations
                        </Link>
                        {!isUserAdmin && (
                          <span className="tooltipText">
                            Upgrade your plan for access to this item.
                          </span>
                        )}
                      </li>
                    )}
                  </ul>
                </li>
                {isUserAllowedTCAHub && !isTCAInMaintenance && (
                  <li className="header-account__menu-item">
                    <a
                      href={TCA_HUB_URL + AuthenticationService.getUserToken()}
                      className="header-account__menu-link"
                      target="_blank"
                      style={
                        isUserAllowedTCAHub
                          ? {}
                          : { color: "grey", pointerEvents: "none" }
                      }
                    >
                      TCA Hub
                    </a>
                    {!isUserAllowedTCAHub && (
                      <span className="tooltipText">
                        Upgrade your plan for access to this item.
                      </span>
                    )}
                  </li>
                )}
              </ul>
              <div className="header-account__user">
                <ul className="header-account__menu">
                  <li className="header-account__menu-item header-profile-menu">
                    <div className="header-account__menu-link">
                      <img src={person} alt="" />
                      <span className="user-name">{name}</span>
                    </div>
                    <ul className="header-account__menu-dropdown">
                      {isUserAdmin && (
                        <>
                          <li>
                            <Link
                              to="/Roles"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.mainHistory.push("/Roles");
                              }}
                            >
                              Roles
                            </Link>
                          </li>
                        </>
                      )}
                      <>
                        <li>
                          <Link
                            to={"/ChangePassword"}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.mainHistory.push("/ChangePassword");
                            }}
                          >
                            Change Password
                          </Link>
                        </li>
                      </>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="header-account__user">
                <a
                  className="header-account__logout"
                  href="/#"
                  onClick={this._doLogout}
                >
                  <span>Log Out</span>
                  <img src={logout} alt="Logout" />
                </a>
              </div>

              <div
                className={cn("header-account__burger", {
                  active: this.state.showMobileMenu,
                })}
                onClick={() => this._toggleMobileMenu()}
              >
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
          {this._renderMobileMenu()}
        </header>
        {isTCAInMaintenance && <div className="banner-shim"></div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  setUserIsLoggedIn: (data) => dispatch(setUserIsLoggedIn(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthenticatedHeader)
);
