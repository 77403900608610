import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../service";
import {setServerError} from "../app-reducer";

export function* fetchOrganizationsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/api/v1/organization/findAll`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.fetchOrganizationsSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchOrganizationsError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* updateOrganizationsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "PUT",
      `/api/v1/organization/updateOrganization`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Updated!!",
      });
      yield put(actions.updateOrganizationsSuccess(response.data));
      yield put(actions.fetchOrganizationsRequest());
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while updating!!",
    });
    yield put(actions.updateOrganizationsError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* deleteOrganizationsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "DELETE",
      `/api/v1/organization/delete/` + action.payload.id,
      {},
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully deleted!!",
      });
      yield put(actions.deleteOrganizationsSuccess(response.data));
      yield put(actions.fetchOrganizationsRequest(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while deleting!!",
    });
    yield put(actions.deleteOrganizationsError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}

export function* addOrganizationsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/api/v1/organization/saveOrganization`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: "",
        message: "Successfully Added!!",
      });
      yield put(actions.addOrganizationsSuccess(response.data));
      yield put(setServerError(response.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: "",
      message: "Error while adding!!",
    });
    yield put(actions.addOrganizationsError(e.response.data));
  }
}

export function* fetchOrganizationInfoRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "GET",
      `/api/v1/organization/findById/` + action.payload.id,
      {},
      header
    );
    if (response) {
      yield put(actions.fetchOrganizationInfoSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.fetchOrganizationInfoError(e.response.data));
    yield put(setServerError(e.response.data));
  }
}
