import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import MultiSelect from "@khanacademy/react-multi-select";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import {Button, Form} from "react-bootstrap";
import * as actions from "../../redux/actions";

class InsertOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationName: "",
            authorized: false,
            selectedRoles: "",
            existingRoles: [],
            selectedUsers: "",
            existingUsers: [],
        };
    }

    componentDidMount() {
        this.refs.organizationNameField.focus();
        this.props.fetchRolesRequest();
        this.props.fetchUsersRequest();
        this.props.fetchUserInfoByIdRequest({id: 1}); //  this users full name will be used in authorized By
    }

    _setExistingRoles = (roles) => {
        if (roles) {
            let formattedArr = [];
            roles.forEach((element) => {
                formattedArr.push({
                    label: element.name,
                    value: element.id,
                });
            });
            this.setState({
                existingRoles: formattedArr,
            });
        }
    };
    _setExistingUsers = (users) => {
        if (users) {
            let formattedArr = [];
            users.forEach((element) => {
                formattedArr.push({
                    label: element.fullName,
                    value: element.id,
                });
            });
            this.setState({
                existingUsers: formattedArr,
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.addOrganization.isSuccess &&
            this.props.addOrganization.isSuccess !==
            prevProps.addOrganization.isSuccess
        ) {
            this.props.history.push(`/Organizations`);
        }

        if (this.props.rolesList !== prevProps.rolesList) {
            this._setExistingRoles(this.props.rolesList);
        }

        if (this.props.usersList !== prevProps.usersList) {
            this._setExistingUsers(this.props.usersList);
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };

    _getInsertOrganizationBody = () => {
        const {organizationName, selectedRoles, selectedUsers} = this.state;
        const {rolesList, usersList, userInfoById} = this.props;

        let authorized = false;
        let authorisedBy = "";
        let ncfxAdmin = false;
        let roles = [];
        let users = [];

        if (userInfoById && userInfoById.fullName) {
            authorisedBy = userInfoById.fullName;
        }
        if (selectedRoles.length > 0 && rolesList.length > 0) {
            rolesList.forEach((obj) => {
                if (selectedRoles.indexOf(obj.id) !== -1) {
                    roles.push(obj);
                }
            });
        }
        if (selectedUsers.length > 0 && usersList.length > 0) {
            usersList.forEach((obj) => {
                if (selectedUsers.indexOf(obj.id) !== -1) {
                    obj.password = "";
                    users.push(obj);
                }
            });
        }

        let body = {
            organizationName,
            authorized,
            authorisedBy,
            ncfxAdmin,
            roles,
            users,
        };

        return body;
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let body = this._getInsertOrganizationBody();
        this.props.addOrganizationsRequest(body);
    };

    render() {
        return (
            <div className="InsertOrganization">
                <div className="lander">
                    <div className="register-photo">
                        <div className="form-container">
                            <form onSubmit={this.handleSubmit}>
                                <h2 className="text-center">
                                    <strong>Create</strong> a new organization.
                                </h2>

                                <Form.Group>
                                    <Form.Label>Organization Name</Form.Label>
                                    <Form.Control
                                        ref="organizationNameField"
                                        type="text"
                                        name="organizationName"
                                        placeholder={"Organization Name"}
                                        required={true}
                                        min={3}
                                        value={this.state.organizationName}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Select Roles</Form.Label>
                                    <MultiSelect
                                        overrideStrings={{
                                            selectSomeItems: "Select Roles...",
                                            allItemsAreSelected: "All Roles are Selected",
                                        }}
                                        options={this.state.existingRoles}
                                        selected={this.state.selectedRoles}
                                        onSelectedChanged={(selectedRoles) =>
                                            this.setState({selectedRoles})
                                        }
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Select Users</Form.Label>
                                    <MultiSelect
                                        overrideStrings={{
                                            selectSomeItems: "Select Users...",
                                            allItemsAreSelected: "All Users are Selected",
                                        }}
                                        options={this.state.existingUsers}
                                        selected={this.state.selectedUsers}
                                        onSelectedChanged={(selectedUsers) =>
                                            this.setState({selectedUsers})
                                        }
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>

                                <Form.Group className="border-checkbox">
                                    <Form.Check type="checkbox" label="Authorized" disabled/>
                                </Form.Group>

                                <Form.Group className="border-checkbox">
                                    <Form.Check type="checkbox" label="NCFX Admin" disabled/>
                                </Form.Group>

                                <div className="form-group">
                                    <Button className="btn btn-primary btn-block" type={"submit"}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    addOrganization: state.organization.addOrganization,
    rolesList: state.roles.roles.data,
    usersList: state.users.users.data,
    userInfoById: state.users.userInfoById.data,
});

const mapDispatchToProps = (dispatch) => ({
    addOrganizationsRequest: (data) =>
        dispatch(actions.addOrganizationsRequest(data)),
    fetchRolesRequest: (data) => dispatch(actions.fetchRolesRequest(data)),
    fetchUsersRequest: (data) => dispatch(actions.fetchUsersRequest(data)),
    fetchUserInfoByIdRequest: (data) =>
        dispatch(actions.fetchUserInfoByIdRequest(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InsertOrganization)
);
