import React, {useState, useEffect, useRef} from 'react';

import InfoPopup from "./InfoPopup";
import Rates from "./Rates";
import CurrencySelect from "./CurrencySelect";
// import ContraCurrencySelect from "./ContraCurrencySelect";
import Dashboard from "./Dashboard/Dashboard";
import RightSidebar from "./RightSidebar/RightSidebar";
import ForwardPointsContainer from "./ForwardPoints/ForwardPointsContainer";
import AuthenticationService from "../service/AuthenticationService";
import './_exchange-page.scss';
import cn from "classnames";
import infoIcon from "../assets/img/info.svg";
import redCloseIcon from "../assets/img/red-close.svg";

const ExchangePage = props => {
    const [isCurrencySelectOpen, setCurrencySelectOpen] = useState(false);
    const isUserAllowedData = AuthenticationService.isUserAllowedData();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const noAccessMessage = "To access this feature, please contact info@newchangefx.com.";


    return (
        <main className="main exchange-page">
            <div className="container">
                <div className="exchange-page__top exchange-page-top">
                    <div className="exchange-page-top__left">
                        <h1 className="exchange-page-top__maintitle">
                            Welcome to <span>NCFX Client Portal</span>
                        </h1>
                        <InfoPopup/>
                    </div>
                </div>
                <div className="exchange-page__bar exchange-bar">
                    <div className="exchange-bar__top">
                        <div className="exchange-bar__title">Spot Benchmark Snapshot</div>
                        {/* <div className="exchange-bar__controls" style={isUserAllowedData ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}> */}
                        <div className="exchange-bar__controls" >
                            <CurrencySelect setSortRates={props.setSortRates}
                                            setCurrencySelectOpen={setCurrencySelectOpen}
                                            currency={props.currencyPairs}
                                            isSorting={true}
                                            isMulti={true}
                                            placeholder={"Available currency pairs:"}
                            />
                            {/*<ContraCurrencySelect setCurrencySelectOpen={setCurrencySelectOpen}*/}
                            {/*                      currency={props.currency}/>*/}
                        </div>
                    </div>
                    {/* <div style={{margin: "0px 0px 0px 20px"}}>
                        {AuthenticationService.isUserAllowedData()? (<></>):(<><span style={{color: " #F95987", fontSize: "16px"}}>{noAccessMessage}</span></>)}
                    </div> */}
                    <Rates ratesLoader={props.ratesLoader} rates={props.rates}
                           isCurrencySelectOpen={isCurrencySelectOpen} />
                           {/* style={isUserAllowedData ? {} : {opacity: "0.4", pointerEvents: "none", filter: "blur(1.5px)"}}/> */}
                </div>
                <div className="exchange-page__body">
                    <div className="exchange-page__content" >
                        <Dashboard setSortRates={props.setSortRates} setCurrencySelectOpen={setCurrencySelectOpen}
                                   currency={props.currencyPairs} ratioData={props.ratioData}
                                   timeList={props.timeList}/>
                    </div>
                    <RightSidebar direction={props.direction} currencyPairs={props.currencyPairs} instantTradeCheck={props.instantTradeCheck}/>
                    <ForwardPointsContainer currencyPairs={props.currencyPairs}/>
                </div>
            </div>
        </main>
    )
};

export default ExchangePage;