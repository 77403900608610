import React, { Component } from "react";
import { connect } from "react-redux";
import {Link, withRouter} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Datatable } from "@o2xp/react-datatable";
import "./Roles.css";

import WorkIcon from "@material-ui/icons/Work";

import * as actions from "../../redux/actions";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      name: "",
      fullObjectMap: [],
      options: "",
    };
  }

  componentDidMount() {
    this.props.fetchRolesRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rolesList !== prevProps.rolesList) {
      this.setOptions(this.props.rolesList);
    }
  }

  setOptions(roles) {
    let options = {
      actions: {},
      dimensions: {
        datatable: {
          width: "100%",
          height: "500px",
        },
      },
      keyColumn: "id",
      data: {
        columns: [
          {
            id: "id",
            label: "id",
            colSize: "10px",
            editable: false,
          },
          {
            id: "name",
            label: "name",
            colSize: "30px",
            editable: true,
          },
        ],
        rows: roles,
      },
      features: {
        canEdit: true,
        canDelete: true,
      },
    };

    this.setState({ options: options });
  }

  actionsRow = ({ type, payload }) => {
    if (type === "save") {
      this.props.updateRolesRequest(payload);
    } else if (type === "delete") {
      this.props.deleteRolesRequest({ id: payload.id });
    }
  };

  render() {
    if (Object.keys(this.state.options).length === 0) {
      return [
        <div key="1" className="Roles">
          <div className="lander">Loading Roles...</div>
        </div>,
      ];
    } else {
      return [
        <div key="2" className="Roles">
          <div className="lander">
            <div className="roles-category">
              <form>
                <span className="current">Roles</span>
              </form>
            </div>
            <div>
              <Link
                to="/InsertRole"
                onClick={() => {
                  this.props.history.push("/InsertRole");
                }}
              >
                <WorkIcon /> Insert New Role
              </Link>
            </div>
            <div>
              <Datatable
                options={this.state.options}
                actions={this.actionsRow}
              />
            </div>
          </div>
        </div>,
      ];
    }
    // return [
    //   <div className="Roles">
    //     <div className="lander">
    //       if (Object.keys(this.state.options).length == 0){" "}
    //       {<Datatable options={this.state.options} actions={this.actionsRow} />}
    //     </div>
    //   </div>,
    // ];
  }
}

const mapStateToProps = (state) => ({
  rolesList: state.roles.roles.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRolesRequest: (data) => dispatch(actions.fetchRolesRequest(data)),
  updateRolesRequest: (data) => dispatch(actions.updateRolesRequest(data)),
  deleteRolesRequest: (data) => dispatch(actions.deleteRolesRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles));
