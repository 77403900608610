import React, {useRef, useState, useEffect} from 'react';
import { curveCardinal } from 'd3-shape';
import {AreaChart, Brush, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine} from 'recharts';
import {useContainerDimensions} from '../../../utils/helpers';

const ImplementationShortfallDisplay = props => {
    const {implementationShortfall, implementationShortfallError} = props;
    const containerRef = useRef();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000);
    const { width, height } = useContainerDimensions(containerRef);
    const cardinal = curveCardinal.tension(1);

    function CustomTooltip({ payload, label, active}) {
        if (active && payload){
            return (
                <div>
                    <h6><b>{`${payload[0].payload.time}`}</b></h6>
                    <h6 style={{color: "#F95987"}}><b>{`${payload[0].payload.usdCost}`}</b></h6>
                </div>
            );
        }
        return null;
    }

    const wrapperStyle = { padding: "10px", backgroundColor: '#FFF', border: '1px solid #d5d5d5' };

    useEffect(() => {
        setMax(implementationShortfall.maxCost);
        setMin(implementationShortfall.minCost);

    }, [implementationShortfall.minCost, implementationShortfall.maxCost]);

    return (
    <div className="calculations__display">
        {implementationShortfallError &&
            (<><span className="calculations__display__error">{implementationShortfallError.message}</span></>)}

        <div className="exchange-dashboard__chart" ref={containerRef}>
            <AreaChart width={width} height={height} data={implementationShortfall.shortfall}
                    margin={{top: 50, right: 0, left: 10, bottom: 10}}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#F95987" stopOpacity={0.3}/>
                        <stop offset="95%" stopColor="#F95987" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <CartesianGrid stroke="#eee"/>
                <XAxis dataKey="time" minTickGap={15} padding={window.innerWidth < 767 ? { right: 50 } : {}} />
                <YAxis dataKey="usdCost" orientation="right" hide={window.innerWidth < 767}
                    domain={[min, max]}
                />
                <Tooltip wrapperStyle={wrapperStyle} content={<CustomTooltip />}/>
                <Area type={cardinal} dataKey="usdCost" stroke="#F95987" strokeWidth={4} fill="url(#colorUv)"/>
                
            </AreaChart>
        </div>
        
        {implementationShortfall.shortfall.length > 0 && 
            (<>
                <table className="calculations__table account-page__table">
                    <thead>
                    <tr>
                        <th>
                            <div>
                                <span>Timestamp</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Transaction Step</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>NCFX Mid Rate</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>USD Cost</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span>Bp Cost</span>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {implementationShortfall.shortfall.map((fixing, index) => (
                        <tr key={index}>
                            <td>{fixing.time}</td>
                            <td>{fixing.step}</td>
                            <td>{fixing.midRate}</td>
                            <td>${fixing.usdCost}</td>
                            <td>{fixing.bpCost}</td>
                        </tr>
                    ))}
                    {implementationShortfall.total.map((fixing, index) => (
                        <tr style={{backgroundColor: "#dedede"}} key={index}>
                            <td style={{fontWeight: "1000"}}>{fixing.time}</td>
                            <td style={{fontWeight: "1000"}}>{fixing.step}</td>
                            <td style={{fontWeight: "1000"}}>{fixing.midRate}</td>
                            <td style={{fontWeight: "1000"}}>${fixing.usdCost}</td>
                            <td style={{fontWeight: "1000"}}>{fixing.bpCost}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>)}
    </div>
    )
};

export default ImplementationShortfallDisplay;