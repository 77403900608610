import React, {useState, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {required, commaCheck, checkNumber, millisecondTimeValidator, millisecondTimeFormatValidator} from "../../utils/validators/validators";
import {RenderField, RenderSelect, RenderDatePicker} from "../../components/common/FormsControls/FormsControls";
import tenors from "./Menus/tenors";
import intervals from "./Menus/intervals"
import formatTime from "../../components/common/FormsControls/TimePicker";
import {compose} from "redux";
import {connect} from "react-redux";

import "../../assets/css/main.css";
import "../../assets/css/reset.css";

const Form = props => {

    const {handleSubmit, currencyPairs, financialInstrument, dataView} = props;

    const currencyPairBoxWidthFull = () => {
        if (financialInstrument == 0){
            if (dataView < 2)
                return true;
        }
        
        return false;
    }

    const intervalBoxWidthFull = () => {
        if (financialInstrument == 1){
            if (dataView > 1)
                return true;
        }
        
        return false;
    }

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [startTooLongError, setStartTooLongError] = useState(false); 
    const [endTooLongError, setEndTooLongError] = useState(false); 

    const startTimepickerChange = (e,value) => {
        const newValue = formatTime(value, true);
        if (value.length >= 12){
            setStartTooLongError(true);
        }
        setStartTime(newValue);
        
        props.change("startTime", newValue);
    }

    const endTimepickerChange = (e,value) => {
        const newValue = formatTime(value, true);

        if (value.length >= 12){
            setEndTooLongError(true);
        }
        setEndTime(newValue);
        props.change("endTime", newValue);
    }

    useEffect(() => {
        const form = props.dataForm;
        if (form) {
            if (form.values) {
                console.log(form);
                if (form.values.startTime != startTime) {
                    props.change("startTime", startTime);
                }
                if (startTooLongError){
                    props.change("startTime", startTime);
                    setStartTooLongError(false);
                }
            } else {
                props.change("startTime", startTime);
            }
        }
    }, [startTime, startTooLongError])

    useEffect(() => {
        const form = props.dataForm;
        if (form) {
            if (form.values) {
                console.log(form);
                if (form.values.endTime != endTime) {
                    props.change("endTime", endTime);
                }
                if (endTooLongError){
                    props.change("endTime", endTime);
                    setEndTooLongError(false);
                }
            } else {
                props.change("endTime", endTime);
            }
        }
    }, [endTime, endTooLongError])

    return (
            <div className="calculations__form">
                
                <form className="right-sidebar-form forward-points-form" onSubmit={handleSubmit}>
                    <div className="right-sidebar-form__item" style={currencyPairBoxWidthFull()? {width: "100%", zIndex: 5} : {zIndex: 5}}>
                        <Field validate={[required]} name="currencyPair" isSearchable={true} component={RenderSelect} placeholder={"Currency Pair"} options={currencyPairs}/>
                    </div>
                    {financialInstrument == 1 && (<div className="right-sidebar-form__item" style={{zIndex: 5}}>
                        <Field validate={[required]} name="tenor" isSearchable={true} component={RenderSelect} placeholder={"Tenor"} options={tenors}/>
                    </div>)}
                    {dataView > 1 && (
                    <div className="right-sidebar-form__item" style={intervalBoxWidthFull()? {width: "100%"} : {}}>
                        <Field validate={[required]} name="interval" isSearchable={true} component={RenderSelect} placeholder={"Interval"} options={intervals}/>
                    </div>)}
                    <div className="right-sidebar-form__item">
                        <Field
                            validate={[required]}
                            name="startDate"
                            label={dataView >  0? "Start Date": "Date"}
                            component={RenderDatePicker}
                            type="text"
                            className="form-control"
                            timeIntervals={1}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="right-sidebar-form__item">
                        <Field
                            validate={[required, millisecondTimeFormatValidator, millisecondTimeValidator]}
                            name="startTime"
                            label={dataView >  0? "Start Time": "Time"}
                            type="text"
                            component={RenderField}
                            onChange={startTimepickerChange}
                        />
                    </div>
                    {dataView > 0 && (
                    <>
                        <div className="right-sidebar-form__item">
                            <Field
                            validate={[required]}
                            name="endDate"
                            label="End Date"
                            component={RenderDatePicker}
                            type="text"
                            className="form-control"
                            timeIntervals={1}
                            dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className="right-sidebar-form__item">
                            <Field
                                validate={[required, millisecondTimeFormatValidator, millisecondTimeValidator]}
                                name="endTime"
                                label="End Time"
                                type="text"
                                component={RenderField}
                                onChange={endTimepickerChange}
                            />
                        </div>
                    </>
                    )}
                    <div className="right-sidebar-form__item--full" style={{paddingTop: "15px"}}>
                        <input className="right-sidebar-form__submit" type="submit" value="Submit"/>
                    </div>
                </form>
            </div>
    )
};

const mapStateToProps = state => ({
    dataForm: state.form.dataForm,
});

const DataForm = compose(
    reduxForm({
        form: 'dataForm'
    }),
    connect(mapStateToProps,null)
    )(Form);

export default DataForm;